import { request, httpMethod } from "./request";
import { isEmpty, flatten } from "lodash";
import { areKeysSame } from "../utils/utils";
import { ScrapedKeyState } from "../utils/constants";
import { getActiveRuleIds } from "../utils/common";

// Following function calls the api to save normalized data
export const saveFinalSubmitData = async (finalSubmitData, getAccessToken) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalise`;
  const dataPayload = JSON.stringify(finalSubmitData);
  return await request(endpoint, getAccessToken, dataPayload, httpMethod.POST);
};

// Create submit data for the draft -start
export const createDraftSubmitData = (
  translationRules,
  scrapedKeyStates,
  mcpDetail,
  selectedFileInfo,
  tenantDefaultData,
  modelMappingId,
  isDraft,
  isStandard,
  accountId
) => {
  const { skuId, skuVersion, name } = mcpDetail;
  const { fileId, category, competitor } = selectedFileInfo;
  return {
    fileId: fileId,
    accountId: accountId,
    category: category,
    competitor: competitor,
    competitorType: "1",
    tenant: name,
    tenantType: "2",
    skuId: skuId,
    skuVersion: skuVersion,
    isDraft: isDraft ? isDraft : false,
    modelMappingId: modelMappingId,
    deletedScrapeAttributes: createDeletedScrapedData(
      translationRules,
      scrapedKeyStates
    ),
    translationRules: createDraftTranslationRules(
      translationRules,
      scrapedKeyStates
    ),
    defaultValues: {
      scrapedAttributes: [],
      tenantAttributes: createDefaultData(tenantDefaultData),
    },
    isStandard: isStandard,
  };
};

/// New method write to create the translation rule- start
export const createDraftTranslationRules = (
  translationRules,
  scrapedKeyStates
) => {
  const rules = [];
  const dicActiveRule = getActiveRuleIds(scrapedKeyStates);
  translationRules.forEach((translationRule) => {
    if (dicActiveRule[translationRule.ruleId]) {
      const { scrapedAttributes, tenantAttributes, tenantKeys } =
        translationRule;
      scrapedAttributes.forEach((scrapedAttribute) => {
        const translationRule = findTenantAttributesRule(
          tenantAttributes,
          scrapedAttribute.id
        );
        rules.push({
          scrapedAttributes: scrapedAttribute.data,
          tenantAttributes: createDraftTenantRule(translationRule, tenantKeys),
        });
      });
    }
  });

  return rules;
};

const createDraftTenantRule = (translationRule, tenantKeys) => {
  let rule = [];

  if (tenantKeys.every((key) => isEmpty(key))) {
    return rule;
  }
  translationRule.data.forEach((element) => {
    if (
      element.key &&
      !isEmpty(element.key) &&
      tenantKeys.includes(element.key)
    ) {
      rule.push({ key: element.key, value: element.value });
    }
  });

  if (rule.length !== tenantKeys.length) {
    tenantKeys.forEach((key) => {
      if (!isEmpty(key)) {
        if (!rule.find((x) => areKeysSame(x.key, key)))
          rule.push({ key: key, value: [] });
      }
    });
  }
  return rule;
};
// Create submit data for the draft - end

// Following function creates a final submit data
export const createFinalSubmitData = (
  translationRules,
  scrapedKeyStates,
  mcpDetail,
  selectedFileInfo,
  tenantDefaultData,
  modelMappingId,
  isDraft,
  isStandard,
  accountId,
  copiedScrapeAttributes
) => {
  const { skuId, skuVersion, name } = mcpDetail;
  const { fileId, category, competitor } = selectedFileInfo;
  return {
    fileId: fileId,
    accountId: accountId,
    category: category,
    competitor: competitor,
    competitorType: "1",
    tenant: name,
    tenantType: "2",
    skuId: skuId,
    skuVersion: skuVersion,
    isDraft: isDraft ? isDraft : false,
    modelMappingId: modelMappingId ? modelMappingId : null,
    deletedScrapeAttributes: createDeletedScrapedData(
      translationRules,
      scrapedKeyStates
    ),
    translationRules: createTranslationRules(
      translationRules,
      scrapedKeyStates
    ),
    defaultValues: {
      scrapedAttributes: [],
      tenantAttributes: createDefaultData(tenantDefaultData),
    },
    isStandard: isStandard,
    copiedScrapeAttributes: copiedScrapeAttributes,
  };
};

export const createPreviewData = (
  translationRules,
  scrapedKeyStates,
  mcpDetail,
  selectedFileInfo,
  tenantDefaultData,
  modelMappingId,
  isDraft,
  accountId
) => {
  const { skuId, skuVersion, name } = mcpDetail;
  const { fileId, category, competitor } = selectedFileInfo;
  return {
    fileId: fileId,
    accountId: accountId,
    category: category,
    competitor: competitor,
    competitorType: "1",
    tenant: name,
    tenantType: "2",
    skuId: skuId,
    skuVersion: skuVersion,
    isDraft: isDraft ? isDraft : false,
    modelMappingId: modelMappingId ? modelMappingId : null,
    deletedScrapeAttributes: createDeletedScrapedData(
      translationRules,
      scrapedKeyStates
    ),
    translationRules: createTranslationRules(
      translationRules,
      scrapedKeyStates,
      true
    ),
    defaultValues: {
      scrapedAttributes: [],
      tenantAttributes: createDefaultData(tenantDefaultData),
    },
  };
};
const createDefaultData = (dataList) => {
  if (!dataList) {
    return [];
  }
  var defaultDataList = [];
  dataList.forEach((data) => {
    if (data.defaultValue) {
      defaultDataList.push({
        key: data.attributeKey,
        value: data.defaultValue,
      });
    }
  });
  return defaultDataList;
};

/// New method write to create the translation rule- start
export const createTranslationRules = (
  translationRules,
  scrapedKeyStates,
  isPreview
) => {
  const rules = [];
  const dicActiveRule = getActiveRuleIds(scrapedKeyStates);
  translationRules.forEach((translationRule) => {
    if (dicActiveRule[translationRule.ruleId]) {
      const { scrapedAttributes, tenantAttributes, tenantKeys } =
        translationRule;
      scrapedAttributes.forEach((scrapedAttribute) => {
        const translationRule = findTenantAttributesRule(
          tenantAttributes,
          scrapedAttribute.id
        );
        rules.push({
          scrapedAttributes: scrapedAttribute.data,
          tenantAttributes: !isPreview
            ? createTenantRule(translationRule, tenantKeys)
            : createTenantRuleForPreview(translationRule, tenantKeys),
        });
      });
    }
  });

  return rules;
};

const createTenantRule = (translationRule, tenantKeys) => {
  let rule = [];
  translationRule.data.forEach((element) => {
    if (tenantKeys.includes(element.key)) {
      rule.push({ key: element.key, value: element.value });
    }
  });
  return rule;
};

const createTenantRuleForPreview = (translationRule, tenantKeys) => {
  let rule = [];
  translationRule.data.forEach((element) => {
    if (tenantKeys.includes(element.key) && !isEmpty(element.value)) {
      rule.push({ key: element.key, value: element.value });
    }
  });
  return rule;
};

const findTenantAttributesRule = (translationRules, id) => {
  return translationRules.find((x) => x.scrapeId === id);
};

const createDeletedScrapedData = (translationRules, scrapedKeyStates) => {
  const deletedScrapeKeysInUI = scrapedKeyStates.filter((each) =>
    areKeysSame(each.mappingState, ScrapedKeyState.DELETED)
  );
  const mappedkey = scrapedKeyStates.filter((each) =>
    areKeysSame(each.mappingState, ScrapedKeyState.MAPPED)
  );

  const mappedKeysList = flatten(mappedkey.map((o) => o["scrapedKeys"]));
  const deletedScrapeKeys = [];
  deletedScrapeKeysInUI.forEach((deletedScrapeKey) => {
    const key = deletedScrapeKey.scrapedKeys[0];
    const translationRule = translationRules.find((each) =>
      areKeysSame(each.ruleId, deletedScrapeKey.ruleId)
    );
    const data = flatten(
      translationRule.scrapedAttributes.map((each) => each.data)
    );
    const values = data
      .filter((each) => areKeysSame(each.key, key))
      .map((each) => each.value);

    if (mappedKeysList.indexOf(key) === -1) {
      deletedScrapeKeys.push({ key: key, values: values });
    }
  });
  return deletedScrapeKeys;
};

/// New method write to create the translation rule- end
