import React, { Component, Fragment } from "react";
import { ScrapedKeyState } from "../../utils/constants";
import { MemoizeSelectedScrapeAttributeView } from "./SelectedScrapeAttributeView";
import SelectedTenantAttributeView from "./SelectedTenantAttributeView";
import { MemoizeSelectedAttributeButtons } from "./SelectedAttributeButtons";

const getItemStyle = (mappingState) => ({
  pointerEvents: mappingState === ScrapedKeyState.DELETED ? "none" : "",
  opacity: mappingState === ScrapedKeyState.DELETED ? "0.7" : "",
});

class AttributeMapper extends Component {
  render() {
    const {
      onChangeTenantKey,
      onSelectionTenantKey,
      onBlurTenantKey,
      onBlurTenantValue,
      onChangeTenantValue,
      onSelectionTenantValue,
      onScrapedKeySplit,
      onScrapedKeyMerge,
      mappingState,
      translationRule,
      ruleIndex,
      selectedKeyId,
      onDeleteTenantKey,
      onDeleteAttributeKey,
      onSelectAttributeKey,
      onCopyScrapeValues,
      onDeleteScrapeKey,
      scrapedKeys,
      getTenantKeys,
      getTenantValues,
      getAttributeKeys,
      disableActions,
      copiedScrapeAttributes,
      onToggleAlwaysCopy,
    } = this.props;
    const { scrapedAttributes, tenantKeys, ruleId, tenantAttributes } =
      translationRule;
    let rowSize = scrapedKeys.length * 3;

    return (
      <Fragment>
        <MemoizeSelectedAttributeButtons
          onScrapedKeyMerge={onScrapedKeyMerge}
          onScrapedKeySplit={onScrapedKeySplit}
          onCopyScrapeValues={onCopyScrapeValues}
          onDeleteScrapeKey={onDeleteScrapeKey}
          ruleIndex={ruleIndex}
          selectedKeyId={selectedKeyId}
          tenantKeys={tenantKeys}
          scrapedKeys={scrapedKeys}
          mappingState={mappingState}
          disableActions={disableActions}
          copiedScrapeAttributes={copiedScrapeAttributes}
          onToggleAlwaysCopy={onToggleAlwaysCopy}
        />
        <div className="row" style={getItemStyle(mappingState)}>
          <div className={`col-md-${rowSize}`}>
            <MemoizeSelectedScrapeAttributeView
              scrapedAttributes={scrapedAttributes}
              ruleId={ruleId}
              scrapedKeys={scrapedKeys}
              getAttributeKeys={getAttributeKeys}
              ruleIndex={ruleIndex}
              onDeleteAttributeKey={onDeleteAttributeKey}
              onSelectAttributeKey={onSelectAttributeKey}
            />
          </div>
          <div className={`col-md-${12 - rowSize}`}>
            <SelectedTenantAttributeView
              tenantAttributes={tenantAttributes}
              tenantKeys={tenantKeys}
              ruleId={ruleId}
              ruleIndex={ruleIndex}
              getTenantKeys={getTenantKeys}
              getTenantValues={getTenantValues}
              onChangeTenantKey={onChangeTenantKey}
              onSelectionTenantKey={onSelectionTenantKey}
              onBlurTenantKey={onBlurTenantKey}
              onBlurTenantValue={onBlurTenantValue}
              onChangeTenantValue={onChangeTenantValue}
              onSelectionTenantValue={onSelectionTenantValue}
              scrapedKeysLength={scrapedKeys.length}
              onDeleteTenantKey={onDeleteTenantKey}
              disableActions={disableActions}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AttributeMapper;
