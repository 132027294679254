import axios from "axios";

export const GetThresholdPriceChanges = async (getAccessToken, request) => {
  const headers = { ...{ authorization: `Bearer ${getAccessToken()}` } };
  return axios
    .post(
      `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/price/pricechange`,
      request,
      { headers }
    )
    .then((response) => {
      return response.data;
    });
};

export const GetMultiplePricePoints = async (getAccessToken, request) => {
  const headers = { ...{ authorization: `Bearer ${getAccessToken()}` } };
  return axios
    .post(
      `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/multiplepricepoint`,
      request,
      { headers }
    )
    .then((response) => {
      return response.data;
    });
};
