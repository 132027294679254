import React, { useState } from "react";
import { Button, Modal, FlexBox, Checkbox } from "@cimpress/react-components";
import DatePicker from "@cimpress/react-components/module/DatePicker";
import moment, { isMoment } from "moment";
import mappingManagementStyles from "./MappingManagement.module.css";

function SyncModal({
  sku,
  toggleSyncModal,
  showSyncModal,
  onSync,
  showSpinner,
}) {
  const [dateValue, setDateValue] = useState(new Date());
  const [syncExistingMappings, setSyncExistingMappings] = useState(true);

  // This needs to be the start of the day and not the minute due to how the DatePicker's day
  //   validation works. The component passes the start of the day, and if this function returns true,
  //   the day is considered valid. Therefore, to allows dates on the current day, we need to consider
  //   the start of the day as valid even though it is technically not.
  function validatePickerDate(date) {
    const todayDate = moment().endOf("second");
    // Limit selection to only last 6 months
    const lastAllowedDate = moment().endOf("second").subtract(6, "months");
    return date.isBetween(lastAllowedDate, todayDate);
  }

  const handleChangeSinceDate = (value) => {
    const parsedValue = isMoment(value) ? value : moment(value);
    setDateValue(parsedValue);
  };

  const datePicker = (
    <DatePicker
      disabled={showSpinner}
      label="Select Date"
      timeFormat="h:mm A"
      value={dateValue}
      closeOnClickOutside={true}
      closeOnSelect={true}
      isValidDate={validatePickerDate}
      onChange={handleChangeSinceDate}
      utc={true}
    />
  );

  const footer = (
    <>
      <Button
        onClick={toggleSyncModal}
        variant="default"
        disabled={showSpinner}
      >
        Cancel
      </Button>
      <Button
        onClick={() => onSync(dateValue, syncExistingMappings)}
        type="primary"
        disabled={dateValue > new Date() || showSpinner}
        style={{ marginLeft: "16px" }}
      >
        Sync
      </Button>
    </>
  );

  return (
    <Modal
      style={{ width: "30%" }}
      className={mappingManagementStyles.modalStyle}
      show={showSyncModal}
      footer={footer}
      onRequestHide={() => toggleSyncModal()}
      title="Sync Product Mapping Data"
      closeButton={true}
    >
      Sync the sku <b>{sku}</b> and its mapping with previous scraped files.
      <br />
      <br />
      <FlexBox middle style={{ marginLeft: "-15px" }}>
        <span className="col-md-4">Sync to all files since </span>
        <span className="col-md-6">{datePicker}</span>
      </FlexBox>
      <br />
      <FlexBox middle>
        <Checkbox
          checked={syncExistingMappings}
          onChange={() => setSyncExistingMappings((prev) => !prev)}
        />
        <span style={{ marginBottom: "8px" }}>
          Override all existing mappings for the selected time period
        </span>
      </FlexBox>
    </Modal>
  );
}

export default SyncModal;
