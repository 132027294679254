import React, { Fragment, useState, useEffect } from "react";
import { TextField } from "@cimpress/react-components";

import { areKeysSame, CheckIfEmpty } from "../../../utils/utils";

export default function AddVat(props) {
  const { countryData, countryVatMapping, updateVat, closeVatModal } = props;
  const [vatDetails, setVatDetails] = useState({ vats: [] });

  useEffect(() => {
    const vatArr = [];
    countryData.map((data) => {
      const vatRate = countryVatMapping.find(
        (each) => each.countryCode === data
      );

      return vatArr.push({
        countryCode: data,
        vatRate: vatRate ? vatRate.vatRate : "",
      });
    });
    setVatDetails({ vats: vatArr });
  }, [countryData, countryVatMapping]);

  function onInputChange(e) {
    const countryCode = e.target.getAttribute("data-key");
    const index = vatDetails.vats.findIndex((x) =>
      areKeysSame(x.countryCode, countryCode)
    );
    const vats = [...vatDetails.vats];
    const vat = { ...vats[index] };
    vat.vatRate = e.target.value;
    vats[index] = vat;
    setVatDetails({ vats });
  }

  function createPopupData() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <TextField readOnly value={"Country"} />
          </div>
          <div className="col-md-6">
            <TextField readOnly value={"VAT"} />
          </div>
        </div>
        {vatDetails.vats.map((vat) => {
          return (
            <div className="row" key={vat.countryCode}>
              <div className="col-md-6">
                <TextField readOnly label="Country" value={vat.countryCode} />
              </div>
              <div className="col-md-6">
                <TextField
                  required
                  onChange={onInputChange}
                  label="VAT"
                  data-key={vat.countryCode}
                  value={vat.vatRate}
                />
              </div>
            </div>
          );
        })}
        <div>
          <button
            className="btn btn-default"
            disabled={CheckIfEmpty("vatRate", vatDetails.vats)}
            onClick={(data) => updateVat(vatDetails.vats)}
          >
            Save & Close
          </button>
          &nbsp;&nbsp;
          <button className="btn btn-default" onClick={closeVatModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
  return <Fragment>{createPopupData()}</Fragment>;
}
