import {
  getFulfillerDetails,
  isStandardProduct,
} from "../../services/productModel";

const getFulfillerName = async (skuId, getAccessToken) => {
  try {
    const fulfillerName = await getFulfillerDetails(skuId, getAccessToken);
    return fulfillerName;
  } catch (ex) {
    // Do nothing.
  }
};

export const createSubmitData = async (
  currScrapedFile,
  sku,
  getAccessToken,
  accountId
) => {
  return {
    fileId: currScrapedFile.id,
    accountId: accountId,
    category: currScrapedFile.category,
    competitor: currScrapedFile.competitor,
    competitorType: "1",
    tenant: await getFulfillerName(sku.productId, getAccessToken),
    tenantType: "2",
    skuId: sku.productId,
    skuVersion: sku.version,
    isDraft: true,
    modelMappingId: null,
    deletedScrapeAttributes: [],
    translationRules: [],
    defaultValues: {
      scrapedAttributes: [],
      tenantAttributes: [],
    },
    isStandard: await isStandardProduct(sku.productId, getAccessToken),
  };
};
