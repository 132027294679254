import { MappingStatus } from "./constants";

export const getPageCount = (pageSize, totalRecords) => {
  if (totalRecords % pageSize === 0) {
    return totalRecords / pageSize;
  }

  return Math.floor(totalRecords / pageSize) + 1;
};

export const getStartAndEndIndex = (pageSize, currentPage, totalRecords) => {
  const startIndex = pageSize * currentPage;
  let endIndex = startIndex + pageSize;

  if (endIndex > totalRecords) {
    endIndex = totalRecords;
  }

  return { startIndex, endIndex };
};

export const getCurrentPageScrapedFile = (
  totalRecords,
  pageSize,
  currentPage,
  scrapedFiles
) => {
  if (!totalRecords) return;
  const { startIndex, endIndex } = getStartAndEndIndex(
    pageSize,
    currentPage,
    totalRecords
  );
  return scrapedFiles.slice(startIndex, endIndex);
};

export const getProcessScrapedFilesData = (scrapedFiles) => {
  scrapedFiles.forEach((scrapedFile) => {
    const { references } = scrapedFile;
    if (references && references.length > 0) {
      references.forEach((reference) => {
        let status = "";
        if (reference.mappingInProgress) {
          status = MappingStatus.MAPPING_IN_PROGRESS;
        } else if (!reference.hasMapping) {
          status = MappingStatus.NOT_STARTED;
        } else if (reference.isDraft) {
          status = MappingStatus.MAPPING_FAILED;
        } else {
          status = MappingStatus.COMPLETED;
        }
        reference.mappingStatus = status;
      });
    }
  });

  return scrapedFiles;
};

export const downloadMappingUtil = (
  modelId,
  competitor,
  country,
  category,
  sku
) => {
  const url = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalisation/${modelId}:download`;
  return `https://uiwrap.trdlnk.cimpress.io/?url=${encodeURIComponent(
    url
  )}&filename=${encodeURIComponent(
    `${competitor}_${country}_${category}_${sku}.csv`
  )}&method=GET`;
};

export const getMapForCompetitor = (masters, mapByCompetitor) => {
  masters.forEach((master) => {
    const { competitor } = master;
    let mapByCountry = mapByCompetitor.get(competitor);
    if (!mapByCountry) {
      mapByCountry = new Map();
    }

    mapByCompetitor.set(competitor, addCategoryToCountry(master, mapByCountry));
  });

  return mapByCompetitor;
};

const addCategoryToCountry = (master, mapByCountry) => {
  const { productCategory, country } = master;
  let categories = mapByCountry.get(master.country);
  if (categories && categories.length > 0) {
    categories.push(productCategory);
  } else {
    categories = [productCategory];
  }
  mapByCountry.set(country, categories);
  return mapByCountry;
};

export const getCategories = (mapByCompetitor, competitorName, country) => {
  const countries = mapByCompetitor.get(competitorName);
  if (countries && countries.size > 0) {
    const categories = countries.get(country);
    if (categories && categories.length > 0) {
      return categories;
    }
  }
  return [];
};

export const getCountries = (mapByCompetitor, competitorName) => {
  const countries = mapByCompetitor.get(competitorName);
  if (countries && countries.size > 0) {
    return Array.from(countries.keys());
  }
  return [];
};

export const isValidRoute = (
  competitors,
  competitor,
  category,
  country,
  accountId,
  mapByCompetitor
) => {
  if (!accountId) return false;
  const competitorFound = competitors && competitors.includes(competitor);
  if (!competitorFound) return false;

  if (category) {
    const categoryList = getCategories(mapByCompetitor, competitor);
    const categoryFound = categoryList && categoryList.includes(category);
    if (!categoryFound) return false;
  }

  if (country) {
    const countryList = getCountries(mapByCompetitor, competitor, category);
    const countryFound = countryList && countryList.includes(country);
    if (!countryFound) return false;
  }

  return true;
};

// export const getQueryStringForManageMapping = (
//   competitor,
//   category,
//   country
// ) => {
//   let queryString = "";
//   if (competitor) {
//     queryString = `${queryString}?competitor=${competitor}`;
//   }
//   if (country) {
//     queryString = `${queryString}&country=${country}`;
//   }
//   if (category) {
//     queryString = `${queryString}&category=${encodeURIComponent(category)}`;
//   }

//   return queryString;
// };

export const getStatusStyle = (status) => {
  switch (status) {
    case MappingStatus.NOT_STARTED:
      return { backgroundColor: " #d9d9d9", minWidth: 120 };
    case MappingStatus.COMPLETED:
      return { backgroundColor: "#35ad96", minWidth: 120 };
    case MappingStatus.MAPPING_FAILED:
      return { backgroundColor: "#d24a35", minWidth: 120 };
    case MappingStatus.PRICE_CHANGE_THRESHOLD:
      return { backgroundColor: "#d24a35", minWidth: 120 };
    default:
      return { backgroundColor: "#1A8AA8", minWidth: 120 };
  }
};

export const getStyleClassName = (status) => {
  switch (status) {
    case MappingStatus.COMPLETED:
      return "success";
    case MappingStatus.MAPPING_FAILED:
      return "danger";
    case MappingStatus.PRICE_CHANGE_THRESHOLD:
      return "danger";
    case MappingStatus.NOT_STARTED:
      return "info";
    default:
      return "info";
  }
};
