import { request, httpMethod } from "./request";
import { getAccessToken } from "../utils/accessToken";
export const deleteFileData = async (jobId) =>  {
    let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapingjobs/${jobId}`;
    return await request(
        endpoint,
        getAccessToken,
        null,
        httpMethod.DELETE
      );
}
