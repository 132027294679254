/**
 * An example of how to implement a relevancy-based sorting method. States are
 * sorted based on the location of the match - For example, a search for "or"
 * will return "Oregon" before "North Carolina" even though "North Carolina"
 * would normally sort above Oregon.
 */
export const sortStates = (a, b, value) => {
  const aRatio = a.ratio;
  const bRatio = b.ratio;
  const aLower = a.value.toLowerCase();
  const bLower = b.value.toLowerCase();
  const valueLower = value.toLowerCase();
  const queryPosA = aLower.indexOf(valueLower);
  const queryPosB = bLower.indexOf(valueLower);
  if (aRatio !== undefined && bRatio !== undefined) {
    if (aRatio !== bRatio) {
      return bRatio - aRatio;
    }
  }
  if (queryPosA !== queryPosB) {
    return queryPosA - queryPosB;
  }
  return aLower < bLower ? -1 : 1;
};

/**
 * Invoked for each entry in items and its return value is used to determine whether or
 * not it should be displayed in the dropdown menu.
 */
export const shouldItemRender = (item, value) => {
  return item.value.toLowerCase().indexOf(value.toLowerCase()) > -1;
};

export const menuStyle = {
  border: "1px solid #c4cdd6",
  // boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
  background: "rgba(255, 255, 255, 0.9)",
  padding: "5px",
  fontSize: "90%",
  position: "fixed",
  overflow: "auto",
  maxHeight: "230px",
  zIndex: 1024,
};

// export const menuStyle = {
//   border: "1px solid #c4cdd6",
//   // boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
//   background: "rgba(255, 255, 255, 0.9)",
//   padding: "5px",
//   fontSize: "90%",
//   position: "absolute",
//   overflow: "auto",
//   maxHeight: "230px",
//   zIndex: 1024,
//   // bottom: -230,
//   // right: 0,
//   // left: 0
//   top: "100%",
//   left: 0,
//   right: 0
// };

export const wrapperStyle = {
  display: "inline",
  width: "100%",
};

export const menuWrapper = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
};
