import React from "react";
import {
  Loudspeaker,
  LoudspeakerEnvironment,
} from "@cimpress-technology/react-loudspeaker";
const competitorPriceTrackingLoudspeakerId = "competitor-tracking";
function Loudspeakers({ accessToken, isProduction }) {
  return (
    <div>
      {accessToken && (
        <Loudspeaker
          accessToken={accessToken}
          channelIds={[competitorPriceTrackingLoudspeakerId]}
          title="What's new in Competitor Price Tracking?"
          environment={ isProduction ? LoudspeakerEnvironment.Production : LoudspeakerEnvironment.Staging}
        />
      )}
    </div>
  );
}

export default Loudspeakers;
