import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DraggableButton from "./DraggableButton";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // Some basic styles
  userSelect: "none",
  padding: grid * 0.1,
  margin: `0 0 ${grid}px 0`,

  // Change background colour while dragging
  background: isDragging ? "lightgreen" : "white",

  // Styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "silver" : "#eff3f5",
  padding: grid,
});

export default function ScrapedAttributesListView(props) {
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const { reorderOnDrag } = props;
    reorderOnDrag(result.source.index, result.destination.index);
  };

  const { items, onClickAttributeKey, onDeleteScrapeKey, disableActions, translationRules, translationRule } = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => {
              const { scrapedKeys, isSelected, isActive, mappingState, ruleId } = item;
              if (!isActive) {
                return true;
              }
              return (
                <Draggable
                  key={item.ruleId}
                  draggableId={item.ruleId}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <DraggableButton
                        attributeKey={
                          scrapedKeys.length > 1
                            ? scrapedKeys.join(" ")
                            : scrapedKeys[0]
                        }
                        index={index}
                        isSelected={isSelected}
                        mappingState={mappingState}
                        onClickAttributeKey={onClickAttributeKey}
                        onDeleteScrapeKey={onDeleteScrapeKey}
                        ruleId = {ruleId}
                        disableActions = {disableActions}
                        scrapedKeys={scrapedKeys}
                        translationRules={translationRules}
                        translationRule = {translationRule}

                      />
                    </div>
                  )}
                </Draggable>                
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export const MemoizeScrapedAttributesListView = React.memo(
  ScrapedAttributesListView,
  (prevProps, nextProps) => {
    return prevProps.items === nextProps.items;
  }
);
