import { request } from "./request";

export const getCategories = (sourceData, selectedCompetitor) => {
  let categories = [];
  if (!selectedCompetitor) return categories;
  const competitor = sourceData.find(
    (x) => x.sourceName === selectedCompetitor.value
  );
  competitor.categories.forEach((category) => {
    categories.push({ value: category, label: category });
  });
  return categories;
};

export const getCompetitors = (sourceData) => {
  let competitors = [];
  sourceData.forEach((source) => {
    competitors.push({ value: source.sourceName, label: source.sourceName });
  });
  return competitors;
};

export const getFileData = async (getAccessToken, fileId, accountId) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile`;
  if (fileId && accountId) endpoint += `/${fileId}/accountid/${accountId}`;
  return await request(endpoint, getAccessToken);
};
