export const Messages = {
  SUCCESSFUL_SAVE: "Successfully saved the data.",
  SKU_MANDATORY: "Please enter mcp sku.",
  LISTING_FILE_FAILED: "Operation of listing the file has failed.",
  SUCCESSFUL_FINAL_SUBMIT: "Successfully submitted mapped data.",
  SUCCESSFUL_FINAL_SUBMIT_AND_SYNC:
    "Successfully submitted mapped data and syncing in progress. You will be notified via email once done.",
  FINAL_SUBMIT_FAILED: "Operation of final submit has failed.",
  FETCHING_WARNINGS_FAILED: "Fetching the warnings data failed.",
  SYNC_FAILED: "Submit successful but Syncing of the data failed.",
  GET_MAPPED_DATA_FAILED:
    "Operation to fetch the necessary data for normalization process has failed.",
  GET_MARGE_DATA_FAILED:
    "Operation to fetch the merged combination data for normalization process has failed.",
  INVALID_SELECTION: "Please select at least the competitor.",
  PREVIEW_FAILED: "Operation of preview the data has failed.",
  GET_RULE_SET_FAILED: "Failed to fetch data for given sku.",
  FIELDS_MANDATORY: "Please fill in all mandatory fields.",
  DOWNLOAD_FAILED: "Downloading the file has failed.",
  MAX_MERGED_KEY: "Maximum three keys can be merged.",
  MAX_SPLIT_KEY: "Maximum three keys can be split.",
  MAP_TENANT_KEY_FOR_COPY:
    "Please map the tenant key to copy scraped value to tenant value.",
  KEY_ALREADY_SELECTED: "Key is already selected.",
  SAVE_AS_DRAFT:
    "Only those mapping would be saved for which tenant keys are specified.",
  SAVE_AS_DRAFT_VALIDATION: "Map at least one attribute to save as draft.",
  NLP_SERVICE_ERROR: "Failed to fetch nlp data.",
  COMPETITOR_SERVICE_ERROR: "Failed to retreive competitors.",
  SUCCESSFUL_SUBSCRIBE: "Subscribed successfully!",
  UNSUCCESSFUL_SUBSCRIBE: "Failed to perform subscribe action",
  SUCCESSFUL_UNSUBSCRIBE: "Unsubscribed successfully!",
  UNSUCCESSFUL_UNSUBSCRIBE: "Failed to perform unsubscribe action",
  EXISTING_SUSCRIPTION: "Already subscribed",
  SUBSCRIPTION_NOTFOUND: "No subscription found",
  FAILED_FETCH_SUBSCRIPTION_DATA:
    "Failed to fetch subscription data! Please contact the admin",
  NO_MODIFICATION:
    "You may not have made any changes from your current configuration",
  EMPTY_SELECTION: "You may not have selected any categories to subscribe to",
  ERROR_WHILE_FETCHING_FILE_OWNER_DETAILS:
    "Error while fetching file owner details",
  MASTERDATA_API_FAILED: "Failed to fetch master data",
  FILE_OWNED_BY_OTHER_BUSINESS:
    "This file is owned by other business and you can start use once mandatory fields are filled.",
  STANDARD_PRODUCT_MAPPING_CONFIG_FAILED:
    "Failed to get standard product mapping configuration.",
  CONTACT_PHOENIX_SQUAD:
    "Unexpected error occured. Please Contact Phoenix Squad!",
};

// Maximum number of split or merge allowed.
export const MAX_KEY = 2;

export const NO_OF_COMPETITORS_TO_SHOW = 3;

export const ScrapedKeyState = {
  DELETED: "DELETED",
  MAPPED: "MAPPED",
  PARTIALLY_MAPPED: "PARTIALLY_MAPPED",
};

export const SubscriptionAction = {
  SUBSCRIBE: "subscribe",
  UNSUBSCRIBE: "unSubscribe",
};

export const SELECTEDACCOUNTCONFIGURATION_KEY =
  "selectedScrapingToolConfiguration";

export const SubscriptionOption = {
  SUBSCRIBE_ALL: "All",
  UNSUBSCRIBE_ALL: "None",
  SUBSCRIBE_INDIVIDUAL: "Individual",
};

export const StandardColumnName = {
  COUNTRY: "Country",
  CURRENCY: "Currency",
  QUANTITY: "Quantity",
  PRICE: "Price",
  ISSTANDARDTAX: "isStandardTax",
  PRICEINCLUSIVESHIPPING: "priceInclusiveShipping",
  SHIPPINGPRICE: "ShippingPrice",
};

export const StandardColumnType= {
  PRICE: "Price",
  STRING: "String",
  NUMBER: "Number"
};

export const ValidationStatus = {
  PENDING: "Pending",
  COMPLETED: "Completed",
  MISSINGMANDATORYFIELDS: "MissingMandatoryFields",
};

export const THRESHOLD_PRICE_CHANGES = "Threshold Price Changes";
export const MULTIPLE_PRICE_POINT = "Multiple Price Points";
export const NO_CURRENCY = "NoCurrency";

export const MappingStatus = {
  MAPPING_FAILED: "MAPPING FAILED",
  COMPLETED: "COMPLETED",
  NOT_STARTED: "NOT STARTED",
  MAPPING_IN_PROGRESS: "MAPPING IN PROGRESS",
  PRICE_CHANGE_THRESHOLD: "PRICE CHANGE THRESHOLD",
};

export const defaultConfirmationModalStateDetails = {
  show: false,
  title: null,
  nonPrimaryButton: null,
  primaryButton: null,
  description: null,
  onPrimaryButtonClickAction: null,
  onNonPrimaryButtonClickAction: null,
  bsStyle: null,
};

export const AttributesTransformedStatus = {
  ADDED: "ADDED",
  MATCHED: "MATCHED",
  MODIFIED: "MODIFIED",
  REMOVED: "REMOVED",
  CUSTOM: "CUSTOM",
};
