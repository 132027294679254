import { getAccessToken } from "../utils/accessToken";
import { maxInt32 } from "./metaData";
import { request } from "./request";
export const getMasterData = async (accountId) => {
  const endpoint = `${
    process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI
  }/v2/master/competitors/${accountId}?offset=${0}&limit=${maxInt32}`;
  let response = await request(endpoint, getAccessToken);
  return response._embedded.item;
};
