import React, { useEffect, useState } from "react";
import ReactAutocomplete from "react-autocomplete";

function generateUniqueId(name, placeholder, label) {
  // Build a unique ID for this field in case one was not provided.
  const randomNumber = Math.floor(Math.random() * 0xffff);
  const uniqueId = `${name}-${placeholder}-${label}-${randomNumber}`;
  return uniqueId.replace(/[^A-Za-z0-9-]/gi, "");
}

const isValuePresent = (val) =>
  val !== undefined && val !== null && val.toString() !== "";

const AutocompleteTextField = React.forwardRef((props, ref) => {
  const {
    value,
    items,
    getItemValue,
    onChange,
    onSelect,
    shouldItemRender,
    sortItems,
    renderMenu,
    renderItem,
    wrapperStyle,
    selectOnBlur,
    onMenuVisibilityChange,
    open,
    inputProps: {
      autoFocus,
      bsStyle,
      className,
      disabled,
      helpText,
      id,
      inputStyle,
      label,
      name,
      onBlur,
      onFocus,
      onClick,
      onKeyDown,
      placeholder,
      required,
      rightaddon,
      style,
      type,
      ...rest
    },
  } = props;

  const [inputValue, setInputValue] = useState(isValuePresent(value));
  const [uniqueId] = useState(() => {
    return generateUniqueId(name, placeholder, label);
  });

  //const [menuTopPosition, setMenuTopPosition] = useState(0);
  //const refs = useRef(null);

  useEffect(() => {
    setInputValue(isValuePresent(value));
  }, [value]);

  const handleBlur = (e) => {
    setInputValue(isValuePresent(value));
    onBlur && onBlur(e);
  };

  const handleFocus = (e) => {
    setInputValue(true);
    onFocus && onFocus(e);
  };

  const inputId = id || uniqueId;

  const wrapperClassNames = ["form-group", className];
  label && inputValue && wrapperClassNames.push("form-group-active");
  required && wrapperClassNames.push("required");
  bsStyle && wrapperClassNames.push("has-" + bsStyle);
  disabled && wrapperClassNames.push("disabled");

  const inputProps = {
    autoFocus,
    className: "form-control py-2 border-right-0 border minwidth",
    disabled,
    id: inputId,
    name,
    onBlur: handleBlur,
    onChange,
    onClick,
    onFocus: handleFocus,
    onKeyDown,
    placeholder,
    required,
    style: inputStyle,
    value,
    rightaddon,
    ...rest,
  };
  inputProps.type = type;

  const autocompleteProps = {
    value,
    items,
    getItemValue,
    onChange,
    onSelect,
    shouldItemRender,
    sortItems,
    renderMenu,
    renderItem,
    wrapperStyle,
    selectOnBlur,
    onMenuVisibilityChange,
    open,
  };

  const inputElement = (
    <ReactAutocomplete
      inputProps={{
        ...inputProps,
      }}
      {...autocompleteProps}
      ref={ref}
    />
  );

  return (
    <div className={wrapperClassNames.join(" ")} style={style}>
      <div className="input-group-new" style={{ width: "100%" }}>
        {inputElement}

        {rightaddon && (
          <span className="input-group-new-append">
            {" "}
            <div className="input-group-new-text bg-transparent border-left-0 border">
              {rightaddon}
            </div>
          </span>
        )}
        {!rightaddon && (
          <span className="input-group-new-append">
            {" "}
            <div className="input-group-new-text bg-transparent border-left-0 border"></div>
          </span>
        )}
      </div>
      {helpText ? <small className="help-block">{helpText}</small> : null}
    </div>
  );
});
export default AutocompleteTextField;
