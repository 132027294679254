import React, { useState } from "react";
import { Table, Dropdown, Tooltip, Button } from "@cimpress/react-components";
import MappingFailedReasonModal from "../modals/MappingFailedReasonModal";
import { downloadMappingUtil } from "../../utils/manageMapping";
import mappingManagementStyles from "./MappingManagement.module.css";
import { toMonthDayYearDisplay } from "../../utils/dateFormat";
import { routeNames } from "../../constants/general";
import { getStatusStyle, getStyleClassName } from "../../utils/manageMapping";
import { MappingStatus, ValidationStatus } from "../../utils/constants";
import { useHistory } from "react-router";
import IconNavigationMenuVertical from "@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical";
import { checkIfPriceThresholdChanged } from "../../utils/common";

const noRecordMessage =
  'No SKUs yet. Select the "Add SKUs" option in the action menu to add a SKU';
const fileMappingNotFilled = "Please Fill the File Mapping";
const fileHasError =
  "File contains the following error(s):";

const MappingTable = ({
  accessToken,
  currScrapedFile,
  onClickSyncModal,
  accountId,
  showConfirmationModalForDisableSku,
}) => {
  const [showMappingFailedModal, setShowMappingFailedModal] = useState();
  const [mappingFailedReasonDetail, setMappingFailedReasonDetail] =
    useState(undefined);
  const history = useHistory();

  const columns = [
    { Header: "SKU", accessor: "productModelId" },
    { Header: "MERCHANT", accessor: "merchant" },
    {
      Header: "MAPPING STATUS",
      accessor: "mappingStatus",
      Cell: (row) => {
        const { mappingStatus, mappingFailedReason, validationMessage } = row.original;
        const statusToDisplay =  mappingStatus === MappingStatus.MAPPING_FAILED && validationMessage ? checkIfPriceThresholdChanged(validationMessage) : mappingStatus;
        return (
          <div
            align="center"
            style={getStatusStyle(statusToDisplay)}
            className={`labelStyle ${getStyleClassName(statusToDisplay)}`}
          >
            {mappingStatus === MappingStatus.MAPPING_FAILED ? (
              <Tooltip
                direction={"top"}
                contents={"Click for more details on the status"}
              >
                <Button
                  style={{ color: "white" }}
                  type={"anchor"}
                  key="redirect"
                  onClick={() =>
                    setMappingFailedReasonModalVisibility(
                      true,
                      mappingFailedReason
                    )
                  }
                >
                  {statusToDisplay}
                </Button>
              </Tooltip>
            ) : (
              statusToDisplay
            )}
          </div>
        );
      },
    },
    {
      Header: "MAPPING UPDATED",
      accessor: "mappingUpdated",
      Cell: (row) => {
        const { referenceId, mappedDate, mappingStatus, productModelId } =
          row.original;

        const viewMapping = (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key="viewMapping" onClick={() => viewMappingHandler(row.original)}>
            View Mapping
          </a>
        );
        const downloadMappingData = (
          <a
            key="downloadMappingData"
            href={downloadLatestMappingHandler(referenceId, productModelId)}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Mapping Data
          </a>
        );
        const disableSku = (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key="disableSku"
            onClick={() =>
              showConfirmationModalForDisableSku(currScrapedFile, row.original)
            }
          >
            Disable SKU
          </a>
        );
        const syncData = (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key="syncData" onClick={() => onClickSyncModal(referenceId)}>
            Sync SKU
          </a>
        );
        const actions = [viewMapping];

        if (currScrapedFile.isLatest) {
          actions.push(disableSku);
        }

        if (mappingStatus === MappingStatus.COMPLETED) {
          actions.push(downloadMappingData);
        }

        if (
          currScrapedFile.isLatest &&
          mappingStatus === MappingStatus.COMPLETED
        ) {
          actions.push(syncData);
        }

        return mappingStatus !== MappingStatus.MAPPING_IN_PROGRESS ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="text-overflow">
              {toMonthDayYearDisplay(mappedDate)}
            </div>
            <Dropdown
              className={mappingManagementStyles.mappingActionsNavigationIcon}
              title={<IconNavigationMenuVertical weight="fill" />}
              type="simple"
            >
              {actions}
            </Dropdown>
          </div>
        ) : null;
      },
    },
  ];

  const viewMappingHandler = (row) => {
    history.push(
      `/${routeNames.MAPPING_MANAGEMENT}/${accountId}/${routeNames.VIEW_MAPPING}/${encodeURIComponent(currScrapedFile.competitor)}/${currScrapedFile.id}/${row.referenceId}/${currScrapedFile.countryCode}/${encodeURIComponent(currScrapedFile.category)}`
    );
  };
  const setMappingFailedReasonModalVisibility = (
    show,
    mappingFailedReasonData
  ) => {
    setShowMappingFailedModal(show);
    setMappingFailedReasonDetail(mappingFailedReasonData);
  };

  const downloadLatestMappingHandler = (modelId, sku) => {
    return downloadMappingUtil(
      modelId,
      currScrapedFile.competitor,
      currScrapedFile.countryCode,
      currScrapedFile.category,
      sku
    );
  };

  return (
    <div>
      {currScrapedFile.references && currScrapedFile.references.length > 0 ? (
        <Table
          className={mappingManagementStyles.mappingTable}
          loading={false}
          columns={columns}
          data={currScrapedFile.references}
          condensed={false}
          filterable={false}
          sortable={false}
          showPagination={true}
          minRows={1}
        />
      ) : (
        <div className={`${mappingManagementStyles.file_no_sku_message}`}>
          {currScrapedFile.validationStatus ===
          ValidationStatus.MISSINGMANDATORYFIELDS ? (
            fileMappingNotFilled
          ) : currScrapedFile.mappingFailedReason && currScrapedFile.mappingFailedReason.hasError ? (
            <div>
              <div>{fileHasError}</div>
              <div>{currScrapedFile.mappingFailedReason.message}.</div>
            </div>
          ) : (
            noRecordMessage
          )}
        </div>
      )}
      {showMappingFailedModal && (
        <MappingFailedReasonModal
          description="File could not auto mapped due to following reasons."
          showModal={showMappingFailedModal}
          setModalVisibility={setMappingFailedReasonModalVisibility}
          mappingFailedReason={mappingFailedReasonDetail}
        />
      )}
    </div>
  );
};

export default MappingTable;
