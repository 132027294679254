import { CompetitorsComponent } from "@cimpress-technology/scraping-jobs";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAccountDetails } from "../services/scrapingToolConfigurations";

import Breadcrumb from "./breadcrumb/Breadcrumb";
import { competitorItems } from "./breadcrumb/breadcrumbElements";

const Competitors = props => {
  const { id } = useParams();
  const [accountName, setAccountName] = useState(undefined);

  useEffect(() => {
    if (id) {
      getAccountDetails(props.accessToken, id).then(each => setAccountName(each.name));
    }
    else {
      setAccountName(undefined);
    }
  }, [id, props.accessToken])

  return (
    <div className="container-fluid">
      <div style={{ paddingLeft: '15px' }}>
        <Breadcrumb separator="/">
          {competitorItems.map(({ to, label }) => (
            <Link key={to} to={to}>
              {label}
            </Link>
          ))}
        </Breadcrumb>
      </div>
      <div className="container-fluid">
        {accountName ? <CompetitorsComponent
          accessToken={props.accessToken}
          isProduction={props.isProduction}
          accountId={id}
          accountName={accountName}
        /> : null}
      </div>
    </div>
  );
};

export default Competitors;
