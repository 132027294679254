import { centralizedAuth, UserMetadata, Delegation } from '@cimpress/simple-auth-wrapper';

const auth = new centralizedAuth({
  clientID: process.env.REACT_APP_APPLICATION_CLIENT_ID,
  redirectRoute: '/',
});

Object.assign(auth, new UserMetadata(auth), new Delegation(auth));

export default auth;
