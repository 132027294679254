import moment from 'moment';

export const MDY_DATE_FORMAT = "YYYY-MM-DD";
export const MM_DD_YYYY = "MM/DD/YYYY";
export const MDY_HH_MM_DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const MM_DD_YY_DISPLAY = "LL";

export const getSixMonthAgo = () => {
  const SIX_MONTHS_AGO = new Date();
  SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6);
  return SIX_MONTHS_AGO;
};

export function toMonthDayYearDisplay(date) {
  if(!date) {
    return '';
  }
  return moment(date).format(MM_DD_YYYY);
}
