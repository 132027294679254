import { Modal, Button, FlexBox, TextField } from "@cimpress/react-components";
import React from "react";
import { useState } from "react";
import IconBin from "@cimpress-technology/react-streamline-icons/lib/IconBin";
import { getLocalStorage } from "../../services/localStorage";
import { SELECTEDACCOUNTCONFIGURATION_KEY } from "../../utils/constants";
import { getRuleSet } from "../../services/productModel";
import { StatusCodes } from "http-status-codes";
import { getAccessToken } from "../../utils/accessToken";

const AddSkuModal = ({
  showAddSkuModal,
  toggleAddSkuModal,
  currScrapedFile,
  onSaveSkus,
  isLoading,
  maxSkus,
  setShowSpinner,
}) => {
  const [newSKUs, setNewSKUs] = useState([]);
  const [newSkuToAdd, setNewSkuToAdd] = useState("");
  const [showCustomSkuModal, setShowCustomSkuModal] = useState(false);

  const [skuInputError, setSkuInputError] = useState({
    error: false,
    message: "",
  });

  const toggleCustomSkuModal = () => {
    setShowCustomSkuModal((prevState) => !prevState);
  };

  const onClickSave = async () => {
    if (maxSkus === 1) {
      if (newSkuToAdd.trim().length <= 0) {
        setSkuInputError({
          error: true,
          message: "Enter the SKU!",
        });
        setNewSkuToAdd("");
      } else if (newSkuToAdd.trim().length > 0) {
        const { sku, isValid, isCustomSku } = await isNewlyAddedSkuValid(
          newSkuToAdd
        );

        if (!isValid && isCustomSku) {
          setShowCustomSkuModal(true);
        } else if (isValid) {
          onSaveSkus({ productId: newSkuToAdd, version: sku?.version });
        }
      }
    } else {
      if (newSKUs.length <= 0) {
        setSkuInputError({
          error: true,
          message: "Add atleast one SKU!",
        });
      } else {
        onSaveSkus(newSKUs);
      }
    }
  };

  const onRemoveSku = (index) => {
    setNewSKUs((prevState) => {
      let prevSKUs = [...prevState];
      prevSKUs.splice(index, 1);
      return prevSKUs;
    });
  };

  const isNewlyAddedSkuAlreadyExists = (newSkuToAdd) => {
    let skuAlreadyExists = false;
    for (
      let indexOfReference = 0;
      indexOfReference < currScrapedFile.references.length;
      indexOfReference++
    ) {
      if (
        currScrapedFile.references[indexOfReference].productModelId ===
        newSkuToAdd
      ) {
        skuAlreadyExists = true;
        break;
      }
    }

    let isSkuAlreadyAdded = false;
    for (let indexOfSKU = 0; indexOfSKU < newSKUs.length; indexOfSKU++) {
      if (newSKUs[indexOfSKU].productId === newSkuToAdd) {
        isSkuAlreadyAdded = true;
        break;
      }
    }
    if (isSkuAlreadyAdded || skuAlreadyExists) {
      return true;
    }
    return false;
  };

  const isNewlyAddedSkuValid = async (newSkuToAdd) => {
    let isValid = true;
    let sku;
    if (newSKUs.length >= maxSkus) {
      setSkuInputError({
        error: true,
        message: `Only ${maxSkus} SKUs can be added at a time!`,
      });
      isValid = false;
    } else if (isNewlyAddedSkuAlreadyExists(newSkuToAdd)) {
      setSkuInputError({
        error: true,
        message: "SKU already exists!",
      });
      isValid = false;
    } else if (newSkuToAdd.length <= 0) {
      setSkuInputError({
        error: true,
        message: "Enter the SKU!",
      });
      setNewSkuToAdd("");
      isValid = false;
    }
    const selectedAccountConfiguration = getLocalStorage(
      SELECTEDACCOUNTCONFIGURATION_KEY
    );
    let isCustomSku = false;
    if (!selectedAccountConfiguration.value.disableSkuMapping) {
      try {
        sku = await getRuleSet(
          newSkuToAdd,
          undefined,
          true,
          getAccessToken,
          selectedAccountConfiguration.value.accountId,
          currScrapedFile.countryCode
        );
        if (!sku.mcpSkuVersion) {
          isCustomSku = true;
          isValid = false;
        }
      } catch (error) {
        if (error.status === StatusCodes.NOT_FOUND) {
          isCustomSku = true;
        } else {
          setSkuInputError({
            error: true,
            message: `Error while fetching data for given sku`,
          });
        }
        isValid = false;
      }
    }
    return { sku, isValid, isCustomSku };
  };

  const onAddSku = async (newSkuToAdd) => {
    setShowSpinner(true);

    if (newSkuToAdd.trim().length > 0) {
      const { sku, isValid, isCustomSku } = await isNewlyAddedSkuValid(
        newSkuToAdd
      );

      if (!isValid && isCustomSku) {
        setShowCustomSkuModal(true);
      } else if (isValid) {
        setNewSKUs([
          ...newSKUs,
          { productId: newSkuToAdd, version: sku?.mcpSkuVersion },
        ]);
        setNewSkuToAdd("");
      }
    }
    setShowSpinner(false);
  };

  const onClickContinueCustomSku = () => {
    if (maxSkus === 1) {
      onSaveSkus({ productId: newSkuToAdd, version: null });
    } else {
      setNewSKUs([...newSKUs, { productId: newSkuToAdd, version: null }]);
      setNewSkuToAdd("");
    }
    toggleCustomSkuModal();
  };

  const customSkuModalFooter = (
    <>
      <Button
        disabled={isLoading}
        onClick={toggleCustomSkuModal}
        variant="default">
        Cancel
      </Button>
      <Button
        disabled={isLoading}
        type="primary"
        onClick={() => onClickContinueCustomSku()}>
        Continue
      </Button>
    </>
  );

  const footer = (
    <>
      <Button
        disabled={isLoading}
        onClick={toggleAddSkuModal}
        variant="default">
        Cancel
      </Button>
      <Button disabled={isLoading} type="primary" onClick={() => onClickSave()}>
        Save
      </Button>
    </>
  );

  const newSkuList = newSKUs.map((sku, index) => {
    return (
      <FlexBox key={sku.productId} left style={{ marginBottom: "5px" }}>
        <span>{sku.productId}</span>
        <Button
          disabled={isLoading}
          type="anchor"
          size="sm"
          onClick={() => onRemoveSku(index)}
          style={{ marginTop: "-2px" }}>
          <IconBin />
        </Button>
      </FlexBox>
    );
  });

  return (
    <>
      <Modal
        closeButton
        show={showAddSkuModal}
        title={maxSkus === 1 ? "Add SKU" : "Add SKUs"}
        onRequestHide={() => toggleAddSkuModal()}
        footer={footer}>
        {newSkuList}
        <FlexBox style={{ marginTop: "20px" }}>
          <TextField
            disabled={isLoading}
            bsStyle={skuInputError.error ? "error" : ""}
            label="Enter a SKU"
            name="newSku"
            helpText={skuInputError.error ? skuInputError.message : ""}
            value={newSkuToAdd}
            onChange={(e) => {
              setSkuInputError(false);
              setNewSkuToAdd(e.target.value);
            }}
            style={{ width: "250px", marginRight: "10px" }}
          />
          {maxSkus !== 1 && (
            <Button
              disabled={isLoading}
              variant="default"
              style={{ height: "48px" }}
              onClick={() => onAddSku(newSkuToAdd.trim())}>
              Add
            </Button>
          )}
        </FlexBox>
      </Modal>
      <Modal
        closeButton
        show={showCustomSkuModal}
        title={"Custom SKU Added"}
        onRequestHide={() => toggleCustomSkuModal()}
        footer={customSkuModalFooter}>
        The SKU {newSkuToAdd} is a Custom SKU. Are you sure you want to
        continue?
      </Modal>
    </>
  );
};

export default AddSkuModal;
