import { getAttributeValues } from "./productAttributeMapping";
import { request, httpMethod } from "./request";
import { areKeysSame } from "../utils/utils";

export const getNlpData = async (
  scrapeKeys,
  scrapedAttributes,
  tenantKey,
  productModelData,
  getAccessToken
) => {
  const tenantValues = getAttributeValues(productModelData, tenantKey).map(
    (x) => x.value
  );
  const values = scrapedAttributes.map((x) => x.data[0].value);
  const payLoad = {
    attributes: [createNlpRequest(scrapeKeys[0], values)],
    choices: [createNlpRequest(tenantKey, tenantValues)],
  };
  const response = await request(
    process.env.REACT_APP_NLP_SERVICE_URL,
    getAccessToken,
    JSON.stringify(payLoad),
    httpMethod.POST
  );
  const nlpResponse = JSON.parse(response);
  const tenantAttributes = [];

  scrapedAttributes.forEach((attribute) => {
    tenantAttributes.push({
      scrapeId: attribute.id,
      data: getTenantAttributesData(nlpResponse, attribute.data),
    });
  });
  return tenantAttributes;
};

const getTenantAttributesData = (nlpResponse, data) => {
  const scrapeValue = data[0].value;
  const result = nlpResponse[0].result.find((x) =>
    areKeysSame(x.attributeValue, scrapeValue)
  );
  const match = result.matches[0];
  const matchedValues = match.matchedValues[0];
  let value = [];
  let ratio = matchedValues.ratio;
  if (ratio >= 80) {
    value.push(matchedValues.value);
  }
  return [
    {
      key: match.matchKey,
      value: value,
      label: value,
      ratio: ratio >= 80 ? ratio + "%" : " ",
      selectionList: match,
    },
  ];
};

const createNlpRequest = (key, values) => {
  return { key: key, values: values };
};
