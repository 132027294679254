import React from "react";
import CreatableSelect from "react-select/creatable";
import "./CreatableMenu.css";

function Creatable(props) {
  const {
    index,
    ruleIndex,
    value,
    items,
    label,
    onChange,
    tenantKey,
    disableActions,
  } = props;

  const formatOptionLabel = ({ value, label, ratio }) => {
    let txt = ratio === undefined ? "" : `( ${ratio}% match)`;
    return (
      <div style={{ display: "flex" }}>
        <div>
          {label}
          <span style={{ fontSize: "10px", color: "#585858" }}>{txt}</span>
        </div>
      </div>
    );
  };
  function handleOnChange(selectedValue, e) {
    let valueArray = [];
    if (selectedValue) {
      selectedValue.forEach((eachValue) => {
        valueArray.push(eachValue.value);
      });
    }
    onChange(valueArray, index, tenantKey, ruleIndex);
  }

  const customStyles = {
    container: (provided) => ({
      ...provided,
      marginBottom: "15px !important",
    }),
    control: (provided) => ({
      ...provided,
      height: "48px !important",
    }),
  };

  return (
    <CreatableSelect
      isDisabled={disableActions}
      required={"required"}
      isMulti
      formatOptionLabel={formatOptionLabel}
      isSearchable
      options={items}
      onChange={handleOnChange}
      value={value}
      placeholder={label}
      showNewOptionAtTop={false}
      noOptionsMessage={() => null}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={disableActions ? customStyles : undefined}
    />
  );
}

export default Creatable;
