import { areKeysSame } from "../utils/utils";
import { isEmpty } from "lodash";
import { ScrapedKeyState } from "../utils/constants";

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const setSelectedKey = (list, index, selectedKeyId) => {
  const selectedIndex = list.findIndex((x) => x.ruleId === selectedKeyId);
  const result = [...list];
  result[selectedIndex] = { ...result[selectedIndex] };
  result[selectedIndex].isSelected = false;
  result[index] = { ...result[index] };
  result[index].isSelected = true;
  return result;
};

export const createDropdownElement = (elements) => {
  if (elements === undefined) return [];
  return elements.map((data) => {
    return { label: data[0], value: data[0] };
  });
};

export const findScrapedValue = (rules, index, key) => {
  let rule = rules[index];
  let scrapedAttribute = rule.scrapedAttributes.find((x) =>
    areKeysSame(x.key, key)
  );
  if (scrapedAttribute === undefined) return "";
  return scrapedAttribute.value;
};

export const findValueOfKey = (data, key) => {
  let scrapedAttribute = data.find((x) => areKeysSame(x.key, key));
  if (scrapedAttribute === undefined) return "";
  return scrapedAttribute.value;
};

export const findValuesOfKey = (data, key) => {
  let attribute = data.find((x) => areKeysSame(x.key, key, true));
  if (attribute === undefined) return [];
  if (attribute.value[0] === "object") {
    return attribute.value;
  } else {
    let values = [];
    attribute.value.forEach((attributeValue) => {
      values.push({
        value: attributeValue,
        label: attributeValue,
      });
    });
    return values;
  }
};

export const findRatioOfKey = (data, key) => {
  let scrapedAttribute = data.find((x) => x.key === key);
  if (scrapedAttribute === undefined || scrapedAttribute.ratio === undefined)
    return "    ";
  return scrapedAttribute.ratio;
};

export const findScrapedValue2 = (rules, index, key) => {
  let rule = rules[index];
  let scrapedAttribute = rule.scrapedAttributes.find((x) =>
    areKeysSame(x.key, key)
  );
  if (scrapedAttribute === undefined) return "";
  return scrapedAttribute.value;
};

export const findTenantValue = (rules, index, key) => {
  let rule = rules[index];
  let tenantAttribute = rule.tenantAttributes.find((x) =>
    areKeysSame(x.key, key)
  );
  if (tenantAttribute === undefined) return "";
  return tenantAttribute.value;
};

export const changeTenantKey1 = (mappedData, value, index) => {
  const tenantKeys = [...mappedData.tenantKeys];
  tenantKeys[index] = value;
  return tenantKeys;
};

export const changeTenantKey = (translationRules, value, ruleIndex, index) => {
  const rules = [...translationRules];
  rules[ruleIndex] = { ...rules[ruleIndex] };
  rules[ruleIndex].tenantKeys = [...rules[ruleIndex].tenantKeys];
  rules[ruleIndex].tenantKeys[index] = value;
  return rules;
};

export const deleteTenantKey = (translationRules, ruleIndex, index) => {
  const rules = [...translationRules];
  rules[ruleIndex] = { ...rules[ruleIndex] };
  let tenantKeys = [...rules[ruleIndex].tenantKeys];
  tenantKeys.splice(index, 1);
  rules[ruleIndex].tenantKeys = tenantKeys;
  return rules;
};

export const deleteScrapeKey = (list, selectedIndex, ruleIndex, index) => {
  const result = [...list];
  result[selectedIndex] = { ...result[selectedIndex] };
  const scrapedKeys = [...result[selectedIndex].scrapedKeys];
  const mergedScrapeKey = scrapedKeys[index];
  scrapedKeys.splice(index, 1);
  result[selectedIndex].scrapedKeys = scrapedKeys;
  setScrapeKeyActiveState(result, mergedScrapeKey, true);
  return result;
};

const setScrapeKeyActiveState = (
  scrapedKeyStates,
  mergedScrapeKey,
  isActive
) => {
  if (mergedScrapeKey !== "") {
    const crapedKeyIndex = scrapedKeyStates.findIndex(
      (x) => x.scrapedKeys[0] === mergedScrapeKey
    );
    scrapedKeyStates[crapedKeyIndex] = {
      ...scrapedKeyStates[crapedKeyIndex],
    };
    scrapedKeyStates[crapedKeyIndex].isActive = isActive;
    scrapedKeyStates[crapedKeyIndex].mappingState =
      scrapedKeyStates[crapedKeyIndex].mappingState === ScrapedKeyState.MAPPED
        ? ScrapedKeyState.PARTIALLY_MAPPED
        : scrapedKeyStates[crapedKeyIndex].mappingState;
  }
};

export const changeScrapeKey = (list, selectedIndex, value, index) => {
  const result = [...list];
  result[selectedIndex] = { ...result[selectedIndex] };
  const scrapedKeys = [...result[selectedIndex].scrapedKeys];
  setScrapeKeyActiveState(result, scrapedKeys[index], true);
  setScrapeKeyActiveState(result, value, false);
  scrapedKeys[index] = value;
  result[selectedIndex].scrapedKeys = scrapedKeys;
  result[selectedIndex].mappingState = ScrapedKeyState.PARTIALLY_MAPPED;
  return result;
};

export const setSelectedKeyInActive = (list, selectedIndex, value) => {
  const indexMarkInActive = list.findIndex((x) => x.scrapedKeys[0] === value);
  list[indexMarkInActive] = { ...list[indexMarkInActive] };
  list[indexMarkInActive].isActive = false;
  return list;
};

export const copyScrapeValues = (translationRules, ruleIndex) => {
  const rules = [...translationRules];
  const rule = { ...rules[ruleIndex] };
  let localTenantAttributes = [];
  const { scrapedAttributes, tenantAttributes, tenantKeys } = rule;
  const tenantKey = tenantKeys[0];
  scrapedAttributes.forEach((scrapedAttribute, index) => {
    let newData = {};
    newData.key = tenantKey;
    newData.value = [];
    newData.value.push(scrapedAttribute.data[0].value);
    let data = [];
    tenantAttributes[index].data.forEach((element) => {
      if (areKeysSame(element.key, tenantKey, true)) {
        newData = { ...element };
        if (isEmpty(newData.value)) {
          newData.value.push(scrapedAttribute.data[0].value);
        }
      } else {
        data.push(element);
      }
    });
    data.push(newData);
    localTenantAttributes.push({ scrapeId: scrapedAttribute.id, data: data });
  });

  rule.tenantAttributes = localTenantAttributes;
  rules[ruleIndex] = rule;
  return rules;
};

export const createMergedCombination = (
  translationRules,
  ruleIndex,
  scrapedKeys,
  mergedCombinationData
) => {
  const rules = [...translationRules];
  rules[ruleIndex] = { ...rules[ruleIndex] };

  let scrapedAttributes = [];
  let tenantAttributes = [];

  mergedCombinationData.forEach((combinationData, combinationIndex) => {
    let scrapeData = [];
    scrapedKeys.forEach((key) => {
      let value = combinationData[key];
      scrapeData.push({ key: key, value: value });
    });
    scrapedAttributes.push({ id: combinationIndex, data: scrapeData });
    tenantAttributes.push({
      scrapeId: combinationIndex,
      data: [],
    });
  });
  rules[ruleIndex].scrapedAttributes = scrapedAttributes;
  rules[ruleIndex].tenantAttributes = tenantAttributes;

  return rules;
};

export const changeTenantValue = (
  translationRules,
  value,
  ruleIndex,
  index,
  tenantKey,
  ratio
) => {
  const rules = [...translationRules];
  rules[ruleIndex] = { ...rules[ruleIndex] };
  const tenantAttributes = [...rules[ruleIndex].tenantAttributes];
  tenantAttributes[index] = { ...tenantAttributes[index] };
  const data = [...tenantAttributes[index].data];
  let tenantKeyIndex = data.findIndex((x) => areKeysSame(x.key, tenantKey));
  if (tenantKeyIndex === -1) {
    tenantAttributes[index].data.push({
      key: tenantKey,
      value: value,
      ratio: ratio !== undefined ? ratio + "%" : "",
    });
  } else {
    tenantAttributes[index].data[tenantKeyIndex] = {
      ...data[tenantKeyIndex],
    };
    tenantAttributes[index].data[tenantKeyIndex].value = value;
    if (ratio !== undefined) {
      tenantAttributes[index].data[tenantKeyIndex].ratio = ratio + "%";
    } else {
      tenantAttributes[index].data[tenantKeyIndex].ratio = "";
    }
  }
  rules[ruleIndex].tenantAttributes = tenantAttributes;
  return rules;
};

export const autoPopulateTenantValues = (mappedData) => {
  let rules = [...mappedData.rules];
  const key = mappedData.tenantKeys[0];
  rules.forEach((rule, ruleIndex) => {
    const { scrapedAttributes, tenantAttributes } = rule;
    const tenantAttribute = tenantAttributes.find((tenantAttribute) =>
      areKeysSame(tenantAttribute.key, key)
    );
    const scrapeValue = scrapedAttributes[0].value;
    rules[ruleIndex] = { ...rule };
    if (!tenantAttribute) {
      rules[ruleIndex].tenantAttributes = [{ key: key, value: scrapeValue }];
    } else {
      if (isEmpty(tenantAttribute.value)) {
        rules[ruleIndex].tenantAttributes = [{ ...tenantAttribute }];
        rules[ruleIndex].tenantAttributes[0].value = scrapeValue;
      }
    }
  });
  return rules;
};

export const tenantKey = "Tenant Key";
export const tenantValue = "Tenant Value";
export const attributeValue = "Attribute Value";

export const divStyle = {
  display: "block",
  border: ".5px solid #ccc",
  margin: "1em 0",
  padding: 0,
};
