import React, { useRef, useEffect, useState } from "react";
import { Select, Button, FlexBox, Tooltip } from "@cimpress/react-components";
import DatePicker from "@cimpress/react-components/lib/DatePicker";
import { snackBarStatus } from "../../constants/general";
import { getMasterData } from "../../services/master";
import { getOptions } from "../../utils/common";
import { MDY_DATE_FORMAT } from "../../utils/dateFormat";
import {
  getCategories,
  getCountries,
  getMapForCompetitor,
} from "../../utils/manageMapping";
import mappingSearchStyles from "./MappingManagement.module.css";
import moment from "moment";
import IconSynchronizeArrowsAlt from "@cimpress-technology/react-streamline-icons/lib/IconSynchronizeArrowsAlt";

const MappingSearch = (props) => {
  const {
    accountId,
    onFilter,
    showAlert,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = props;
  const [competitorOptions, setCompetitorOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [competitor, setCompetitor] = useState();
  const [country, setCountry] = useState();
  const [category, setCategory] = useState();
  const [masterData, setMasterData] = useState([]);
  const mapByCompetitor = useRef(new Map());

  useEffect(() => {
    async function fetchMasterData() {
      const response = await getMasterData(accountId);
      setMasterData(response);
    }
    fetchMasterData();
  }, [accountId]);

  useEffect(() => {
    let masterResponse = props.qIsShared
      ? masterData.filter((x) => x.ownerAccountId !== accountId)
      : masterData.filter((x) => x.ownerAccountId === accountId);
    mapByCompetitor.current = new Map();
    mapByCompetitor.current = getMapForCompetitor(
      masterResponse,
      mapByCompetitor.current
    );
    const competitorsList = Array.from(mapByCompetitor.current.keys());
    setCompetitorOptions(getOptions(competitorsList));
  }, [masterData, accountId, props.qIsShared]);

  useEffect(() => {
    props.qCompetitor
      ? setCompetitor({ value: props.qCompetitor, label: props.qCompetitor })
      : setCompetitor(null);
    props.qCategory
      ? setCategory({ value: props.qCategory, label: props.qCategory })
      : setCategory(null);
    props.qCountry
      ? setCountry({ value: props.qCountry, label: props.qCountry })
      : setCountry(null);
  }, [props.qCompetitor, props.qCategory, props.qCountry]);

  const updateCategoryOptions = (selectedCountry, competitor) => {
    var categories = selectedCountry
      ? getOptions(
          getCategories(
            mapByCompetitor.current,
            competitor.value,
            selectedCountry.value
          )
        )
      : [];
    setCategoryOptions(categories);
    if (selectedCountry !== null && categories && categories.length === 1) {
      setCategory(categories[0]);
    }
  };

  const validateEndDatePickerDate = (date) => {
    const earliestStartDate = moment().add(1, "days").startOf("day");
    return date.isSameOrBefore(earliestStartDate);
  };

  const updateCountryOptions = (selectedCompetitor) => {
    var countries = selectedCompetitor
      ? getOptions(
          getCountries(mapByCompetitor.current, selectedCompetitor.value)
        )
      : [];
    setCountryOptions(countries);
    if (selectedCompetitor !== null && countries && countries.length === 1) {
      setCountry(countries[0]);
      updateCategoryOptions(countries[0], selectedCompetitor);
    } else {
      setCategoryOptions([]);
    }
  };

  useEffect(() => {
    if (competitorOptions && competitorOptions.length > 0) {
      setCountryOptions(
        competitor
          ? getOptions(getCountries(mapByCompetitor.current, competitor.value))
          : []
      );
      setCategoryOptions(
        country
          ? getOptions(
              getCategories(
                mapByCompetitor.current,
                competitor.value,
                country.value
              )
            )
          : []
      );
    }
  }, [competitor, country, competitorOptions]);

  const onChangeCompetitor = (selectedCompetitor) => {
    setCategory(null);
    setCountry(null);
    setCompetitor(selectedCompetitor);
    updateCountryOptions(selectedCompetitor);
  };

  const onCountrySelectionChange = (selectedCategory) => {
    setCategory(null);
    setCountry(selectedCategory);
    updateCategoryOptions(selectedCategory, competitor);
  };

  const onClickSearch = () => {
    if (!competitor) {
      showAlert({
        message: "Please select a competitor.",
        status: snackBarStatus.danger,
        delay: 2000,
      });
      return;
    }

    if (!category) {
      showAlert({
        message: "Please select a category.",
        status: snackBarStatus.danger,
        delay: 2000,
      });
      return;
    }

    if (!country) {
      showAlert({
        message: "Please select a country.",
        status: snackBarStatus.danger,
        delay: 2000,
      });
      return;
    }

    onFilter(competitor.value, category?.value, country?.value);
  };

  return (
    <>
      <div>
        <FlexBox>
          <FlexBox spaceBetween className={mappingSearchStyles.mappingSearch}>
            <div>
              <Select
                label="Select Competitor"
                value={competitor}
                options={competitorOptions}
                onChange={onChangeCompetitor}
                isClearable
                required
                status="error"
              />
            </div>
            <div>
              <Select
                label={"Select Country"}
                value={country}
                options={countryOptions}
                onChange={onCountrySelectionChange}
                isClearable
                required
              />
            </div>
            <div>
              <Select
                label={"Select Category"}
                value={category}
                options={categoryOptions}
                onChange={(value) => setCategory(value)}
                isClearable
                required
              />
            </div>
            <div>
              <DatePicker
                onChange={(value) => {
                  setStartDate(value);
                }}
                closeOnSelect={true}
                dateFormat={MDY_DATE_FORMAT}
                timeFormat={false}
                value={startDate}
                placeholder="Scraped Date from"
                utc={true}
              />
            </div>
            <div>
              <DatePicker
                onChange={(value) => {
                  setEndDate(value);
                }}
                closeOnSelect={true}
                dateFormat={MDY_DATE_FORMAT}
                timeFormat={false}
                value={endDate}
                placeholder="Scraped Date to"
                utc={true}
                isValidDate={validateEndDatePickerDate}
              />
            </div>
          </FlexBox>
          <div>
            <Button
              type="primary"
              size="lg"
              onClick={onClickSearch}
              style={{ height: "48px" }}>
              Search
            </Button>
          </div>
          <Tooltip direction="left" contents="Refresh the table">
            <Button
              type="primary"
              size="sm"
              style={{ minHeight: "48px", marginLeft: "10px" }}
              onClick={onClickSearch}>
              <IconSynchronizeArrowsAlt size="lg" />
            </Button>
          </Tooltip>
        </FlexBox>
      </div>
    </>
  );
};

export default MappingSearch;
