import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { shapes } from "@cimpress/react-components";
import { getDisplayMessage } from "../../services/errorMessages";
import ProductAttributeMapping from "./ProductAttributeMapping";
import { getFileData } from "../../services/fileListingService";
import ErrorRobot from "./ErrorRobot";
import { getStandardProductMappingConfig } from "../../services/metaData";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { ViewMappingScreen } from "../breadcrumb/breadcrumbElements";
import { routeNames } from "../../constants/general";
import mappingStyles from "./App.module.css";

const msg404 = "Nothing here";
const getInitialState = (competitor) => {
  return {
    selectedFileInfo: { competitor: competitor },
    mappingActionType: undefined,
    shouldLoad: false,
    isInValidRoute: false,
    msg: "Nothing here",
  };
};
const isValidRoute = (data, competitor, category, country) => {
  if (
    data.competitor.trim() === competitor.trim() &&
    data.category.trim() === category.trim() &&
    data.countryCode.trim() === country.trim()
  )
    return true;
  return false;
};
export default function Mapping(props) {
  const { getAccessToken } = props;
  const [sharedScrapedDataConfig, setSharedScrapedDataConfig] =
  useState(undefined);
  const [showSpinner, setSpinner] = useState(false);

  let params = useParams();
  const { fileId, modelMappingId, competitor, category, country, id } = params;
  const decodedCompetitor = decodeURIComponent(competitor);
  const decodedCategory = decodeURIComponent(category);

  const [data, setData] = useState(() => getInitialState(decodedCompetitor));

  useEffect(() => {
    async function fetchStandardProductMappingConfig(id) {
      if (!id) {
        setSharedScrapedDataConfig(undefined);
        return;
      }
      const response = await getStandardProductMappingConfig(id);
      setSharedScrapedDataConfig(response);
    }
    fetchStandardProductMappingConfig(id);
  }, [id, getAccessToken]);

  useEffect(() => {
    const fetchFileInformation = async () => {
      setData((state) => {
        return { ...state, shouldLoad: false, isInValidRoute: false };
      });
      let response = await getFileData(getAccessToken, fileId, id);

      const canEditStandardProductMapping =
        sharedScrapedDataConfig.canEditStandardProductMapping;
      const canViewStandardProductMapping =
        sharedScrapedDataConfig.canViewStandardProductMapping;
      let mappingDetails = response.references.find(
        (element) => `${element.referenceId}` === modelMappingId
      );

      if (
        !canEditStandardProductMapping &&
        !canViewStandardProductMapping &&
        mappingDetails &&
        mappingDetails.isStandard
      ) {
        mappingDetails = undefined;
      }

      if (
        !isValidRoute(response, decodedCompetitor, decodedCategory, country) ||
        (!mappingDetails && modelMappingId)
      ) {
        setData((state) => {
          return {
            ...state,
            shouldLoad: false,
            isInValidRoute: true,
            msg: msg404,
          };
        });
        return;
      }

      response.fileId = fileId;
      response.modelMappingId = modelMappingId;
      if (mappingDetails) {
        response.productModel = mappingDetails.productModelId;
        delete mappingDetails.productModelId;
        delete mappingDetails.referenceId;
        setData((state) => {
          return {
            ...state,
            selectedFileInfo: { ...mappingDetails, ...response },
            shouldLoad: true,
            isInValidRoute: false,
          };
        });
      } else {
        setData((state) => {
          return {
            ...state,
            selectedFileInfo: response,
            shouldLoad: true,
            isInValidRoute: false,
          };
        });
      }
    };
    // Until configuration is not fetch, don't fetch file details.
    if (!sharedScrapedDataConfig) return;
    fetchFileInformation().catch((error) => {
      setData((state) => {
        return {
          ...state,
          shouldLoad: false,
          isInValidRoute: true,
          msg: getDisplayMessage({ error: error }),
        };
      });
      setSpinner(false);
    });
  }, [
    getAccessToken,
    id,
    decodedCompetitor,
    decodedCategory,
    country,
    fileId,
    modelMappingId,
    sharedScrapedDataConfig,
  ]);

  function showScreen() {
    const isShared = data.selectedFileInfo.accountId !== id ? true : false;
    return (
      <div
        className={`${mappingStyles.productAttributeMappingContainer} container-fluid`}
      >
        <Breadcrumb separator="/">
          {ViewMappingScreen.map(({ to, label }, index) =>
            index === 2 ? (
              <span key={to}>{label}</span>
            ) : index === 1 ? (
              <Link
                key={`/${
                  routeNames.MAPPING_MANAGEMENT
                }/${id}/${encodeURIComponent(
                  decodedCompetitor
                )}/${country}/${encodeURIComponent(decodedCategory)}`}
                to={`/${
                  routeNames.MAPPING_MANAGEMENT
                }/${id}/${encodeURIComponent(
                  decodedCompetitor
                )}/${country}/${encodeURIComponent(decodedCategory)}?isshared=${isShared}`}
              >
                {label}
              </Link>
            ) : (
              <Link key={to} to={to}>
                {label}
              </Link>
            )
          )}
        </Breadcrumb>
        <ProductAttributeMapping
          selectedFileInfo={data.selectedFileInfo}
          getAccessToken={getAccessToken}
          accountId={id}
          sharedScrapedDataConfig={sharedScrapedDataConfig}
        />
      </div>
    );
  }

  const { Spinner } = shapes;
  return (
    <React.Fragment>
      {data.isInValidRoute ? (
        <ErrorRobot includeContact={false} msg={data.msg} />
      ) : null}
      {data.shouldLoad ? showScreen() : null}{" "}
      {showSpinner && <Spinner fullPage />}
    </React.Fragment>
  );
}
