import { Modal, Button } from "@cimpress/react-components";
import React from "react";

const ConfirmationModal = (props) => {
  return (
    <Modal
      closeButton
      show={props.show}
      title={props.title}
      onRequestHide={props.onNonPrimaryButtonClickAction}
      bsStyle={props.bsStyle}
      footer={
        <>
          <Button onClick={props.onNonPrimaryButtonClickAction} type="default">
            {props.nonPrimaryButton}
          </Button>
          <Button type="primary" onClick={props.onPrimaryButtonClickAction}>
            {props.primaryButton}
          </Button>
        </>
      }
    >
      {props.description}
    </Modal>
  );
};

export default ConfirmationModal;
