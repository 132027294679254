import { isEmpty } from "lodash";

export const areKeysSame = (key1, key2, isCaseSensitive) => {
  if (key1 === key2) {
    return true;
  } else if (
    !isCaseSensitive &&
    key1 &&
    key2 &&
    key1.trim().toLowerCase() === key2.trim().toLowerCase()
  ) {
    return true;
  }
  return false;
};

//This function checks if given key passed in (keyName parameter) is empty in the data.
export const CheckIfEmpty = (keyName, data) => {
  return isEmpty(data) || data.some((x) => isEmpty(x[keyName]));
};

//This function toggles the visibility of the controls based on the element value is true or false
export const toggleVisibilityOnElement = (element) => {
  if (!element) {
    return { display: "none" };
  }
  return { display: "" };
};

export const getFormattedDate = (dt) => {
  if (dt === null || dt === undefined || dt === "") return "";
  return new Date(dt).toLocaleDateString();
};

export const getQueryStringForMapping = (
  productCategory,
  country,
  mcpSku = undefined
) => {
  const category = productCategory
    ? encodeURIComponent(productCategory)
    : productCategory;
  const sku = mcpSku ? encodeURIComponent(mcpSku) : mcpSku;
  // We need to maintain the order of following if statements.
  if (!category && !country && !sku) {
    return ``;
  }

  if (!category && !country && sku) {
    return `?mcpSKU=${sku}`;
  }

  if (!category && country && !sku) {
    return `?country=${country}`;
  }

  if (!category && country && sku) {
    return `?country=${country}&mcpSKU=${sku}`;
  }

  if (category && !country && !sku) {
    return `?category=${category}`;
  }

  if (category && !country && sku) {
    return `?category=${category}&mcpSKU=${sku}`;
  }

  if (category && country && !sku) {
    return `?country=${country}&category=${category}`;
  }

  if (category && country && sku) {
    return `?country=${country}&category=${category}&mcpSKU=${sku}`;
  }
};

export const checkAllColumnSelected = (validationField, data) => {
  return validationField.some((item) => {
    return  data.questionnaireData[item] === "";
  });
};

export const checkColumnAlreadySelected = (
  validationFields,
  key,
  value,
  data
) => {
  return validationFields.find(
    (x) =>  data.questionnaireData[x] === value && x !== key && value !== ""
  );
};

export const convertToOptionFormat = (elements) => {
  if (!elements) return [];
  return elements.map((data) => {
    return { label: data, value: data };
  });
};

export const convertToTitleCase = (str) => {
  var sentence = str.toLowerCase().split(" ");
  for (var i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence;
};

export const getColumnData = (columnName, data) => {
  if (!isEmpty(data.scrapedData)) {
    return data.scrapedData.find((x) =>
      areKeysSame(x.attributeKey, columnName)
    );
  } else {
    return [];
  }
};

export const generateToolTip = (scrapedKeys, tenantKeys) =>
    {
      let list = new Set();
      for(let i = 0; i < scrapedKeys.length; i++)
      {
        list.add(scrapedKeys[i].split(' ').join(''));
      }
      for(let i = 0; i < tenantKeys.length; i++)
      {
        if(list.has(tenantKeys[i].split(' ').join('')))
        {
          list.delete(tenantKeys[i].split(' ').join(''));
        }
        list.add(tenantKeys[i]);
      }
      let toolTip = `Split ${[...list][0]}`;
      if (list.size > 1) {
      let arrayFromSet = [...list];
    for (let i = 1; i < arrayFromSet.length - 1; i++) {
      toolTip += `, ${arrayFromSet[i]}`;
    }
  }

      toolTip += ` and ${[...list][list.size-1]}  to remove it from scraped files`;
      return toolTip;
    }