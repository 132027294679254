import React from "react";
import { Snackbar } from "@cimpress/react-components";
import { getDisplayMessage } from "../services/errorMessages";
import { snackBarStatus } from "../constants/general";

const SnackBarAlert = ({ snackbar, closeAlert }) => {
  return (
    <Snackbar
      show={snackbar.show ?? false}
      bsStyle={snackbar.status ?? snackBarStatus.info}
      delay={snackbar.delay}
      onHideSnackbar={() => closeAlert()}
    >
      {getDisplayMessage({
        customMessage: snackbar.message,
        error: snackbar.error,
      })}
    </Snackbar>
  );
};

export default SnackBarAlert;
