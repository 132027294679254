import { request, httpMethod } from "./request";
import { isEmpty } from "lodash";
import _ from "underscore";
import { getRuleSet } from "./productModel";
import { ScrapedKeyState } from "../utils/constants";
import { areKeysSame } from "../utils/utils";
import {
  isScrapedKeysEmpty,
  isTenantKeysEmpty,
  isEmptyTenantValue,
  isSelectedKeyPartiallyMapped,
} from "../utils/validation";
import { buildQuery } from "../utils/common";

export const getMappedData = async (
  productModel,
  modelMappingId,
  selectedFileInfo,
  getAccessToken,
  accountId
) => {
  const mappingReference = await getNormalizeData(
    modelMappingId,
    getAccessToken
  );
  const {
    tenant,
    translationRules,
    defaultValues,
    deletedScrapeAttributes,
    isDraft,
    copiedScrapeAttributes,
    skuVersion,
  } = mappingReference;
  if (
    defaultValues.scrapedAttributes === null &&
    defaultValues.tenantAttributes === null &&
    translationRules.length <= 0 &&
    deletedScrapeAttributes.length <= 0
  ) {
    return null;
  }
  const normalizedMapData = convertModelMappingReference(
    translationRules,
    deletedScrapeAttributes
  );
  const ruleSet = await getRuleSet(
    productModel,
    skuVersion,
    isDraft,
    getAccessToken,
    accountId,
    selectedFileInfo.countryCode
  );

  let newDefaultValues = defaultValues;
  let newNormalizedMapData = normalizedMapData;
  let attributeMappings;
  if (isDraft && skuVersion !== ruleSet.mcpSkuVersion) {
    var transformedData = await tranformModelMappings(
      modelMappingId,
      getAccessToken
    );

    newDefaultValues = transformedData.defaultValues;
    newNormalizedMapData = convertModelMappingReference(
      transformedData.translationRules,
      deletedScrapeAttributes
    );
    attributeMappings = transformedData.changes?.attributeMappings;
  }

  return {
    ...newNormalizedMapData,
    productModelData: ruleSet.whiteList,
    showMappingSection: true,
    mcpSku: productModel,
    mcpSkuVersion: isDraft ? ruleSet.mcpSkuVersion : skuVersion,
    selectedMerchant: tenant,
    defaultTenantData: convertToDefaultTenantData(
      newDefaultValues,
      ruleSet.whiteList
    ),
    selectedKeyId: newNormalizedMapData.scrapedKeyStates[0].ruleId,
    isDraft: isDraft,
    copiedScrapeAttributes: copiedScrapeAttributes ?? [],
    attributesTransformed: attributeMappings,
  };
};

export const getPreviousMappedData = async (
  fileId,
  productModel,
  modelMappingId,
  countryCode,
  getAccessToken,
  accountId
) => {
  const promises = [
    getPreviousNormalizeData(fileId, modelMappingId, getAccessToken),
    getRuleSet(
      productModel,
      undefined,
      true,
      getAccessToken,
      accountId,
      countryCode
    ),
  ];
  const [mappingReference, ruleSet] = await Promise.all(promises);
  const { tenant, translationRules, defaultValues, deletedScrapeAttributes } =
    mappingReference;
  const normalizedMapData = convertModelMappingReference(
    translationRules,
    deletedScrapeAttributes
  );
  return {
    ...normalizedMapData,
    productModelData: ruleSet.whiteList,
    showMappingSection: true,
    mcpSku: productModel,
    mcpSkuVersion: ruleSet.mcpSkuVersion,
    selectedMerchant: tenant,
    defaultTenantData: convertToDefaultTenantData(
      defaultValues,
      ruleSet.whiteList
    ),
    selectedKeyId: normalizedMapData.scrapedKeyStates[0].ruleId,
  };
};

export const getPreviousModelMappingDetails = async (
  accountId,
  competitor,
  category,
  countryCode,
  getAccessToken
) => {
  return await request(
    `${
      process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI
    }/v1/scrapedfile/latestModelMapping/${accountId}/${encodeURIComponent(
      competitor
    )}?category=${encodeURIComponent(category)}&country=${countryCode}`,
    getAccessToken
  );
};

export const updateMappingStates = (translationRules, scrapedKeyStates) => {
  let unmappedRuleIds = [];
  isScrapedKeysEmpty(scrapedKeyStates).forEach((element) => {
    unmappedRuleIds.push(element);
  });
  isTenantKeysEmpty(translationRules, scrapedKeyStates).forEach((element) => {
    unmappedRuleIds.push(element);
  });
  isEmptyTenantValue(translationRules, scrapedKeyStates).forEach((element) => {
    unmappedRuleIds.push(element);
  });

  const uniqueUnmappedRuleIds = unmappedRuleIds.filter(unique);

  scrapedKeyStates.forEach((scrapedKeyState, index) => {
    if (scrapedKeyState.mappingState !== ScrapedKeyState.DELETED) {
      let state = { ...scrapedKeyState };
      state.mappingState = ScrapedKeyState.MAPPED;
      scrapedKeyStates[index] = state;
    }
  });

  if (uniqueUnmappedRuleIds.length) {
    uniqueUnmappedRuleIds.forEach((ruleId) => {
      scrapedKeyStates.forEach((scrapedKeyState, index) => {
        if (scrapedKeyState.ruleId === ruleId) {
          let state = { ...scrapedKeyState };
          state.mappingState = ScrapedKeyState.PARTIALLY_MAPPED;
          scrapedKeyStates[index] = state;
        }
      });
    });
  }

  return scrapedKeyStates;
};

export const updateSelectedKeyMappingState = (
  selectedKeyId,
  scrapedKeyStates,
  translationRules
) => {
  const selectedIndex = scrapedKeyStates.findIndex(
    (x) => x.ruleId === selectedKeyId
  );
  const result = [...scrapedKeyStates];
  result[selectedIndex] = { ...result[selectedIndex] };
  if (result[selectedIndex].mappingState !== ScrapedKeyState.DELETED) {
    if (
      isSelectedKeyPartiallyMapped(
        result[selectedIndex],
        translationRules[selectedIndex]
      )
    ) {
      result[selectedIndex].mappingState = ScrapedKeyState.PARTIALLY_MAPPED;
    } else {
      result[selectedIndex].mappingState = ScrapedKeyState.MAPPED;
    }
  }
  return result;
};

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const getScrapeAndProductModelData = async (
  fileId,
  productModel,
  countryCode,
  getAccessToken,
  accountId
) => {
  const promises = [
    getScrapedData(fileId, getAccessToken),
    getRuleSet(
      productModel,
      undefined,
      true,
      getAccessToken,
      accountId,
      countryCode
    ),
  ];
  return await Promise.all(promises);
};

const getScrapedData = async (id, getAccessToken) => {
  const responseBody = await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/attribute-values/${id}/modelMapping`,
    getAccessToken
  );
  return convertInFormat(responseBody);
};

const convertInFormat = (responseBody) => {
  let counter = 0;
  let scrapedKeyStates = [];
  let translationRules = [];
  responseBody.scrapedData.forEach((element) => {
    const scrapeKey = element.attributeKey.trim();
    let ruleId = "draggable-" + counter;
    scrapedKeyStates.push({
      ruleId: ruleId,
      scrapedKeys: [scrapeKey],
      isActive: true,
      isSelected: counter === 0 ? true : false,
      mappingState: "",
    });
    translationRules.push(
      createTranslationRules(scrapeKey, element.attributeValue, ruleId)
    );
    counter++;
  });

  return {
    scrapedKeyStates: scrapedKeyStates,
    translationRules: translationRules,
  };
};

const createTranslationRules = (attributeKey, values, ruleId) => {
  let tenantAttributes = [];
  let scrapedAttributes = [];
  values.forEach((element, index) => {
    scrapedAttributes.push({
      id: index,
      data: [{ key: attributeKey, value: element.trim() }],
    });
    tenantAttributes.push({
      scrapeId: index,
      data: [],
    });
  });

  return {
    tenantKeys: [""],
    scrapedAttributes: scrapedAttributes,
    tenantAttributes: tenantAttributes,
    ruleId: ruleId,
  };
};

const convertToDefaultTenantData = (defaultValues, productModelData) => {
  let defaultTenantData = [];
  defaultValues.tenantAttributes &&
    defaultValues.tenantAttributes.forEach((tenantAttribute, index) => {
      defaultTenantData.push({
        attributeKey: tenantAttribute.key,
        attributeValues: [],
        defaultValue: tenantAttribute.value,
      });
      getAttributeValues(productModelData, tenantAttribute.key).forEach(
        (item, valueIndex) => {
          defaultTenantData[index].attributeValues.push({
            value: item.value,
            id: valueIndex,
          });
        }
      );
    });
  return defaultTenantData;
};

// Function fetches already mapped data for given model mapping id
export const getNormalizeData = async (modelMappingId, getAccessToken) => {
  return await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalise/${modelMappingId}`,
    getAccessToken
  );
};

// Function fetches already mapped data for given model mapping id and for new file id
const getPreviousNormalizeData = async (
  scrapedFileDetailId,
  modelMappingId,
  getAccessToken
) => {
  return await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalisation/auto-map/${modelMappingId}/${scrapedFileDetailId}`,
    getAccessToken
  );
};

const convertModelMappingReference = (rules, deletedScrapeAttributes) => {
  const scrapedKeyStates = [];
  const translationRules = [];
  const ruleIds = {};
  let count = 0;
  rules.forEach((translationRule, index) => {
    const { scrapedAttributes, tenantAttributes } = translationRule;
    let scrapeKeys = [];
    let key = "";
    scrapedAttributes.forEach((scrapedAttribute) => {
      key = key.concat(scrapedAttribute.key);
      scrapeKeys.push(scrapedAttribute.key);
    });

    let ruleId = ruleIds[key];
    if (!ruleId) {
      let isSelected = count === 0;
      ruleId = `draggable-${count++}`;
      ruleIds[key] = ruleId;
      addScrapedKeyStates(scrapedKeyStates, scrapeKeys, ruleId, isSelected);
      addTranslationRules(
        translationRules,
        scrapedAttributes,
        tenantAttributes,
        ruleId,
        index
      );
    } else {
      const translationRule = findTranslationRule(translationRules, ruleId);
      updateTranslationRule(
        translationRule,
        scrapedAttributes,
        tenantAttributes,
        index
      );
    }
    // create rule for merged key
    if (scrapeKeys.length > 1) {
      for (let counter = 1; counter < scrapeKeys.length; counter++) {
        const key = scrapeKeys[counter];
        let ruleId = ruleIds[key];
        if (!ruleId) {
          ruleId = `draggable-${count++}`;
          ruleIds[key] = ruleId;
          addScrapedKeyStates(scrapedKeyStates, [key], ruleId, false, false);
          addTranslationRulesForMergedKey(
            translationRules,
            scrapedAttributes,
            key,
            ruleId,
            index
          );
        } else {
          const translationRule = findTranslationRule(translationRules, ruleId);
          updateTranslationRuleForMergedKey(
            translationRule,
            scrapedAttributes,
            key,
            index
          );
        }
      }
    }
  });

  convertDeletedScrapeAtt(
    translationRules,
    scrapedKeyStates,
    deletedScrapeAttributes,
    ruleIds,
    count
  );

  let updatedScrapedKeyStates = updateMappingStates(
    translationRules,
    scrapedKeyStates
  );

  return {
    scrapedKeyStates: updatedScrapedKeyStates,
    translationRules: translationRules,
  };
};

const convertDeletedScrapeAtt = (
  translationRules,
  scrapedKeyStates,
  deletedScrapeAttributes,
  ruleIds,
  count
) => {
  let index = translationRules.length;
  deletedScrapeAttributes.forEach((deletedAttribute) => {
    const { key, values } = deletedAttribute;
    let scrapeKeys = [];
    let ruleId = ruleIds[key];
    if (!ruleId) {
      let isSelected = count === 0;
      ruleId = `draggable-${count++}`;
      ruleIds[key] = ruleId;
      scrapeKeys.push(key);
      addScrapedKeyStates(
        scrapedKeyStates,
        scrapeKeys,
        ruleId,
        isSelected,
        true,
        ScrapedKeyState.DELETED
      );
      values.forEach((value, i) => {
        if (i === 0) {
          addTranslationRules(
            translationRules,
            [{ key: key, value: value }],
            [],
            ruleId,
            index
          );
        } else {
          const translationRule = findTranslationRule(translationRules, ruleId);
          updateTranslationRule(
            translationRule,
            [{ key: key, value: value }],
            [],
            index
          );
        }
        index = index + 1;
      });
    }
  });
};

// Add translation rule for the merged keys.
const addTranslationRulesForMergedKey = (
  translationRules,
  scrapedAttributes,
  key,
  ruleId,
  index
) => {
  translationRules.push({
    ruleId: ruleId,
    tenantKeys: [""],
    scrapedAttributes: [
      {
        id: index,
        data: scrapedAttributes.filter((x) => areKeysSame(x.key, key)),
      },
    ],
    tenantAttributes: [{ scrapeId: index, data: [] }],
  });
};

// Update the translation rule for the merged keys
const updateTranslationRuleForMergedKey = (
  translationRule,
  scrapedAttributes,
  key,
  index
) => {
  const scrapedAttribute = scrapedAttributes.find((x) =>
    areKeysSame(x.key, key)
  );
  const isValueAdded = translationRule.scrapedAttributes.some((x) =>
    x.data.some((y) => areKeysSame(y.value, scrapedAttribute.value))
  );
  if (isValueAdded) return;

  translationRule.scrapedAttributes.push({
    id: index,
    data: scrapedAttributes.filter((x) => areKeysSame(x.key, key)),
  });
  translationRule.tenantAttributes.push({
    scrapeId: index,
    data: [],
  });
};

const updateTranslationRule = (
  translationRule,
  scrapedAttributes,
  tenantAttributes,
  index
) => {
  if (tenantAttributes.length) {
    if (JSON.stringify(translationRule.tenantKeys) === JSON.stringify([""])) {
      translationRule.tenantKeys = [];
    }
    tenantAttributes.forEach((eachData) => {
      if (!translationRule.tenantKeys.includes(eachData.key)) {
        translationRule.tenantKeys.push(eachData.key);
      }
    });
  }

  translationRule.scrapedAttributes.push({
    id: index,
    data: scrapedAttributes,
  });
  translationRule.tenantAttributes.push({
    scrapeId: index,
    data: tenantAttributes,
  });
};

const addTranslationRules = (
  translationRules,
  scrapedAttributes,
  tenantAttributes,
  ruleId,
  index
) => {
  translationRules.push({
    ruleId: ruleId,
    tenantKeys: createTenantKeys(tenantAttributes),
    scrapedAttributes: [{ id: index, data: scrapedAttributes }],
    tenantAttributes: [{ scrapeId: index, data: tenantAttributes }],
  });
};

const addScrapedKeyStates = (
  scrapedKeyStates,
  scrapeKeys,
  ruleId,
  isSelected,
  isActive = true,
  mappingState = ""
) => {
  scrapedKeyStates.push({
    ruleId: ruleId,
    scrapedKeys: scrapeKeys,
    isActive: isActive,
    isSelected: isSelected,
    mappingState: mappingState,
  });
};

const createTenantKeys = (tenantAttributes) => {
  let tenantKeys = [];
  tenantAttributes.forEach((tenantAttribute) => {
    tenantKeys.push(tenantAttribute.key);
  });
  if (tenantKeys.length === 0) tenantKeys.push("");
  return tenantKeys;
};

const findTranslationRule = (translationRules, ruleId) => {
  return translationRules.find((x) => x.ruleId === ruleId);
};

export const getAttributeKeys = (productModel) => {
  let mappingList = [];
  productModel.forEach((attribute, index) => {
    mappingList.push({
      id: index,
      value: attribute.attributeKey,
    });
  });

  return mappingList;
};

export const getAttributeValues = (productModel, mappedAttributeKey) => {
  let mappingList = [];
  if (mappedAttributeKey.trim() !== "") {
    for (let count = 0; count < productModel.length; count++) {
      let attribute = productModel[count];
      if (attribute.attributeKey === mappedAttributeKey) {
        if (attribute.attributeValues === undefined) break;
        attribute.attributeValues.forEach((value, index) => {
          mappingList.push({
            id: index,
            value: value.value,
          });
        });
        break;
      }
    }
  }
  return mappingList;
};

export const getAttributeValuesNew = (
  productModel,
  mappedAttributeKey,
  data
) => {
  let mappingList = [];
  if (mappedAttributeKey.trim() !== "") {
    if (
      data.length > 0 &&
      !_.isEmpty(data[0].selectionList) &&
      data[0].selectionList.matchKey === mappedAttributeKey
    ) {
      if (!isEmpty(data[0].selectionList)) {
        data[0].selectionList.matchedValues.forEach((item, i) => {
          mappingList.push({
            id: i,
            value: item.value,
            label: item.value,
            ratio: item.ratio,
          });
        });
      }
      return mappingList;
    }
    for (let count = 0; count < productModel.length; count++) {
      let attribute = productModel[count];
      if (attribute.attributeKey === mappedAttributeKey) {
        if (attribute.attributeValues === undefined) break;
        attribute.attributeValues.forEach((value, index) => {
          mappingList.push({
            id: index,
            value: value.value,
            label: value.value,
          });
        });
        break;
      }
    }
  }
  if (mappingList === undefined) return [];

  return mappingList;
};
export const getCombinationData = async (inputRequest, getAccessToken) => {
  let queryParams = buildQuery("attributeName", inputRequest.attributeName);
  return await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/merge/${inputRequest.fileId}?${queryParams}`,
    getAccessToken
  );
};

export const getDefaultTenantKeys = (translationRules, productModelData) => {
  let unmappedProductModelKeyList = [];

  let allMappedTenantKey = [];

  translationRules.forEach((element) => {
    allMappedTenantKey = allMappedTenantKey.concat(element.tenantKeys);
  });

  productModelData.forEach((eachProductModelData, index) => {
    if (!allMappedTenantKey.includes(eachProductModelData.attributeKey))
      unmappedProductModelKeyList.push({
        id: index,
        value: eachProductModelData.attributeKey,
      });
  });

  return unmappedProductModelKeyList;
};

export const syncData = async (getAccessToken, finalData) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation:syncmodel`;
  const dataPayload = JSON.stringify(finalData);
  return await request(endpoint, getAccessToken, dataPayload, httpMethod.POST);
};

export const tranformModelMappings = async (
  modelMappingId,
  getAccessToken
) => {
  return await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalise/${modelMappingId}:transformRuleToLatestSKUVersion`,
    getAccessToken
  );
};

export const buttonStyle = { minWidth: "130px", marginLeft: 5 };
