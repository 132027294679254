import React from "react";
import { Robot } from "@cimpress/react-components/lib/shapes/index";
import PropTypes from "prop-types";

function ErrorRobot({ title, msg, includeContact }) {
  let titleRender = title;
  let msgRender = msg;

  if (typeof title === "string") {
    titleRender = <h3>{title}</h3>;
  }

  if (typeof msg === "string") {
    msgRender = <h5>{msg}</h5>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {titleRender}
      <Robot bsStyle="danger" />
      {msgRender}
      {includeContact && (
        <i>
          <h5>{`Please refresh the page to retry. If the problem persists, please contact FinancialFlowsSupport@cimpress.com.`}</h5>
        </i>
      )}
    </div>
  );
}
ErrorRobot.defaultProps = {
  includeContact: true,
};

ErrorRobot.propTypes = {
  title: PropTypes.node,
  msg: PropTypes.node,
  includeContact: PropTypes.bool,
};

export default ErrorRobot;
