import React, { Fragment } from "react";
import Autocomplete from "../autocomplete/AutoComplete";
import Creatable from "../autocomplete/Creatable";
import {
  tenantKey,
  tenantValue,
  findValuesOfKey,
} from "../../services/mapAttributeService";
import "./App.css";

export default function SelectedTenantAttributeView(props) {
  const {
    tenantAttributes,
    tenantKeys,
    ruleIndex,
    getTenantKeys,
    getTenantValues,
    scrapedKeysLength,
    onChangeTenantKey,
    onSelectionTenantKey,
    onBlurTenantKey,
    onChangeTenantValue,
    onDeleteTenantKey,
    ruleId,
    disableActions,
  } = props;

  const rowSize = getRowSize();
  const getDeleteButton = (handleOnClick) => {
    return (
      <i
        data-toggle="tooltip"
        title="Delete"
        className="fa fa-trash-o"
        onClick={handleOnClick}
      />
    );
  };

  function getRowSize() {
    switch (scrapedKeysLength) {
      case 1:
        return 4;
      case 2:
        return 6;
      case 3:
      default:
        return 12;
    }
  }

  const HandleDeleteTenantKey = (index) => {
    onDeleteTenantKey(ruleIndex, index);
  };

  function AddAttributeValueControl() {
    return tenantAttributes.map((tenantAttribute, index) => {
      return (
        <div key={ruleId + tenantAttribute.scrapeId} className="row">
          {tenantKeys.map((key, childIndex) => {
            return (
              <div className={`col-md-${rowSize}`} key={childIndex}>
                <Creatable
                  label={tenantValue}
                  value={findValuesOfKey(tenantAttribute.data, key)}
                  items={getTenantValues(key, tenantAttribute.data)}
                  onChange={onChangeTenantValue}
                  ruleIndex={ruleIndex}
                  index={index}
                  tenantKey={key}
                  disableActions={disableActions}
                />
              </div>
            );
          })}
        </div>
      );
    });
  }

  function AddAttributeKeyControls() {
    const items = getTenantKeys();
    return (
      <div className="row">
        {tenantKeys.map((key, index) => {
          return (
            <div className={`col-md-${rowSize}`} key={index}>
              <div className="flex-container">
                <div className="leftdiv">
                  <Autocomplete
                    ruleIndex={ruleIndex}
                    index={index}
                    items={items}
                    onChange={onChangeTenantKey}
                    onSelection={onSelectionTenantKey}
                    onBlur={onBlurTenantKey}
                    value={key}
                    name="required"
                    label={tenantKey}
                    placeholder={tenantKey}
                    required={true}
                    disableActions={disableActions}
                  />
                </div>
                <div className="rightdiv">
                  {index === 0
                    ? null
                    : getDeleteButton(() => {
                        HandleDeleteTenantKey(index);
                      })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <Fragment>
      {AddAttributeKeyControls()} {AddAttributeValueControl()}
    </Fragment>
  );
}
