import React, { useEffect, useState } from "react";
import AutocompleteTextField from "./AutocompleteTextField";
import * as util from "./autoCompleteUtility";
import "./Menu.css";

function AutoComplete(props) {
  const {
    ruleIndex,
    value,
    items,
    name,
    label,
    placeholder,
    required,
    helpText,
    style,
    onBlur,
    index,
    onChange,
    tenantKey,
    onSelection,
    className,
    rightaddon,
    disableActions,
  } = props;
  const inputRef = React.useRef(null);
  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState(undefined);
  const [position, setPosition] = useState({
    menuTop: undefined,
    x: 0,
  });

  useEffect(() => {
    if (className) {
      const modal = document.getElementsByClassName(className);
      if (modal && modal.length > 0) {
        let modalBody = modal[0].getElementsByClassName("modal-body");
        if (modalBody && modalBody.length > 0) {
          setModalBody(modalBody[0]);
        }
      }
    }
  }, [className]);

  useEffect(() => {
    if (open) {
      let scrollTop = 0;
      if (className && modalBody) {
        scrollTop = modalBody.scrollTop;
      } else {
        scrollTop = getScrollOffset();
      }
      let menuTop = inputRef.current.refs.menu.style.top;
      menuTop = menuTop.substring(0, menuTop.length - 2);
      setPosition({
        x: scrollTop,
        menuTop: menuTop,
      });
    }
  }, [open, className, modalBody]);

  useEffect(() => {
    const setMenuPosition = (scrollTop) => {
      const { menuTop, x } = position;
      const menuTopPosition =
        menuTop - scrollTop + x < 0 ? 0 : menuTop - scrollTop + x;
      inputRef.current.refs.menu.style.top = `${menuTopPosition}px`;
    };
    const handleScrollEvent = () => {
      if (open && modalBody) {
        setMenuPosition(modalBody.scrollTop);
      }
    };

    const handleScrollEventListener = () => {
      if (open) {
        let scrollTop = getScrollOffset();
        setMenuPosition(scrollTop);
      }
    };

    if (open) {
      if (modalBody) {
        modalBody.addEventListener("scroll", handleScrollEvent);
      } else {
        window.addEventListener("scroll", handleScrollEventListener);
      }
    }

    return () => {
      if (modalBody) {
        modalBody.removeEventListener("scroll", handleScrollEvent);
      } else {
        window.removeEventListener("scroll", handleScrollEventListener);
      }
    };
  }, [modalBody, open, position]);

  const getScrollOffset = () => {
    return window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
  };

  const onMenuVisibilityChange = (isOpen) => {
    if (open !== isOpen) {
      setOpen(isOpen);
    }
  };

  function onBlurHandler(e) {
    onBlur && onBlur(e, index, ruleIndex);
  }

  function onChangeHandler(e) {
    onChange(e, index, tenantKey, ruleIndex);
  }

  function onSelectionHandler(value, item) {
    onSelection(item, index, tenantKey, ruleIndex);
  }

  function renderMenu(items, value, style) {
    let modalContent = undefined;
    const modal = document.getElementsByClassName(className);
    if (modal && modal.length > 0 && modal[0].hasChildNodes()) {
      modalContent = modal[0].firstChild;
    }
    let position = {};
    if (modalContent) {
      let react = modalContent.getBoundingClientRect();
      const computedStyle = global.window.getComputedStyle(modalContent);
      const marginBottom = parseInt(computedStyle.marginBottom, 10) || 0;
      const marginLeft = parseInt(computedStyle.marginLeft, 10) || 0;
      position = getPosition(
        style,
        react.top + marginBottom,
        react.left + marginLeft
      );
    }
    const styles = {
      ...style,
      ...util.menuStyle,
      ...position,
    };

    if (items.length === 0) {
      return (
        <div style={styles} children={items}>
          No suggestions, you're on your own!
        </div>
      );
    }
    return <div style={styles} children={items} />;
  }

  function getPosition(style, top, left) {
    const position = { left: undefined, top: undefined };
    if (
      style.left === undefined ||
      style.top === undefined ||
      style.minWidth === undefined
    ) {
      return position;
    }
    position.left = style.left - left;
    position.top = style.top - top;
    return position;
  }

  function renderItem(item, isHighlighted) {
    let txt = item.ratio === undefined ? "" : `( ${item.ratio}% match)`;
    return (
      <div
        style={util.menuWrapper}
        className={`item ${isHighlighted ? "item-highlighted" : ""}`}
        key={item.id}
      >
        {item.ratio === undefined ? item.value : item.value + " "}
        <span style={{ fontSize: "10px", color: "#585858" }}>{txt}</span>
      </div>
    );
  }

  return (
    <AutocompleteTextField
      ref={inputRef}
      value={value}
      items={items}
      getItemValue={(item) => item.value}
      onChange={onChangeHandler}
      onSelect={onSelectionHandler}
      inputProps={{
        name,
        label,
        placeholder,
        required,
        style,
        helpText,
        rightaddon,
        className,
        onBlur: onBlurHandler,
        disabled: disableActions
      }}
      shouldItemRender={util.shouldItemRender}
      sortItems={util.sortStates}
      renderMenu={renderMenu}
      renderItem={renderItem}
      selectOnBlur={false}
      wrapperStyle={util.wrapperStyle}
      onMenuVisibilityChange={onMenuVisibilityChange}
      open={open}
    />
  );
}

export default AutoComplete;
