import {
  Button,
  Card,
  FlexBox,
  Label,
  Modal,
  NavTab,
  NavTabItem,
} from "@cimpress/react-components";
import React, { useState, useEffect, Fragment } from "react";
import {
  persimmon,
  success,
  warning,
} from "@cimpress/react-components/lib/colors";
import {
  IconAddCircle,
  IconPencilCircle,
  IconRemoveCircle,
} from "@cimpress-technology/react-streamline-icons/lib";
import { AttributesTransformedStatus } from "../../utils/constants";

const ProductChangesModal = ({
  openSkuChangesModal,
  sku,
  attributesTransformed,
  toggleSkuChangesModal,
}) => {
  const [selectedTab, setSelectedTab] = useState();

  useEffect(() => {
    if (attributesTransformed && attributesTransformed.length > 0) {
      setSelectedTab(attributesTransformed[0]);
    }
  }, [attributesTransformed]);

  const IconNavTabItem = ({ status }) => {
    switch (status) {
      case AttributesTransformedStatus.ADDED:
        return <IconAddCircle color={success.base} size="lg" weight="light" />;
      case AttributesTransformedStatus.MODIFIED:
        return (
          <IconPencilCircle color={warning.base} size="lg" weight="light" />
        );
      case AttributesTransformedStatus.REMOVED:
        return (
          <IconRemoveCircle color={persimmon.base} size="lg" weight="light" />
        );
      default:
        return <></>;
    }
  };

  const AttributeCardTitle = () => {
    switch (selectedTab.mappingStatus) {
      case AttributesTransformedStatus.ADDED:
        return <Label text={selectedTab.targetAttribute} type="success" />;
      case AttributesTransformedStatus.MODIFIED:
        return (
          <>
            <Label text={selectedTab.sourceAttribute} type="danger" /> &rarr;{" "}
            <Label text={selectedTab.targetAttribute} type="success" />
          </>
        );
      case AttributesTransformedStatus.REMOVED:
        return <Label text={selectedTab.sourceAttribute} type="danger" />;
      default:
        return selectedTab.targetAttribute;
    }
  };

  const AttributeValuesCardContent = () => {
    const addedValues = [];
    const modifiedValues = [];
    const removedValues = [];

    selectedTab &&
      selectedTab.attributeValueMappings.forEach((eachAttributeValue) => {
        if (eachAttributeValue.mappingStatus === AttributesTransformedStatus.ADDED) {
          addedValues.push(eachAttributeValue);
        } else if (eachAttributeValue.mappingStatus === AttributesTransformedStatus.MODIFIED) {
          modifiedValues.push(eachAttributeValue);
        } else if (eachAttributeValue.mappingStatus === AttributesTransformedStatus.REMOVED) {
          removedValues.push(eachAttributeValue);
        }
      });

    const content = [];

    if (addedValues.length > 0) {
      content.push(
        <div key={0} style={{ marginBottom: "10px" }}>
          <span>Added Values</span>
          <div>
            {addedValues.map((eachValue, index) => (
              <Label
                key={index}
                text={eachValue.targetAttributeValue}
                type="success"
              />
            ))}
          </div>
        </div>
      );
    }
    if (modifiedValues.length > 0) {
      content.push(
        <div key={1} style={{ marginBottom: "10px" }}>
          <span>Modified Values</span>
          <div>
            {modifiedValues.map((eachValue, index) => (
              <Fragment key={index}>
                <Label text={eachValue.sourceAttributeValue} type="danger" />{" "}
                &rarr;{" "}
                <Label text={eachValue.targetAttributeValue} type="success" />
              </Fragment>
            ))}
          </div>
        </div>
      );
    }
    if (removedValues.length > 0) {
      content.push(
        <div key={2} style={{ marginBottom: "10px" }}>
          <span>Removed Values</span>
          <div>
            {removedValues.map((eachValue, index) => (
              <Label
                key={index}
                text={eachValue.sourceAttributeValue}
                type="danger"
              />
            ))}
          </div>
        </div>
      );
    }

    return <>{content}</>;
  };

  return (
    <Modal
      closeButton
      show={openSkuChangesModal}
      title={`Changes in attributes and/or attributes values of ${sku}`}
      onRequestHide={() => toggleSkuChangesModal()}
      footer={
        <Button onClick={toggleSkuChangesModal} variant="default">
          Close
        </Button>
      }
      style={{ width: "70%" }}
    >
      {selectedTab && (
        <FlexBox spaceBetween>
          <NavTab vertical={true}>
            {attributesTransformed.map((eachAttribute, index) => (
              <NavTabItem
                key={index}
                active={
                  selectedTab.targetAttribute === eachAttribute.targetAttribute
                }
              >
                <button onClick={() => setSelectedTab(eachAttribute)}>
                  {eachAttribute.targetAttribute}{" "}
                  <IconNavTabItem status={eachAttribute.mappingStatus} />
                </button>
              </NavTabItem>
            ))}
          </NavTab>
          {selectedTab.attributeValueMappings?.length > 0 && (
            <Card header={<AttributeCardTitle />} style={{ width: "80%" }}>
              <AttributeValuesCardContent />
            </Card>
          )}
        </FlexBox>
      )}
    </Modal>
  );
};

export default ProductChangesModal;
