export const transformMultiplePricePointResponseToNormalizeDataFormat = (
  response
) => {
  const headers = [];
  const headerIndexMap = {};
  response[0].attributes.forEach((eachAttribute) => {
    headers.push(eachAttribute.key);
  });
  headers.sort();
  headers.forEach((eachHeader, index) => {
    headerIndexMap[eachHeader] = index;
  });

  const data = [];
  response.forEach((eachAttributeCombination) => {
    const eachRow = Array(headers.length).fill(null);

    eachAttributeCombination.attributes.forEach((eachAttribute) => {
      eachRow[headerIndexMap[eachAttribute.key]] = eachAttribute.value;
    });

    eachAttributeCombination.prices.forEach((eachPrice) => {
      if (!headerIndexMap[eachPrice.key]) {
        headers.push(eachPrice.key);
        headerIndexMap[eachPrice.key] = headers.length - 1;

        eachRow.push(eachPrice.value.join(" | "));
      } else {
        eachRow[headerIndexMap[eachPrice.key]] = eachPrice.value.join(" | ");
      }
    });

    data.push(eachRow);
  });

  for (let row = 0; row < data.length; row++) {
    if (data[row].length !== headers.length) {
      data[row] = data[row].concat(
        Array(headers.length - data[row].length).fill(null)
      );
    }
  }

  data.splice(0, 0, headers);

  return data;
};

export const transformPriceChangeResponseToNormalizeDataFormat = (response) => {
  const headers = [];
  const headerIndexMap = {};
  response[0].row.forEach((eachAttribute) => {
    headers.push(eachAttribute.key);
  });
  headers.sort();
  headers.forEach((eachHeader, index) => {
    headerIndexMap[eachHeader] = index;
  });

  const data = [];
  response.forEach((eachAttributeCombination) => {
    const eachRow = Array(headers.length).fill(null);

    eachAttributeCombination.row.forEach((eachAttribute) => {
      eachRow[headerIndexMap[eachAttribute.key]] = eachAttribute.value;
    });

    eachAttributeCombination.changes.forEach((eachPrice) => {
      if (
        !headerIndexMap[`Previous ${eachPrice.key}`] &&
        !headerIndexMap[`Current ${eachPrice.key}`]
      ) {
        headers.push(`Previous ${eachPrice.key}`);
        headerIndexMap[`Previous ${eachPrice.key}`] = headers.length - 1;
        eachRow.push(eachPrice.previousValue);

        headers.push(`Current ${eachPrice.key}`);
        headerIndexMap[`Current ${eachPrice.key}`] = headers.length - 1;
        eachRow.push(eachPrice.newValue);
      } else {
        eachRow[headerIndexMap[`Previous ${eachPrice.key}`]] =
          eachPrice.previousValue;
        eachRow[headerIndexMap[`Current ${eachPrice.key}`]] =
          eachPrice.newValue;
      }
    });

    data.push(eachRow);
  });

  for (let row = 0; row < data.length; row++) {
    if (data[row].length !== headers.length) {
      data[row] = data[row].concat(
        Array(headers.length - data[row].length).fill(null)
      );
    }
  }

  data.splice(0, 0, headers);

  return data;
};
