import { Dropdown, Tooltip } from "@cimpress/react-components";
import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  snackBarStatus,
  routeNames,
  MISSINGMANDATORYFIELDS,
} from "../../constants/general";
import { reUploadFile } from "../../services/scrapingFileService";
import { toMonthDayYearDisplay } from "../../utils/dateFormat";
import mappingManagementStyles from "./MappingManagement.module.css";
import IconNavigationMenuVertical from "@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical";
import IconAlertTriangle from "@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle";
import NewProductVersionConfirmationModal from "./NewProductVersionConfirmationModal";

const ScrapedFileActions = ({
  currScrapedFile,
  toggleAddSkuModal,
  showConfirmationModalForDeleteFileData,
  setShowSpinner,
  setRefreshScrapedFiles,
  showAlert,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const {
    id: fileId,
    fileName,
    validationStatus,
    scrapedDate,
    category,
    competitor,
    countryCode,
    scrapingJobId,
    mappingFailedReason,
  } = currScrapedFile;
  const [
    newProductVersionConfirmationModalState,
    setNewProductVersionConfirmationModalState,
  ] = useState(undefined);
  const editFileMappingHandler = () => {
    history.push(
      `/${routeNames.MAPPING_MANAGEMENT}/${id}/${routeNames.QUESTIONNAIRE}/${fileId}`
    );
  };
  const fileRef = useRef(null);

  const fileHasErrors = mappingFailedReason && mappingFailedReason.hasError;
  const closeNewProductVersionConfirmationModal = () => {
    setNewProductVersionConfirmationModalState(undefined);
  };
  const onCancelNewProductVersionConfirmationModal = () => {
    closeNewProductVersionConfirmationModal();
    fileRef.current.value = null;
  };
  const onContinueNewProductVersionConfirmationModal = (file) => {
    closeNewProductVersionConfirmationModal();
    onFileUpload(file, true);
  };
  const onFileInputChange = (event) => {
    if (event && event.target && event.target.files.length > 0) {
      onFileUpload(event.target.files[0], false);
    }
  };

  const onFileUpload = async (file, ignoreMappingValidation) => {
    setShowSpinner(true);
    let alertDetails;
    try {
      let formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      await reUploadFile(formData, currScrapedFile.id, ignoreMappingValidation);
      setRefreshScrapedFiles(true);
      fileRef.current.value = null;
      alertDetails = {
        message: `New File Successfully Reuploaded.`,
        status: snackBarStatus.success,
        delay: 2000,
      };
    } catch (error) {
      if (error.status === 422) {
        const errorData = await error.json();
        if (
          !ignoreMappingValidation &&
          errorData.title.includes("New version available")
        ) {
          setNewProductVersionConfirmationModalState({
            show: true,
            status: "warning",
            title: errorData.title,
            file: file,
            errorMessage: errorData.detail,
          });
        } else {
          alertDetails = {
            message: "Error while uploading file. ",
            status: snackBarStatus.danger,
            error: error,
          };
        }
      } else {
        alertDetails = {
          message: "Error while uploading file. ",
          status: snackBarStatus.danger,
          error: error,
        };
      }
    } finally {
      alertDetails && showAlert(alertDetails);
      setShowSpinner(false);
    }
  };

  const clickFileInput = () => {
    fileRef && fileRef.current.click();
  };

  const deleteScrapingJob = () => {
    showConfirmationModalForDeleteFileData(currScrapedFile, scrapingJobId);
  };

  const getFileActions = () => {
    const editFileMapping = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a key="editFileMapping" onClick={editFileMappingHandler}>
        Edit File Mapping
      </a>
    );

    const url = `${
      process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI
    }/v1/scrapingjobs/scrapedfile:download?competitor=${competitor}&category=${encodeURIComponent(
      category
    )}&country=${countryCode}&fileName=${encodeURIComponent(fileName)}`;

    const uriWrapUrl = `https://uiwrap.trdlnk.cimpress.io/?url=${encodeURIComponent(
      url
    )}&filename=${encodeURIComponent(fileName)}&method=GET`;

    const downloadFile = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        key="downloadFile"
        href={uriWrapUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download File
      </a>
    );

    const reuploadFile = (
      <React.Fragment key="reuploadFile">
        <input
          // eslint-disable-next-line react/no-string-refs
          ref={fileRef}
          onChange={onFileInputChange}
          type="file"
          style={{ display: "none" }}
        />

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={clickFileInput}>Reupload File</a>
      </React.Fragment>
    );

    const addSku = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a key="addSku" onClick={() => toggleAddSkuModal()}>
        Add SKU's
      </a>
    );

    const deleteFileData = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a key="deleteFileData" onClick={deleteScrapingJob}>
        Delete File Data
      </a>
    );
    let fileActions = [downloadFile];

    if (currScrapedFile.accountId === currScrapedFile.fileOwnerAccountId) {
      fileActions.push(editFileMapping);
      fileActions.push(reuploadFile);
      fileActions.push(deleteFileData);
    }
    if (validationStatus !== MISSINGMANDATORYFIELDS && !fileHasErrors) {
      fileActions.push(addSku);
    }

    return fileActions;
  };

  return (
    <div
      className={`${mappingManagementStyles.mapping_management_accordion_actions}`}
    >
      <div>
        {fileHasErrors ? (
          <Tooltip direction="top" contents={mappingFailedReason.message}>
            <IconAlertTriangle size="lg" color="red" weight="fill" />
          </Tooltip>
        ) : null}
        <strong style={{ marginLeft: "15px" }}>{`${fileName}`}</strong>
      </div>

      <strong
        className={`${mappingManagementStyles.mapping_management_title_display}`}
      >
        <strong>{`Scraped on ${toMonthDayYearDisplay(scrapedDate)}`}</strong>
        {
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              className={
                mappingManagementStyles.scrapedFileActionsNavigationIcon
              }
              style={{ marginLeft: "30px", marginRight: "15px" }}
              title={<IconNavigationMenuVertical weight="fill" />}
              type="simple"
            >
              {getFileActions()}
            </Dropdown>
          </div>
        }
      </strong>
      {newProductVersionConfirmationModalState && (
        <NewProductVersionConfirmationModal
          status={newProductVersionConfirmationModalState.status}
          show={newProductVersionConfirmationModalState.show}
          title={newProductVersionConfirmationModalState.title}
          onCancelNewProductVersionConfirmationModal={
            onCancelNewProductVersionConfirmationModal
          }
          onContinueNewProductVersionConfirmationModal={
            onContinueNewProductVersionConfirmationModal
          }
          file={newProductVersionConfirmationModalState.file}
          errorMessage={newProductVersionConfirmationModalState.errorMessage}
        />
      )}
    </div>
  );
};

export default ScrapedFileActions;
