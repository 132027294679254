import React from "react";
import { Accordion } from "@cimpress/react-components";
import MappingTable from "./MappingTable";
import ScrapedFileActions from "./ScrapedFileActions";
import AddSkuModal from "./AddSkuModal";
import { useState } from "react";
import { getAccessToken } from "../../utils/accessToken";
import { saveFinalSubmitData } from "../../services/submit";
import { createSubmitData } from "./util";
import mappingManagementStyles from "./MappingManagement.module.css";
import SyncModal from "../mappingManagement/SyncModal";
import { syncData } from "../../services/productAttributeMapping";
import { snackBarStatus } from "../../constants/general";
import { getDisplayMessage } from "../../services/errorMessages";

const ScrapedFileAccordion = ({
  accessToken,
  currScrapedFile,
  setRefreshScrapedFiles,
  accountId,
  showSpinner,
  setShowSpinner,
  showAlert,
  showConfirmationModalForDeleteFileData,
  showConfirmationModalForDisableSku,
}) => {
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [selectedReferenceId, setSelectedReferenceId] = useState(undefined);
  const [showAddSkuModal, setShowAddSkuModal] = useState(false);

  const toggleSyncModal = () => {
    setShowSyncModal((prevState) => !prevState);
  };

  const onClickSyncModal = (referenceId) => {
    setSelectedReferenceId(referenceId);
    toggleSyncModal();
  };

  const onSync = async (sinceDate, syncExistingMappings) => {
    setShowSpinner(true);
    let alertDetails;
    try {
      let syncRequest = {
        modelMappingId: selectedReferenceId,
        startDate: sinceDate,
        endDate: new Date(),
        syncExistingMappings: syncExistingMappings,
      };
      await syncData(getAccessToken, syncRequest);
      alertDetails = {
        message: `Succesfully Triggered Synced Data. You will be notified via email once done.`,
        status: snackBarStatus.success,
        delay: 2000,
      };
    } catch (error) {
      alertDetails = {
        message: "Error Syncing Data. ",
        status: snackBarStatus.danger,
        error: error,
      };
    } finally {
      showAlert(alertDetails);
      setShowSpinner(false);
      toggleSyncModal();
    }
  };

  const toggleAddSkuModal = () => {
    setShowAddSkuModal((prevState) => !prevState);
  };

  const onSaveSkus = async (newSKUs) => {
    setShowSpinner(true);
    let errorOnSave = [];
    let alertDetails;
    for (let skuIndex = 0; skuIndex < newSKUs.length; skuIndex++) {
      try {
        const finalSubmitData = await createSubmitData(
          currScrapedFile,
          newSKUs[skuIndex],
          getAccessToken,
          accountId
        );
        await saveFinalSubmitData(finalSubmitData, getAccessToken);
      } catch (error) {
        errorOnSave = [
          ...errorOnSave,
          `${newSKUs[skuIndex].productId} -> ${getDisplayMessage({
            error: error,
          })}`,
        ];
      }
    }
    if (errorOnSave.length === 0) {
      alertDetails = {
        status: snackBarStatus.success,
        message: `Succesfully added SKUs.`,
        delay: 2000,
      };
      toggleAddSkuModal();
      setRefreshScrapedFiles(true);
    } else {
      alertDetails = {
        status: snackBarStatus.danger,
        message: `Error while adding the following SKUs : ${errorOnSave}.`,
      };
      setRefreshScrapedFiles(true);
    }
    showAlert(alertDetails);
    setShowSpinner(false);
  };

  return (
    <div className={`${mappingManagementStyles.mapping_management_accordion}`}>
      <Accordion
        title={
          <ScrapedFileActions
            currScrapedFile={{ ...currScrapedFile, accountId }}
            toggleAddSkuModal={toggleAddSkuModal}
            setRefreshScrapedFiles={setRefreshScrapedFiles}
            setShowSpinner={setShowSpinner}
            showAlert={showAlert}
            showConfirmationModalForDeleteFileData={
              showConfirmationModalForDeleteFileData
            }
          />
        }
        className="overflow-visible"
      >
        <MappingTable
          accessToken={accessToken}
          currScrapedFile={currScrapedFile}
          onClickSyncModal={onClickSyncModal}
          accountId={accountId}
          showConfirmationModalForDisableSku={
            showConfirmationModalForDisableSku
          }
        />
      </Accordion>
      {showSyncModal && (
        <SyncModal
          sku={
            currScrapedFile.references.find(
              (eachReference) =>
                eachReference.referenceId === selectedReferenceId
            )?.productModelId
          }
          showSyncModal={showSyncModal}
          toggleSyncModal={toggleSyncModal}
          onSync={onSync}
          showSpinner={showSpinner}
        />
      )}
      {showAddSkuModal && (
        <AddSkuModal
          showAddSkuModal={showAddSkuModal}
          toggleAddSkuModal={toggleAddSkuModal}
          currScrapedFile={currScrapedFile}
          onSaveSkus={onSaveSkus}
          setShowSpinner={setShowSpinner}
          isLoading={showSpinner}
          maxSkus={5}
        />
      )}
    </div>
  );
};

export default ScrapedFileAccordion;
