import React from 'react';
import { Link } from 'react-router-dom';
import { shapes } from '@cimpress/react-components';

import Breadcrumb from "./breadcrumb/Breadcrumb";
import { notFound } from "./breadcrumb/breadcrumbElements";

const { Robot } = shapes;

const NotFound = () => (
  <div className="container-fluid">
    <div style={{ paddingLeft: '15px' }}>
      <Breadcrumb separator="/">
        {notFound.map(({ to, label }) => (
          <Link key={to} to={to}>
            {label}
          </Link>
        ))}
      </Breadcrumb>
    </div>
    <div className="container-fluid">
      <div className="main__content">
        <div className="container">
          <div className="css-1h9865k">
            <h1>404 - Not Found</h1>
            <h2>
              <div className="row">
                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
                  <Robot status="danger" />
                </div>
              </div>
              You don't have access to the account(s)
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
