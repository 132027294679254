import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FlexBox,
  Radio,
  RadioGroup,
  Accordion,
  Select,
  Tooltip,
} from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import { Messages, SubscriptionOption } from "../utils/constants";
import ReactCountryFlag from "react-country-flag";
import _ from "lodash";
import {
  postSubscriptionConfiguration,
  createPostSubscribePayload,
  deleteSubscriptionConfiguration,
} from "../services/subscriptions";

const buttonStyle = {
  marginLeft: "5px",
  textAlign: "right",
};

function SubscriptionAccordion({
  countryCode,
  title,
  subscriptionData,
  options,
  getAccessToken,
  updateSnackbar,
  account,
  updateSubscribedData,
}) {
  const [categories, setCategories] = useState([]);
  const [customOpen, setCustomOpen] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [existingSubscription, setExistingSubscription] =
    useState(subscriptionData);
  const [currentState, setCurrentState] = useState({});
  const [uniqueKey, setUniqueKey] = useState(null);
  const [isToggleChecked, setToggleChecked] = useState(false);
  const [optionsToDisplay, setOptionsToDisplay] = useState(() => {
    const optionsForCountry = options.find(
      (item) => item.country === countryCode
    );
    return optionsForCountry ? optionsForCountry.options : [];
  });

  useEffect(() => {
    setExistingSubscription(subscriptionData);
  }, [subscriptionData]);

  useEffect(() => {
    if (!_.isEmpty(existingSubscription)) {
      setCurrentState({
        categories: existingSubscription.categories,
        notification: existingSubscription.notification,
        isMultiSelectDisabled: existingSubscription.isMultiSelectDisabled,
      });
      setCategories(existingSubscription.stringCategories);
    } else {
      setCurrentState({
        categories: [],
        notification: SubscriptionOption.UNSUBSCRIBE_ALL,
        isMultiSelectDisabled: true,
      });
      setCategories([]);
    }
    setUniqueKey(Math.random());
  }, [existingSubscription]);

  function onHeaderClick() {
    setCustomOpen(!customOpen);
  }

  function onChangeNotification(e, value) {
    const noSubscriptionNotAll =
      _.isEmpty(existingSubscription) &&
      value !== SubscriptionOption.SUBSCRIBE_ALL;
    const noChangeFromExisting =
      !_.isEmpty(existingSubscription) &&
      value === existingSubscription.notification &&
      _.isEqual(
        _.sortBy(categories),
        _.sortBy(existingSubscription.stringCategories)
      );
    const individualButNoSelection =
      !_.isEmpty(existingSubscription) &&
      value === SubscriptionOption.SUBSCRIBE_INDIVIDUAL &&
      _.isEmpty(currentState.categories);
    if (
      noSubscriptionNotAll ||
      noChangeFromExisting ||
      individualButNoSelection
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
    if (value === SubscriptionOption.SUBSCRIBE_INDIVIDUAL) {
      setCurrentState({
        categories: _.isEmpty(existingSubscription)
          ? []
          : existingSubscription.categories,
        isMultiSelectDisabled: false,
        notification: SubscriptionOption.SUBSCRIBE_INDIVIDUAL,
      });
    } else {
      setCurrentState({
        categories: [],
        isMultiSelectDisabled: true,
        notification: value,
      });
      setCategories([]);
    }
  }

  function onCancel() {
    setCustomOpen(false);
    if (!_.isEmpty(existingSubscription)) {
      setCurrentState({
        categories: existingSubscription.categories,
        notification: existingSubscription.notification,
        isMultiSelectDisabled: existingSubscription.isMultiSelectDisabled,
      });
      setCategories(existingSubscription.stringCategories);
    } else {
      setCurrentState({
        categories: [],
        notification: SubscriptionOption.UNSUBSCRIBE_ALL,
        isMultiSelectDisabled: true,
      });
      setCategories([]);
    }
    setUniqueKey(Math.random());
    setDisableSave(true);
  }

  function onChangeOfMultiSelectCategories(selectedValues) {
    let values = _.isEmpty(selectedValues)
      ? []
      : selectedValues.map((eachValue) => eachValue.value);
    setCurrentState({ ...currentState, categories: selectedValues });
    setCategories(values);
    if (!_.isEmpty(existingSubscription)) {
      !_.isEqual(
        _.sortBy(values),
        _.sortBy(existingSubscription.stringCategories)
      )
        ? setDisableSave(false)
        : setDisableSave(true);
      if (_.isEmpty(selectedValues)) setDisableSave(true);
    } else {
      _.isEmpty(selectedValues) ? setDisableSave(true) : setDisableSave(false);
    }
  }

  function onSubscribeClick() {
    if (!_.isEmpty(existingSubscription)) {
      deleteSubscriptionConfiguration(
        getAccessToken,
        existingSubscription.subscriptionId
      )
        .then((response) => {
          createSubscription();
        })
        .catch((error) => {
          updateSnackbar(true, "danger", 3000, Messages.UNSUCCESSFUL_SUBSCRIBE);
        });
    } else {
      createSubscription();
    }
  }

  function createSubscription() {
    let payload = createPostSubscribePayload(categories, countryCode, account);
    postSubscriptionConfiguration(getAccessToken, payload)
      .then((response) => {
        setExistingSubscription({
          ...currentState,
          subscriptionId: response.subscriptionId,
        });
        updateSubscribedData(countryCode, {
          ...currentState,
          subscriptionId: response.subscriptionId,
        });
        updateSnackbar(true, "success", 3000, Messages.SUCCESSFUL_SUBSCRIBE);
      })
      .catch((error) => {
        updateSnackbar(true, "danger", 3000, Messages.UNSUCCESSFUL_SUBSCRIBE);
      });
  }

  function onUnsubscribeClick() {
    deleteSubscriptionConfiguration(
      getAccessToken,
      existingSubscription.subscriptionId
    )
      .then((response) => {
        setExistingSubscription(undefined);
        updateSnackbar(true, "success", 3000, Messages.SUCCESSFUL_UNSUBSCRIBE);
      })
      .catch((error) => {
        updateSnackbar(true, "danger", 3000, Messages.UNSUCCESSFUL_UNSUBSCRIBE);
      });
  }

  function onSave() {
    if (
      _.isEmpty(existingSubscription) ||
      currentState.notification !== existingSubscription.notification ||
      !_.isEqual(
        _.sortBy(categories),
        _.sortBy(existingSubscription.stringCategories)
      )
    ) {
      if (currentState.notification === SubscriptionOption.UNSUBSCRIBE_ALL) {
        _.isEmpty(existingSubscription)
          ? updateSnackbar(true, "danger", 3000, Messages.SUBSCRIPTION_NOTFOUND)
          : onUnsubscribeClick();
      } else {
        onSubscribeClick();
      }
    } else {
      updateSnackbar(true, "danger", 3000, Messages.EXISTING_SUSCRIPTION);
    }
    setDisableSave(true);
  }

  function onToggle(flag) {
    setToggleChecked(flag);

    if (flag) {
      const optionsSet = new Set();
      const uniqueOptionsArray = [];

      options.forEach((item) => {
        item.options.forEach((option) => {
          if (!optionsSet.has(option.value)) {
            optionsSet.add(option.value);
            uniqueOptionsArray.push(option);
          }
        });
      });

      const uniqueOptions = uniqueOptionsArray.sort((a, b) =>
        a.value.localeCompare(b.value)
      );
      setOptionsToDisplay(uniqueOptions.length > 0 ? uniqueOptions : []);
    } else {
      const optionForCountry = options.find(
        (item) => item.country === countryCode
      );
      setOptionsToDisplay(optionForCountry ? optionForCountry.options : []);
    }
  }

  let disableSaveTooltipMsg = "";
  if (
    (!_.isEmpty(existingSubscription) &&
      _.isEqual(
        _.sortBy(categories),
        _.sortBy(existingSubscription.stringCategories)
      )) ||
    (_.isEmpty(existingSubscription) &&
      currentState.notification === SubscriptionOption.UNSUBSCRIBE_ALL)
  ) {
    disableSaveTooltipMsg = Messages.NO_MODIFICATION;
  }
  if (
    currentState.notification === SubscriptionOption.SUBSCRIBE_INDIVIDUAL &&
    _.isEmpty(currentState.categories)
  ) {
    disableSaveTooltipMsg = Messages.EMPTY_SELECTION;
  }
  return (
    <div>
      <Accordion
        title={[
          <span style={{ marginRight: "5px" }} key={countryCode}>
            <ReactCountryFlag
              className="emojiFlag"
              countryCode={countryCode}
              style={{
                fontSize: "1.5em",
              }}
              svg
            />
          </span>,
          title,
        ]}
        customOpen={customOpen}
        onHeaderClick={onHeaderClick}>
        <div style={{ overflowY: "auto" }}>
          <RadioGroup
            name="notificationSelection"
            onChange={onChangeNotification}
            valueSelected={currentState.notification}
            key={uniqueKey}>
            <Radio
              label="Subscribe to all categories"
              value={SubscriptionOption.SUBSCRIBE_ALL}
            />
            <Radio
              label="Unsubscribe from all categories"
              value={SubscriptionOption.UNSUBSCRIBE_ALL}
            />
            <Radio
              label="Subscribe to individual categories"
              value={SubscriptionOption.SUBSCRIBE_INDIVIDUAL}
            />
          </RadioGroup>{" "}
          <br />
          <div>
            <FlexBox middle>
              <span>Show all scraped categories &nbsp;</span>
              <span style={{ marginBottom: "-10px" }}>
                <Checkbox
                  checked={isToggleChecked}
                  onChange={() => onToggle(!isToggleChecked)}
                  disabled={currentState.isMultiSelectDisabled}
                />
              </span>
            </FlexBox>
            <Select
              isClearable
              placeholder="Subscribe to individual categories"
              value={currentState.categories}
              options={optionsToDisplay}
              onChange={onChangeOfMultiSelectCategories}
              isMulti
              isDisabled={currentState.isMultiSelectDisabled}
            />
          </div>{" "}
          <br />
          <div className="css-12aszlq" align="right" style={buttonStyle}>
            {disableSave ? (
              <Tooltip contents={disableSaveTooltipMsg}>
                <Button
                  variant="primary"
                  type="primary"
                  onClick={onSave}
                  disabled={disableSave}>
                  Save
                </Button>
              </Tooltip>
            ) : (
              <Button
                variant="primary"
                type="primary"
                onClick={onSave}
                disabled={disableSave}>
                Save
              </Button>
            )}{" "}
            &nbsp;&nbsp;&nbsp;
            <Button variant="default" type="default" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Accordion>
    </div>
  );
}

export default SubscriptionAccordion;
