import { request, httpMethod, contentTypes } from "./request";
import download from "downloadjs";

export const downloadFile = async (getAccessToken, finalSubmitData) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalisation/preview/download:file`;
  const dataPayload = JSON.stringify(finalSubmitData);
  let responseBlob = await request(
    endpoint,
    getAccessToken,
    dataPayload,
    httpMethod.POST,
    contentTypes.application_json,
    true
  );
  download(responseBlob, `Mapped_Data.csv`, "text/csv");
};
