import React, { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Table,
  NavTab,
  NavTabItem,
  Button,
} from "@cimpress/react-components";
import {
  MULTIPLE_PRICE_POINT,
  THRESHOLD_PRICE_CHANGES,
} from "../../utils/constants";
import { getInitialTableData } from "../../utils/common";

function Warnings({ toggleWarningsScreen, showWarnings, data, onClickSubmit }) {
  const [selectedTab, setSelectedTab] = useState(THRESHOLD_PRICE_CHANGES);
  const [columnHeaders, setColumnHeaders] = useState(
    getInitialTableData().columnHeaders
  );
  const [rowsData, setRowsData] = useState(getInitialTableData().rows);
  const [pageNumber, setPageNumber] = useState(0);

  const { ThresholdPriceChanges, MultiplePricePoints } = data;
  useEffect(() => {
    setColumnHeaders(
      ThresholdPriceChanges
        ? ThresholdPriceChanges.columnHeaders
        : MultiplePricePoints.columnHeaders
    );
    setRowsData(
      ThresholdPriceChanges
        ? ThresholdPriceChanges.rowsData
        : MultiplePricePoints.rowsData
    );
  }, [ThresholdPriceChanges, MultiplePricePoints]);

  const ChangeTab = (selectedTab) => {
    switch (selectedTab) {
      case THRESHOLD_PRICE_CHANGES:
        setColumnHeaders(ThresholdPriceChanges.columnHeaders);
        setRowsData(ThresholdPriceChanges.rowsData);
        break;
      case MULTIPLE_PRICE_POINT:
        setColumnHeaders(MultiplePricePoints.columnHeaders);
        setRowsData(MultiplePricePoints.rowsData);
        break;
      default:
        break;
    }
    setPageNumber(0);
    setSelectedTab(selectedTab);
  };

  const footer = (
    <>
      <Button onClick={() => toggleWarningsScreen()} type="default">
        Cancel
      </Button>
      <Button
        type="primary"
        onClick={() => {
          toggleWarningsScreen();
          onClickSubmit();
        }}
      >
        Ok
      </Button>
    </>
  );

  return (
    <div>
      <Modal
        style={{ width: "90%" }}
        bsStyle="warning"
        className="foobar"
        show={showWarnings}
        onRequestHide={() => toggleWarningsScreen()}
        title="Warnings"
        closeButton={true}
        footer={footer}
      >
        <NavTab>
          {ThresholdPriceChanges && (
            <NavTabItem active={selectedTab === THRESHOLD_PRICE_CHANGES}>
              <button
                style={{ fontSize: "15px", fontWeight: "600" }}
                onClick={() => ChangeTab(THRESHOLD_PRICE_CHANGES)}
              >
                {THRESHOLD_PRICE_CHANGES}
              </button>
            </NavTabItem>
          )}
          {MultiplePricePoints && (
            <NavTabItem active={selectedTab === MULTIPLE_PRICE_POINT}>
              <button
                style={{ fontSize: "15px", fontWeight: "600" }}
                onClick={() => ChangeTab(MULTIPLE_PRICE_POINT)}
              >
                {MULTIPLE_PRICE_POINT}
              </button>
            </NavTabItem>
          )}
        </NavTab>
        <Fragment>
          <Table
            columns={columnHeaders}
            data={rowsData}
            defaultPageSize={10}
            noDataText={"No Data Found"}
            page={pageNumber}
            onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          />
        </Fragment>
      </Modal>
    </div>
  );
}

export default Warnings;
