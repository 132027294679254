import { getAccessToken } from "../utils/accessToken";
import { MappingStatus } from "../utils/constants";
import { httpMethod, request } from "./request";

export const disableSku = async (currScrapedFile, mapping, accountId) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/master/${currScrapedFile.masterId}:disableSku`;
  const requestBody = {
    accountId: accountId,
    skuList: [mapping.productModelId],
  };

  await request(
    endpoint,
    getAccessToken,
    JSON.stringify(requestBody),
    httpMethod.POST
  );

  if (mapping.mappingStatus !== MappingStatus.COMPLETED) {
    await request(
      `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalise/${mapping.referenceId}`,
      getAccessToken,
      undefined,
      httpMethod.DELETE
    );
  }
};
