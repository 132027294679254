import React, { Fragment } from "react";
import { Select, TextField } from "@cimpress/react-components";
import {
  createDropdownElement,
  findValueOfKey,
} from "../../services/mapAttributeService";

export default function SelectedScrapeAttributeView(props) {
  const {
    scrapedAttributes,
    scrapedKeys,
    getAttributeKeys,
    ruleIndex,
    onDeleteAttributeKey,
    onSelectAttributeKey,
  } = props;

  let rowSize = 12 / scrapedKeys.length;
  function onSelectionChange(e, index) {
    let value = e !== null ? e.value : "";
    onSelectAttributeKey(value, ruleIndex, index);
  }
  function HandleDeleteAttributeKey(index) {
    onDeleteAttributeKey(ruleIndex, index);
  }
  const getDeleteButton = (handleOnClick) => {
    return (
      <i
        data-toggle="tooltip"
        title="Delete"
        className="fa fa-trash-o"
        onClick={handleOnClick}
      />
    );
  };

  function AddAttributeValueControl() {
    return scrapedAttributes.map((scrapedAttribute, index) => {
      return (
        <div key={index} className="row">
          {scrapedKeys.map((key, childIndex) => {
            return (
              <div className={`col-md-${rowSize}`} key={childIndex}>
                <TextField
                  name=""
                  label="Attribute Value"
                  value={findValueOfKey(scrapedAttribute.data, key)}
                  readOnly
                  helpText=""
                />
              </div>
            );
          })}
        </div>
      );
    });
  }

  function AddAttributeKeyControls() {
    return (
      <div className="row">
        {scrapedKeys.map((key, index) => {
          if (index > 0) {
            return (
              <div className={`col-md-${rowSize}`} key={`scrap${index}`}>
                <div className="flex-container">
                  <div className="leftdiv">
                    <Select
                      required
                      label="Attribute Key"
                      value={{
                        label: key,
                        value: key,
                      }}
                      options={createDropdownElement(getAttributeKeys())}
                      onChange={(e) => onSelectionChange(e, index)}
                      tether
                    />
                  </div>
                  <div className="rightdiv">
                    {getDeleteButton(() => {
                      HandleDeleteAttributeKey(index);
                    })}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className={`col-md-${rowSize}`} key={key + index}>
                <TextField
                  name="AttributeKey"
                  label="AttributeKey"
                  value={key}
                  readOnly
                  helpText=""
                />
              </div>
            );
          }
        })}
      </div>
    );
  }

  return (
    <Fragment>
      {AddAttributeKeyControls()}
      {AddAttributeValueControl()}
    </Fragment>
  );
}

export const MemoizeSelectedScrapeAttributeView = React.memo(
  SelectedScrapeAttributeView,
  (prevProps, nextProps) => {
    return prevProps.scrapedKeys === nextProps.scrapedKeys;
  }
);
