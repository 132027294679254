import React from "react";
import { Button, Modal } from "@cimpress/react-components";

function NewProductVersionConfirmationModal({
  status,
  show,
  title,
  onCancelNewProductVersionConfirmationModal,
  onContinueNewProductVersionConfirmationModal,
  file,
  errorMessage,
}) {
  return (
    <Modal
      status={status}
      show={show}
      title={title}
      footer={
        <div>
          <Button onClick={() => onCancelNewProductVersionConfirmationModal()}>
            No, Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => onContinueNewProductVersionConfirmationModal(file)}
          >
            Yes, Continue
          </Button>
        </div>
      }
    >
      <div>{errorMessage}</div>
    </Modal>
  );
}


export default NewProductVersionConfirmationModal;
