import { routeNames } from "../../constants/general";

export const ViewMappingScreen = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.MAPPING_MANAGEMENT}`, label: "Mapping Management" },
  { to: "", label: "Product Option Mapping" },
];

export const requestItems = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.REQUESTS}`, label: "Requests" },
];

export const workFlowItems = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.WORKFLOW}`, label: "Workflow" },
];

export const competitorItems = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.COMPETITORS}`, label: "Competitors" },
];

export const mappingManagementItems = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.MAPPING_MANAGEMENT}`, label: "Mapping Management" },
];

export const questionnaireItems = (
  accountId,
  competitor = undefined,
  category = undefined,
  country = undefined
) => [
  { to: "/", label: "Home" },
  {
    to: competitor
      ? `/${routeNames.MAPPING_MANAGEMENT}/${accountId}/${encodeURI(
          competitor
        )}/${country}/${encodeURI(category)}`
      : `/${routeNames.MAPPING_MANAGEMENT}/${accountId}`,
    label: "Mapping Management",
  },
  {
    to: `/${routeNames.MAPPING_MANAGEMENT}/${accountId}/${routeNames.QUESTIONNAIRE}`,
    label: "Edit File Mapping",
  },
];

export const scrapingJobItems = [
  { to: "/", label: "Home" },
  { to: `/${routeNames.JOBS}`, label: "Scraping Jobs" },
];

export const notFound = [{ to: "/", label: "Home" }];
