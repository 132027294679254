import React, {useEffect, useState, useCallback } from 'react';
import { Snackbar, Select, shapes, CssLoader } from '@cimpress/react-components';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';

import { SELECTEDACCOUNTCONFIGURATION_KEY } from '../utils/constants';
import { getScrapingToolConfigurations } from '../services/scrapingToolConfigurations';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '../services/localStorage';
import {routeNames} from '../constants/general';


const getSnackBarInitialState = () => ({
    showSnackbar: false,
    snackBarMessage: '',
    snackBarStatus: 'success',
});

const AccountSelection = ({accessToken, isProduction}) => {
  let {pathname, id} = useParams();
  let history = useHistory();

  const [scrapingToolConfigurations, setScrapingToolConfigurations] = useState([]);
  const [selectedScrapingToolConfiguration, setSelectedScrapingToolConfiguration] = useState(undefined);
  const [snackBarInfo, setSnackBarInfo] = useState(getSnackBarInitialState);
  const [isLoading, setIsLoading] = useState(true);

  const AccountRedirection = useCallback((selectedToolConfiguration, response, priority) => {
    const accountId = selectedToolConfiguration?.value?.accountId;

    if(_.isEmpty(pathname)){
      history.push(`/${routeNames.HOME}/${accountId}`);
    }
    else if(_.isEmpty(id)){
      history.push(`/${pathname}/${accountId}`);
    }
    else {
      if(priority === true){
        const paramAccountDetail = response.find(account => account.value.accountId === id);

        if(_.isEmpty(paramAccountDetail)){
          removeLocalStorage(SELECTEDACCOUNTCONFIGURATION_KEY);
          
          history.push(`/${routeNames.NOT_FOUND}`);
        }
        else{
          setSelectedScrapingToolConfiguration(paramAccountDetail);

          setLocalStorage(SELECTEDACCOUNTCONFIGURATION_KEY, paramAccountDetail);
        }
      }
      else{
        history.push(`/${pathname}/${accountId}`);
      }
    }
  }, [history, id, pathname])

  useEffect(() => {
      let _isMounted = true;
      async function fetchScrapingToolConfigurations(accessToken, isProduction) {
          try {
              const response = await getScrapingToolConfigurations(accessToken, isProduction);
              if (_isMounted) {
                  setScrapingToolConfigurations(response);

                  const getSelectedToolConfiguration = getLocalStorage(SELECTEDACCOUNTCONFIGURATION_KEY);

                  if (!_.isEmpty(getSelectedToolConfiguration)) {
                      setSelectedScrapingToolConfiguration(getSelectedToolConfiguration);

                      AccountRedirection(getSelectedToolConfiguration, response, true);
                  } else {
                      setSelectedScrapingToolConfiguration(response[0]);

                      setLocalStorage(SELECTEDACCOUNTCONFIGURATION_KEY, response[0]);

                      AccountRedirection(response[0], response, true);
                  }

                  setIsLoading(false);
              }
          } catch (err) {
              if (_isMounted) {
                  setSnackBarInfo({
                      showSnackBar: true,
                      snackBarMessage: err.message,
                      snackBarStatus: 'danger',
                  });

                  setIsLoading(false);
              }
          }
      }

      fetchScrapingToolConfigurations(accessToken, isProduction);

      return () => {
          _isMounted = false;
      };
  }, [isProduction, accessToken, id, AccountRedirection]);

  const changeAccountSelection = (value) => {
      setSelectedScrapingToolConfiguration(value);
      setLocalStorage(SELECTEDACCOUNTCONFIGURATION_KEY, value);
      AccountRedirection(value, scrapingToolConfigurations, false);
  };

  const hideSnackbar = () =>
      setSnackBarInfo({
          showSnackBar: false,
          snackBarMessage: '',
      });

  const { showSnackBar, snackBarStatus, snackBarMessage } = snackBarInfo;
  const { Spinner } = shapes;

  return (
    <>
      {isLoading ? (
          <Spinner fullPage />
      ) : (
          <CssLoader>
              <Snackbar show={showSnackBar} bsStyle={snackBarStatus} delay={7000} onHideSnackbar={hideSnackbar}>
                  {snackBarMessage}
              </Snackbar>
              {scrapingToolConfigurations?.length >= 1 ? (
                  <div className="row" id="account-selection-dropdown">
                      <div style={{ width: '300px', float: 'right', paddingRight: '20px' }}>
                          <Select
                              required
                              label="Account"
                              value={selectedScrapingToolConfiguration}
                              onChange={changeAccountSelection}
                              options={scrapingToolConfigurations}
                          />
                      </div>
                  </div>
              ) : null}
          </CssLoader>
      )}
    </>
  );
}

export default AccountSelection;
