import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  shapes,
  Snackbar,
  Tooltip,
} from "@cimpress/react-components";
import { buttonStyle, syncData } from "../../services/productAttributeMapping";
import {
  areAllMandatoryFieldPopulated,
  isAtLeastOneAttributeKeyMapped,
} from "../../utils/validation";
import { getDisplayMessage } from "../../services/errorMessages";
import * as Constants from "../../utils/constants";
import {
  createFinalSubmitData,
  createDraftSubmitData,
  saveFinalSubmitData,
  createPreviewData,
} from "../../services/submit";
import Preview from "../preview/Preview";
import _ from "underscore";
import Warnings from "../Warnings/Warnings";
import {
  GetThresholdPriceChanges,
  GetMultiplePricePoints,
} from "../../services/warnings";
import { getColumnHeadersAndValues } from "../../utils/common";
import {
  transformMultiplePricePointResponseToNormalizeDataFormat,
  transformPriceChangeResponseToNormalizeDataFormat,
} from "../../utils/warningsUtil";
import IconQuestionCircle from "@cimpress-technology/react-streamline-icons/lib/IconQuestionCircle";
import { colors } from "@cimpress/react-components";
import SyncModal from "../mappingManagement/SyncModal";

export default class MappingActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false,
      showWarnings: false,
      showSubmitAndSyncModal: false,
      showSpinner: false,
      finalSubmitData: undefined,
      snackbarDetails: {
        show: false,
        snackBsStyle: undefined,
        message: "",
        delay: 0,
      },
      modalDetails: {
        open: false,
        bsStyle: undefined,
        footer: undefined,
        modalMessage: undefined,
      },
      isDraft: this.props.isDraft,
      warnings: undefined,
      toSync: false,
    };
  }

  onPreviewClick = async () => {
    this.setState({ showSpinner: true });
    const finalSubmitData = await this.createSubmitData(
      false,
      createPreviewData
    );
    this.props.updateSelectedScrapedKeyState();
    this.setState({
      finalSubmitData: finalSubmitData,
      showPreview: !this.state.showPreview,
      showSpinner: false,
    });
  };

  togglePreviewScreen = () => {
    this.setState({ showPreview: !this.state.showPreview });
  };

  toggleWarningsScreen = () => {
    this.setState({ showWarnings: !this.state.showWarnings, toSync: false });
  };

  toggleSubmitAndSyncModal = () => {
    this.setState({
      showSubmitAndSyncModal: !this.state.showSubmitAndSyncModal,
      toSync: false,
    });
  };

  closeModal = () => {
    this.setState({ modalDetails: { open: false, bsStyle: undefined } });
  };

  createSubmitData = async (isDraft, fnCreateFinalSubmitData) => {
    const {
      translationRules,
      scrapedKeyStates,
      skuId,
      skuVersion,
      selectedFileInfo,
      getFulfillerName,
      defaultTenantData,
      modelMappingId,
      isStandard,
      accountId,
      copiedScrapeAttributes,
    } = this.props;
    const name = await getFulfillerName();
    const mcpDetail = { skuId: skuId, name: name, skuVersion: skuVersion };

    return fnCreateFinalSubmitData(
      translationRules,
      scrapedKeyStates,
      mcpDetail,
      selectedFileInfo,
      defaultTenantData,
      modelMappingId,
      isDraft,
      isStandard,
      accountId,
      copiedScrapeAttributes
    );
  };

  onClickSaveAsDraft = () => {
    const { translationRules, scrapedKeyStates } = this.props;
    if (isAtLeastOneAttributeKeyMapped(translationRules, scrapedKeyStates)) {
      this.setState({
        modalDetails: {
          open: true,
          bsStyle: "info",
          modalMessage: Constants.Messages.SAVE_AS_DRAFT,
          footer: this.getFooterModalForDraftSave,
        },
      });
    } else {
      this.setState({
        snackbarDetails: {
          show: true,
          snackBsStyle: "danger",
          message: Constants.Messages.SAVE_AS_DRAFT_VALIDATION,
          delay: 3000,
        },
      });
    }
  };

  onSubmitClick = async () => {
    var warnings = await this.getWarnings();
    if (warnings != null) {
      if (warnings !== "hideSubmit") {
        this.setState({ warnings: warnings, showWarnings: true });
      }
    } else {
      this.setState({
        modalDetails: {
          open: true,
          bsStyle: "info",
          modalMessage: "Are you sure you want to submit?",
          footer: this.getFooterModalForFinalSubmit,
        },
      });
    }
  };

  getWarnings = async () => {
    this.setState({ showSpinner: true });
    const { getAccessToken } = this.props;
    const request = await this.createSubmitData(false, createFinalSubmitData);
    const thresholdPriceChanges = GetThresholdPriceChanges(
      getAccessToken,
      request
    );
    const multiplePricePoints = GetMultiplePricePoints(getAccessToken, request);
    const results = await Promise.allSettled([
      thresholdPriceChanges,
      multiplePricePoints,
    ]);
    const errorResults = results.filter(
      (result) => result.status === "rejected"
    );
    const successResults = results.filter(
      (result) => result.status === "fulfilled"
    );
    if (errorResults.length > 0) {
      const otherErrors = errorResults.filter(
        (error) => error.reason.response.status !== 422
      );
      if (otherErrors.length > 0) {
        this.setState({
          snackbarDetails: {
            show: true,
            snackBsStyle: "danger",
            message: getDisplayMessage({
              customMessage: Constants.Messages.CONTACT_PHOENIX_SQUAD,
            }),
            delay: 3000,
          },
        });
        this.setState({ showSpinner: false });
        return "hideSubmit";
      }
    }
    if (successResults.length > 0) {
      let finalResponse = null;
      successResults.forEach((result) => {
        if (
          result.value.length > 0 &&
          result.value[0].row &&
          result.value[0].changes
        ) {
          const { columnHeaders, rows } = getColumnHeadersAndValues(
            transformPriceChangeResponseToNormalizeDataFormat(result.value)
          );
          if (columnHeaders && rows) {
            finalResponse = {
              ThresholdPriceChanges: {
                columnHeaders: columnHeaders,
                rowsData: rows,
              },
            };
          }
        } else if (
          result.value.length > 0 &&
          result.value[0].attributes &&
          result.value[0].prices
        ) {
          const { columnHeaders, rows } = getColumnHeadersAndValues(
            transformMultiplePricePointResponseToNormalizeDataFormat(
              result.value
            )
          );
          if (columnHeaders && rows) {
            finalResponse = {
              ...finalResponse,
              MultiplePricePoints: {
                columnHeaders: columnHeaders,
                rowsData: rows,
              },
            };
          }
        }
      });
      this.setState({ showSpinner: false });
      return finalResponse;
    }
    this.setState({ showSpinner: false });
    return null;
  };

  saveMappingData = async (
    isDraft,
    fnCreateFinalSubmitData,
    toSync = false,
    startDate = undefined,
    syncExistingMappings = false
  ) => {
    this.setState({ isDraft: isDraft });
    toSync && this.toggleSubmitAndSyncModal();
    this.setState({
      showSpinner: true,
      modalDetails: { open: false, bsStyle: undefined },
    });
    const finalSubmitData = await this.createSubmitData(
      isDraft,
      fnCreateFinalSubmitData
    );
    const { getAccessToken, updateMappingModalId, updateScrapedKeyStates } =
      this.props;
    let syncCalled = false;
    try {
      const response = await saveFinalSubmitData(
        finalSubmitData,
        getAccessToken
      );
      if (toSync) {
        syncCalled = true;
        let syncRequest = {
          modelMappingId: response.modelMappingId,
          startDate: startDate,
          endDate: new Date(),
          syncExistingMappings: syncExistingMappings,
        };
        await syncData(getAccessToken, syncRequest);
      }
      updateMappingModalId(response.modelMappingId);
      updateScrapedKeyStates();
      this.setState({
        snackbarDetails: {
          show: true,
          snackBsStyle: "success",
          delay: 3000,
          message: getDisplayMessage({
            customMessage: toSync
              ? Constants.Messages.SUCCESSFUL_FINAL_SUBMIT_AND_SYNC
              : Constants.Messages.SUCCESSFUL_FINAL_SUBMIT,
          }),
        },
        showSpinner: false,
      });
    } catch (error) {
      this.setState({
        snackbarDetails: {
          show: true,
          snackBsStyle: "danger",
          message: getDisplayMessage({
            customMessage: syncCalled
              ? Constants.Messages.SYNC_FAILED
              : Constants.Messages.FINAL_SUBMIT_FAILED,
            error: error,
          }),
        },
        showSpinner: false,
      });
    } finally {
      this.setState({
        showSpinner: false,
        toSync: false,
        showSubmitAndSyncModal: false,
      });
    }
  };

  onSubmitAndSyncClick = async () => {
    this.setState({ toSync: true });
    var warnings = await this.getWarnings();
    if (warnings != null) {
      if (warnings !== "hideSubmit") {
        this.setState({ warnings: warnings, showWarnings: true });
      }
    } else {
      this.toggleSubmitAndSyncModal();
    }
  };

  onFinalSubmitData = () => {
    this.saveMappingData(false, createFinalSubmitData);
  };

  onDraftSave = () => {
    this.saveMappingData(true, createDraftSubmitData);
  };

  disableFinalSubmit = () => {
    var partialKeyFilled = this.props.defaultTenantData.find(
      (x) =>
        (x.attributeKey !== "" && _.isEmpty(x.defaultValue)) ||
        (_.isEmpty(x.attributeKey) && !_.isEmpty(x.defaultValue))
    );
    return (
      !areAllMandatoryFieldPopulated(
        this.props.scrapedKeyStates,
        this.props.translationRules
      ) || partialKeyFilled !== undefined
    );
  };

  onSubmitAndSyncModelMapping = (sinceDate, syncExistingMappings) => {
    this.saveMappingData(
      false,
      createFinalSubmitData,
      true,
      sinceDate,
      syncExistingMappings
    );
  };

  getCloseModal = (
    <Button type="default" onClick={this.closeModal}>
      cancel
    </Button>
  );

  getFooterModalForDraftSave = (
    <div>
      <Button type="primary" onClick={this.onDraftSave}>
        ok
      </Button>
      {this.getCloseModal}
    </div>
  );

  getFooterModalForFinalSubmit = (
    <div>
      <Button type="primary" onClick={this.onFinalSubmitData}>
        ok
      </Button>
      {this.getCloseModal}
    </div>
  );

  hideSnackbar = () =>
    this.setState({
      snackbarDetails: {
        show: false,
      },
    });

  render() {
    const { getAccessToken, selectedFileInfo } = this.props;
    const {
      finalSubmitData,
      showPreview,
      showWarnings,
      modalDetails: { open, bsStyle, footer, modalMessage },
      snackbarDetails: { show, snackBsStyle, message, delay },
      showSpinner,
      isDraft,
      showSubmitAndSyncModal,
      toSync,
    } = this.state;
    const { Spinner } = shapes;

    return (
      <div className="row">
        <div className="col-md-6" />
        <div className="col-md-6" align="right">
          <ButtonGroup type="justified">
            <Button style={buttonStyle} onClick={this.onPreviewClick}>
              Preview
            </Button>
            <Button
              style={buttonStyle}
              onClick={this.onClickSaveAsDraft}
              disabled={!isDraft || this.props.disableActions}>
              Save Draft
            </Button>
            <Button
              style={buttonStyle}
              onClick={this.onSubmitClick}
              disabled={this.disableFinalSubmit() || this.props.disableActions}>
              Submit
            </Button>
            <Button
              style={buttonStyle}
              type="primary"
              onClick={this.onSubmitAndSyncClick}
              disabled={
                !selectedFileInfo.isLatest ||
                this.disableFinalSubmit() ||
                this.props.disableActions
              }>
              Submit & Sync
            </Button>
            <Tooltip
              direction="right"
              contents="Submit & Sync allows you to save these updates to other previously uploaded files">
              <IconQuestionCircle
                style={{ marginLeft: 5 }}
                size={"lg"}
                color={colors.ocean.darker}
              />
            </Tooltip>
          </ButtonGroup>

          <br />
        </div>
        {showPreview && (
          <Preview
            togglePreviewScreen={this.togglePreviewScreen}
            showPreview={showPreview}
            finalSubmitData={finalSubmitData}
            getAccessToken={getAccessToken}
          />
        )}
        {showWarnings && (
          <Warnings
            toggleWarningsScreen={this.toggleWarningsScreen}
            showWarnings={showWarnings}
            data={this.state.warnings}
            onClickSubmit={
              toSync ? this.toggleSubmitAndSyncModal : this.onFinalSubmitData
            }
          />
        )}
        {showSubmitAndSyncModal && (
          <SyncModal
            sku={this.props.skuId}
            showSyncModal={showSubmitAndSyncModal}
            toggleSyncModal={this.toggleSubmitAndSyncModal}
            onSync={this.onSubmitAndSyncModelMapping}
            showSpinner={showSpinner}
          />
        )}
        <Modal
          bsStyle={bsStyle}
          show={open}
          onRequestHide={this.closeModal}
          footer={footer}>
          {modalMessage}
        </Modal>
        <Snackbar
          show={show}
          bsStyle={snackBsStyle}
          delay={delay}
          onHideSnackbar={this.hideSnackbar}>
          {message}
        </Snackbar>
        {showSpinner && <Spinner fullPage />}
      </div>
    );
  }
}
