import { request, httpMethod } from "./request";
import axios from "axios";
import { SubscriptionOption } from "../utils/constants";

export const convertToMultiSelectOptionsFormat = (masterData) => {
  const outputMap = new Map();

  masterData.forEach((item) => {
    if (!outputMap.has(item.country)) {
      outputMap.set(item.country, {
        country: item.country,
        options: [],
      });
    }

    const option = {
      value: item.productCategory,
      label: item.productCategory,
    };

    const countryOptions = outputMap.get(item.country).options;

    if (
      !countryOptions.some(
        (existingOption) => existingOption.value === option.value
      )
    ) {
      countryOptions.push(option);
    }
  });

  const outputArray = Array.from(outputMap.values());

  outputArray.forEach((item) => {
    item.options.sort((a, b) => a.value.localeCompare(b.value));
  });

  return outputArray;
};

export const convertToMultiSelectValueFormat = (valueArray) => {
  let multiSelectValues = [];
  valueArray.forEach((eachValue) => {
    multiSelectValues.push({
      value: eachValue,
      label: eachValue,
    });
  });
  return multiSelectValues;
};

export const convertToMultiSelectForEachCountry = (valueArray) => {
  let valueObjects = {};
  valueArray.forEach((eachValue) => {
    let subscriptionId = eachValue.subscriptionId;
    let categories = convertToMultiSelectValueFormat(eachValue.categories);
    let stringCategories = eachValue.categories;
    let notification, isMultiSelectDisabled;
    if (subscriptionId === null) {
      notification = SubscriptionOption.UNSUBSCRIBE_ALL;
      isMultiSelectDisabled = true;
    } else {
      if (categories.length === 0 || categories === null) {
        notification = SubscriptionOption.SUBSCRIBE_ALL;
        isMultiSelectDisabled = true;
      } else {
        notification = SubscriptionOption.SUBSCRIBE_INDIVIDUAL;
        isMultiSelectDisabled = false;
      }
    }
    valueObjects[eachValue.countries[0]] = {
      subscriptionId,
      categories,
      notification,
      isMultiSelectDisabled,
      stringCategories,
    };
  });
  return valueObjects;
};

export const postSubscriptionConfiguration = async (
  getAccessToken,
  payload
) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/subscribe/`;
  return await request(
    endpoint,
    getAccessToken,
    JSON.stringify(payload),
    httpMethod.POST
  );
};

export const getSubscriptionConfiguration = async (
  getAccessToken,
  accountId
) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/subscribe/accountId/${accountId}`;
  try {
    return await request(endpoint, getAccessToken);
  } catch (error) {
    return undefined;
  }
};

export const deleteSubscriptionConfiguration = async (
  getAccessToken,
  subscriptionId
) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/subscribe/${subscriptionId}`;
  const authorization = { authorization: `Bearer ${getAccessToken()}` };
  const headers = { ...authorization };
  return await axios.delete(endpoint, { headers });
};

export const createPostSubscriptionPayload = (
  categories,
  subscriptionAction
) => {
  return {
    competitor: [],
    category: categories,
    skuId: [],
    subscribeOn: [],
    action: subscriptionAction,
    subscriptionName: "",
    subscriptionId: "",
  };
};

export const createPostSubscribePayload = (categories, country, accountId) => {
  return {
    countries: [country],
    categories: categories,
    accountId: accountId,
  };
};
