import React from "react";
import { Button, Checkbox, Tooltip } from "@cimpress/react-components";
import { Messages, ScrapedKeyState } from "../../utils/constants";
import { generateToolTip } from "../../utils/utils";
const btnStyle = { border: "solid #00738E 1px", minWidth: "125px" };

export default function SelectedAttributeButtons({
  tenantKeys,
  scrapedKeys,
  ruleIndex,
  onCopyScrapeValues,
  onDeleteScrapeKey,
  onScrapedKeySplit,
  onScrapedKeyMerge,
  mappingState,
  disableActions,
  copiedScrapeAttributes,
  onToggleAlwaysCopy,
}) {
  const isMerged =
    scrapedKeys.length > 1 || mappingState === ScrapedKeyState.DELETED;
  const isSplit =
    tenantKeys.length > 1 || mappingState === ScrapedKeyState.DELETED;

  function disableCopyButton() {
    if (tenantKeys[0] === "") {
      return true;
    }
    return isMerged;
  }

  function disableDeleteButton() {
    return scrapedKeys.length > 1 || tenantKeys.length > 1;
  }

  function handleScrapedKeySplit() {
    onScrapedKeySplit(ruleIndex);
  }

  function handleScrapedKeyMerge() {
    onScrapedKeyMerge(ruleIndex);
  }

  function handleCopyScrapeValue() {
    onCopyScrapeValues(ruleIndex);
  }

  function handleDeleteScrapeKey() {
    onDeleteScrapeKey(
      mappingState !== ScrapedKeyState.DELETED
        ? ScrapedKeyState.DELETED
        : ScrapedKeyState.PARTIALLY_MAPPED
    );
  }

  return (
    <div className="selected-attribute-buttons">
      <div className="remove-right-border remove-right-radius">
        <Button
          key="0"
          type="default"
          style={btnStyle}
          onClick={handleDeleteScrapeKey}
          disabled={disableDeleteButton() || disableActions}>
          <Tooltip
            direction="bottom"
            contents={
              mappingState === ScrapedKeyState.DELETED
                ? ` ${scrapedKeys[0]} will be added back to scraped file`
                : disableDeleteButton() ? generateToolTip(scrapedKeys, tenantKeys) : ` ${scrapedKeys[0]} will be removed from scraped file`
            }>
            {mappingState === ScrapedKeyState.DELETED
              ? " Undo Remove"
              : "Remove"}
          </Tooltip>
        </Button>
      </div>
      <div className="copy-button">
        <div className="remove-right-border remove-complete-radius">
          <Button
            key="1"
            type="default"
            onClick={handleCopyScrapeValue}
            disabled={disableCopyButton() || disableActions}
            style={btnStyle}>
            <Tooltip
              direction="bottom"
              contents={Messages.MAP_TENANT_KEY_FOR_COPY}>
              Copy
            </Tooltip>
          </Button>
        </div>
        <div className="always-copy">
          <Checkbox
            inline
            label="Always copy?"
            checked={copiedScrapeAttributes.includes(scrapedKeys[0])}
            onChange={() => {
              onToggleAlwaysCopy(scrapedKeys[0]);
            }}
            disabled={isMerged || isSplit}
          />
        </div>
      </div>
      <div className="remove-right-border remove-complete-radius">
        <Button
          key="2"
          type="default"
          onClick={handleScrapedKeyMerge}
          disabled={isSplit || disableActions}
          style={btnStyle}>
          Merge
        </Button>
      </div>
      <div className="remove-left-radius">
        <Button
          key="3"
          type="default"
          onClick={handleScrapedKeySplit}
          disabled={isMerged || disableActions}
          style={btnStyle}>
          Split
        </Button>
      </div>
    </div>
  );
}

export const MemoizeSelectedAttributeButtons = React.memo(
  SelectedAttributeButtons,
  (prevProps, nextProps) => {
    return (
      prevProps.scrapedKeys === nextProps.scrapedKeys &&
      prevProps.tenantKeys === nextProps.tenantKeys &&
      prevProps.ruleIndex === nextProps.ruleIndex &&
      prevProps.mappingState === nextProps.mappingState &&
      prevProps.copiedScrapeAttributes === nextProps.copiedScrapeAttributes
    );
  }
);
