import React, { Component } from "react";
import "../draggableButton/DraggableButton.css";

export default class DefaultValueSelectionScreen extends Component {
  render() {
    return (
      <div>
        <div
          onClick={this.props.openDefaultValueScreen}
          className={`${
            this.props.showDefaultValueScreen
              ? "draggable item-selected"
              : "draggable"
          }`}
        >
          Default Values
        </div>
      </div>
    );
  }
}
