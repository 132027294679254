import React, { Component } from "react";
import { Select } from "@cimpress/react-components";

class Selector extends Component {
  onChange = (e) => {
    const { onChange, index } = this.props;
    onChange(e, index);
  };

  render() {
    return (
      <Select
        isDisabled={this.props.disableActions}
        clearable
        label={this.props.label}
        value={this.props.options.find(
          (option) => this.props.selectedValue === option.value
        )}
        options={this.props.options}
        onChange={this.onChange}
        tether
      />
    );
  }
}

export default Selector;
