import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  convertToMultiSelectOptionsFormat,
  convertToMultiSelectForEachCountry,
  getSubscriptionConfiguration,
} from "../services/subscriptions";
import { getMasterData } from "../services/master";
import { Snackbar, Pagination, TextField, } from '@cimpress/react-components';
import * as JsSearch from 'js-search';
import Breadcrumb from "./breadcrumb/Breadcrumb";
import { Messages } from "../utils/constants";
import ReactCountryFlag from 'react-country-flag';
import map from 'lodash/map';
import _ from 'lodash';
import countryList from 'react-select-country-list';
import { shapes } from '@cimpress/react-components';
import PropTypes from 'prop-types';
import SubscriptionAccordion from "./SubscriptionAccordion";

const paginationCount = 5;

const searchBarStyle = {
  width: '100%'
};

const getDefaultCountryOptions = () =>
  map(countryList().getLabels(), (countryName) => {
    const countryCode = countryList().getValue(countryName);
    return {
      value: countryCode,
      country: countryName,
      label: (
        <div>
          <span style={{ marginRight: '15px' }}>
            <ReactCountryFlag
              className="emojiFlag"
              countryCode={countryCode}
              style={{
                fontSize: '1.5em',
              }}
              svg
            />
          </span>
          {countryName}
        </div>
      ),
    };
  });

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      options: [],
      categories: null,
      selectedCategories: [],
      enable: false,
      enableUnsubscribeButton: false,
      customOpen: PropTypes.bool.isRequired,
      countriesOptions: getDefaultCountryOptions(),
      selectedNotification: "All",
      selectedCountry: "",
      subscriptionId: null,
      subscribedData:[],
      isLoading:true,
      currentPage: 0,
      searchText: '',
      snackbarDetails: {
        show: false,
        bsStyle: undefined,
        delay: 0,
        message: ""
      }
    }
  }

 async initializeComponent() {
    const { getAccessToken } = this.props;
    const account = this.props.match.params.id;
    let options = [];
    try {
      const masterData = await getMasterData(account);
      options = convertToMultiSelectOptionsFormat(masterData);
      const subscriptionResponse = await getSubscriptionConfiguration(getAccessToken, account);
      this.setState({ options: options, subscribedData : (_.isEmpty(subscriptionResponse) ? [] : convertToMultiSelectForEachCountry(subscriptionResponse._embedded.item)), isLoading: false });
    } catch (error) {
      this.setState({
        snackbarDetails: {
          show: true,
          bsStyle: "danger",
          message: Messages.FAILED_FETCH_SUBSCRIPTION_DATA,
          isLoading: false
        }
      });
    }
  }

 async componentDidMount() {
    this.initializeComponent();
  }

 async componentDidUpdate(prevProps, prevState) {
    if (prevState.searchText !== this.state.searchText) {
      this.setState({ currentPage: 0 });
    }
    if(!_.isEqual(prevProps.match.params.id, this.props.match.params.id))
      this.initializeComponent();
  }

  updateSubscribedData = (countryCode, newSubscription) => 
  {
    let updatedSubscriptions = this.state.subscribedData;
    updatedSubscriptions[countryCode] = newSubscription;
    this.setState({subscribedData : updatedSubscriptions});
  }

  makeSearchParams = countries => {
    const search = new JsSearch.Search('country');
    search.addIndex('country');
    search.addDocuments(countries);
    return search;
  };

  onPageChange = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  hideSnackbar = () =>
    this.setState({
      snackbarDetails: {
        show: false
      }
    });

  updateSnackbar = (show, bsStyle, delay, message) =>
  {
    this.setState({
      snackbarDetails: {
        show: show,
        bsStyle: bsStyle,
        message: message,
        delay: delay,
        isLoading: false
      }
    });
  }

  onPageChange = ({ selected }) => {
    this.setState({ currentPage: selected });
  };

  onChangeSearch = event => {
    this.setState({ searchText: event.target.value });
  };

  createPaginatedAccordions = (searchedCountries, pageCount, currentPage) => {
    const {options, subscribedData} = this.state;
    const {getAccessToken} = this.props;
    const account = this.props.match.params.id;
    const startIndex = paginationCount * currentPage;
    const countriesToRender = searchedCountries.slice(
      startIndex,
      currentPage === pageCount ? searchedCountries.length : startIndex + paginationCount
    );

    return countriesToRender.map(country => <li key={country.value} value={country.country} >
      <SubscriptionAccordion 
        countryCode={country.value}
        title={country.country}
        subscriptionData={subscribedData[country.value]}
        options={options}
        getAccessToken={getAccessToken}
        account={account}
        updateSnackbar={this.updateSnackbar}
        updateSubscribedData={this.updateSubscribedData}
        />
    </li>);
  };

  render() {
    let {
      snackbarDetails: { show, bsStyle, message, delay }
    } = this.state;
    const { Spinner } = shapes;
    const { searchText, currentPage, countriesOptions } = this.state;
    const jsSearch = this.makeSearchParams(countriesOptions);
    const searchedCountries = !searchText || searchText === '' ? countriesOptions : jsSearch.search(searchText);
    const pageCount = Math.floor(searchedCountries.length / paginationCount) + 1;

    const searchField = (
        <TextField
          style={searchBarStyle}
          id="SearchBar"
          type="search"
          value={searchText}
          onChange={this.onChangeSearch}
          label="Search Country"
        />
        );

    const accordions = this.createPaginatedAccordions(searchedCountries, pageCount, this.state.currentPage);

    const pagination = (
      <div style={{ textAlign: 'center' }}>
        <Pagination
          initialPage={currentPage}
          pageCount={pageCount}
          forcePage={currentPage}
          onPageChange={this.onPageChange}
        />
      </div>
    );

    return (
      <div className="container-fluid">
          {this.state.isLoading ? (
              <Spinner fullPage />
          ) :
          (
      <div> 
        <Snackbar
          show={show}
          bsStyle={bsStyle}
          delay={delay}
          onHideSnackbar={this.hideSnackbar}
        >
          {message}
        </Snackbar>
        <div className="container">
          <Breadcrumb separator="/">
            <Link key="/Home" to="/Home">
              Home
            </Link>
            <Link key="/notifications" to={"/notifications"}>
              notifications
            </Link>
          </Breadcrumb>
          <div className="white-template">
            <h1>Notification Subscription</h1>
            <p>Select the categories for the required countries and subscribe to get notification on following statuses</p>
            <p>New Scraping Request, Scraping Job Failed and Mapping Failed.</p>
            <br />
            
            <div className="row">
              <ul style={{ listStyleType: 'none' }}>
                {
                  <div className="settings__search-header">
                    {searchField}
                  </div>}
                  {accordions}
                  {pagination}
              </ul>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
      )
    }
    </div>
  )}
};

export default withRouter(Subscriptions);
