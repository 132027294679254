import { ScrapingJobsComponent } from "@cimpress-technology/scraping-jobs";
import React from "react";
import { Link, useParams } from "react-router-dom";

import Breadcrumb from "./breadcrumb/Breadcrumb";
import { scrapingJobItems } from "./breadcrumb/breadcrumbElements";

const ScrapingJobs = props => {
  const {id} = useParams();

  return (
    <div className="container-fluid">
      <div style={{ paddingLeft: '15px' }}>
        <Breadcrumb separator="/">
          {scrapingJobItems.map(({ to, label }) => (
            <Link key={to} to={to}>
              {label}
            </Link>
          ))}
        </Breadcrumb>
      </div>
      <div className="container-fluid">
        <ScrapingJobsComponent
            accessToken={props.accessToken}
            isProduction={props.isProduction}
            accountId={id}
        />
      </div>
    </div>
  );
};

export default ScrapingJobs;
