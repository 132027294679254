import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  shapes,
  Pagination,
  Toggle,
  FlexBox,
} from "@cimpress/react-components";
import MappingSearch from "./MappingSearch";
import ScrapedFileAccordion from "./ScrapedFileAccordion";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { mappingManagementItems } from "../breadcrumb/breadcrumbElements";
import { routeNames, snackBarStatus } from "../../constants/general";
import { getStandardProductMappingConfig } from "../../services/metaData";
import { getScrapedFiles } from "../../services/scrapingFileService";
import {
  getCurrentPageScrapedFile,
  getPageCount,
  getProcessScrapedFilesData,
} from "../../utils/manageMapping";
import mappingManagementStyles from "./MappingManagement.module.css";
import SnackBarAlert from "../SnackBarAlert";
import ConfirmationModal from "../ConfirmationModal";
import { deleteFileData } from "../../services/scrapingJob";
import {
  defaultConfirmationModalStateDetails,
  MappingStatus,
} from "../../utils/constants";
import { disableSku } from "../../services/disableSku";
import { getSixMonthAgo, toMonthDayYearDisplay } from "../../utils/dateFormat";

const { Spinner } = shapes;
const pageSize = 8;

const defaultSnackbarDetails = {
  show: false,
  status: null,
  delay: 0,
  message: null,
  error: undefined,
};

const MappingManagement = ({ accessToken }) => {
  const [scrapedFiles, setScrapedFiles] = useState([]);
  const [currScrapedFiles, setCurrScrapedFiles] = useState([]);
  const [pageCount, setPageCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [, setSharedScrapedDataConfig] = useState(undefined);
  const [showSpinner, setShowSpinner] = useState(false);
  const { id, competitor, category, country } = useParams();
  const [refreshScrapedFiles, setRefreshScrapedFiles] = useState(true);
  const [snackbar, setSnackbar] = useState(defaultSnackbarDetails);
  const [confirmationModal, setConfirmationModal] = useState(
    defaultConfirmationModalStateDetails
  );
  const [startDate, setStartDate] = useState(getSixMonthAgo());
  const [endDate, setEndDate] = useState(new Date());
  const search = useLocation().search;
  const qIsShared = new URLSearchParams(search).get("isshared");
  const isShared = qIsShared === "true" ? true : false;
  const [isToggleChecked, setToggleChecked] = useState(isShared);
  const history = useHistory();

  useEffect(() => {
    async function fetchProductMappingConfig() {
      const productMappingConfig = await getStandardProductMappingConfig(id);
      setSharedScrapedDataConfig(productMappingConfig);
    }
    setScrapedFiles([]);
    setCurrScrapedFiles([]);
    fetchProductMappingConfig();
  }, [id, accessToken]);

  useEffect(() => {
    setScrapedFiles([]);
    setCurrScrapedFiles([]);
  }, [isToggleChecked]);

  useEffect(() => {
    const fetchScrapedFiles = async (competitor, category, country) => {
      setShowSpinner(true);
      const scrapedFilesResponse = await getScrapedFiles(
        accessToken,
        id,
        competitor,
        category,
        country
      );
      const scrapedFiles = scrapedFilesResponse.files;
      let filteredFiles = [];
      if (scrapedFiles) {
        if (!isToggleChecked) {
          filteredFiles = scrapedFiles.filter(
            (x) =>
              x.fileOwnerAccountId === id &&
              new Date(x.scrapedDate) <= endDate &&
              new Date(x.scrapedDate) >= startDate
          );
        } else {
          filteredFiles = scrapedFiles.filter(
            (x) =>
              x.fileOwnerAccountId !== id &&
              new Date(x.scrapedDate) <= endDate &&
              new Date(x.scrapedDate) >= startDate
          );
        }
      }
      const totalRecord = filteredFiles.length;
      setTotalRecords(totalRecord);
      setPageCount(getPageCount(pageSize, totalRecord));
      const processedScrapedFiles = getProcessScrapedFilesData(filteredFiles);
      setScrapedFiles(processedScrapedFiles);
      setCurrScrapedFiles(
        getCurrentPageScrapedFile(
          totalRecord,
          pageSize,
          0,
          processedScrapedFiles
        )
      );
      setShowSpinner(false);
    };
    if (competitor && refreshScrapedFiles) {
      fetchScrapedFiles(competitor, category, country);
    }
    setRefreshScrapedFiles(false);
  }, [
    id,
    accessToken,
    competitor,
    category,
    country,
    refreshScrapedFiles,
    isToggleChecked,
    startDate,
    endDate,
  ]);

  const onToggle = () => {
    history.push(
      `/${routeNames.MAPPING_MANAGEMENT}/${id}?isshared=${!isToggleChecked}`
    );
    return setToggleChecked(!isToggleChecked);
  };
  const showAlert = (alertDetails) => {
    setSnackbar({ ...alertDetails, show: true });
  };

  const closeAlert = () => {
    setSnackbar((prevState) => ({ ...prevState, show: false }));
  };

  const deleteFileDataHandler = async (scrapingJobId) => {
    closeConfirmationModal();
    setShowSpinner(true);
    let alertDetails;
    try {
      await deleteFileData(scrapingJobId);
      setRefreshScrapedFiles(true);
      alertDetails = {
        message: `Succesfully Deleted the File Data.`,
        status: snackBarStatus.success,
        delay: 2000,
      };
    } catch (error) {
      alertDetails = {
        message: "Error Deleting the File Data. ",
        status: snackBarStatus.danger,
        error: error,
      };
    } finally {
      setShowSpinner(false);
      showAlert(alertDetails);
    }
  };

  const disableSkuHandler = async (currScrapedFile, mapping) => {
    closeConfirmationModal();
    let alertDetails;
    setShowSpinner(true);
    try {
      await disableSku(currScrapedFile, mapping, id);
      setRefreshScrapedFiles(true);
      alertDetails = {
        message:
          mapping.mappingStatus === MappingStatus.COMPLETED
            ? `Succesfully disabled the SKU.`
            : `Mapping deleted and the SKU is disabled.`,
        status: snackBarStatus.success,
        delay: 2000,
      };
    } catch (error) {
      alertDetails = {
        message: "Error Removing SKU. ",
        status: snackBarStatus.danger,
        error: error,
      };
    } finally {
      showAlert(alertDetails);
      setShowSpinner(false);
    }
  };

  const showConfirmationModalForDisableSku = (currScrapedFile, mapping) => {
    setConfirmationModal({
      description:
        mapping.mappingStatus === MappingStatus.COMPLETED
          ? "The SKU will be disabled from future mappings."
          : "The Mapping will be deleted and the SKU will be disabled from future mappings.",
      title: "Disable SKU",
      nonPrimaryButton: "No, Don’t disable SKU",
      primaryButton: "Yes, disable SKU",
      show: true,
      onPrimaryButtonClickAction: () =>
        disableSkuHandler(currScrapedFile, mapping),
      bsStyle: "warning",
    });
  };

  const showConfirmationModalForDeleteFileData = (
    currScrapedFile,
    scrapingJobId
  ) => {
    setConfirmationModal({
      description: (
        <React.Fragment>
          Warning, deleting the file data will permanently remove{" "}
          <b>{currScrapedFile.fileName}</b> scraped on{" "}
          <b>{toMonthDayYearDisplay(currScrapedFile.scrapedDate)}</b>. This
          action cannot be undone.
          <br />
          <br />
          Are you sure you want to delete this file data?
        </React.Fragment>
      ),
      title: "Delete File Data",
      nonPrimaryButton: "No, don't delete it",
      primaryButton: "Yes, delete it",
      show: true,
      onPrimaryButtonClickAction: () => deleteFileDataHandler(scrapingJobId),
      bsStyle: "warning",
    });
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(defaultConfirmationModalStateDetails);
  };

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected);
    if (totalRecords) {
      setCurrScrapedFiles(
        getCurrentPageScrapedFile(
          totalRecords,
          pageSize,
          selected,
          scrapedFiles
        )
      );
    }
  };

  const onFilter = (competitor, category, country) => {
    history.push(
      `/${routeNames.MAPPING_MANAGEMENT}/${id}/${encodeURIComponent(
        competitor
      )}/${country}/${encodeURIComponent(category)}?isshared=${isToggleChecked}`
    );
    setRefreshScrapedFiles(true);
  };

  let body = "There is no files available for mapping";
  if (currScrapedFiles && currScrapedFiles.length > 0) {
    body = (
      <>
        {currScrapedFiles.map((currScrapedFile, index) => {
          return (
            <ScrapedFileAccordion
              accessToken={accessToken}
              currScrapedFile={currScrapedFile}
              key={currScrapedFile.id}
              index={currScrapedFile.id}
              accountId={id}
              setRefreshScrapedFiles={setRefreshScrapedFiles}
              showSpinner={showSpinner}
              setShowSpinner={setShowSpinner}
              showAlert={showAlert}
              showConfirmationModalForDeleteFileData={
                showConfirmationModalForDeleteFileData
              }
              showConfirmationModalForDisableSku={
                showConfirmationModalForDisableSku
              }
            />
          );
        })}
        <div align="right">
          <Pagination
            initialPage={currentPage}
            pageCount={pageCount}
            onPageChange={onPageChange}
          />
        </div>
      </>
    );
  }

  return (
    <div
      className={`${mappingManagementStyles.mapping_manage_container} container-fluid`}>
      <Breadcrumb separator="/">
        {mappingManagementItems.map(({ to, label }, index) =>
          index < mappingManagementItems.length - 1 ? (
            <Link key={to} to={to} style={{ paddingLeft: "0px" }}>
              {label}
            </Link>
          ) : (
            <span key={index}>{label}</span>
          )
        )}
      </Breadcrumb>
      <h1>Mapping Management</h1>
      <div>
        <FlexBox middle>
          <h2>Competitors</h2>
          <h3
            style={{
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "30px",
            }}>
            Tracked by other&#39;s
          </h3>
          <Toggle on={isToggleChecked} onClick={onToggle}></Toggle>
        </FlexBox>
      </div>
      <MappingSearch
        accountId={id}
        onFilter={onFilter}
        qCompetitor={competitor ? decodeURIComponent(competitor) : undefined}
        qCategory={category ? decodeURIComponent(category) : undefined}
        qCountry={country}
        qIsShared={isToggleChecked}
        showAlert={showAlert}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="white-template">{body}</div>
      <SnackBarAlert snackbar={snackbar} closeAlert={closeAlert} />
      <ConfirmationModal
        onNonPrimaryButtonClickAction={closeConfirmationModal}
        show={confirmationModal.show}
        title={confirmationModal.title}
        nonPrimaryButton={confirmationModal.nonPrimaryButton}
        primaryButton={confirmationModal.primaryButton}
        description={confirmationModal.description}
        onPrimaryButtonClickAction={
          confirmationModal.onPrimaryButtonClickAction
        }
        bsStyle={confirmationModal.bsStyle}
      />
      {showSpinner && <Spinner fullPage />}
    </div>
  );
};

export default MappingManagement;
