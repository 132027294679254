// Function to return message according to the variable passed to it.
export const getDisplayMessage = ({ customMessage, error }) => {
  if(customMessage && !error) {
    return customMessage;
  } else if(!customMessage) {
    return getStandardErrorMessage(error); 
  } else {
    return customMessage + getStandardErrorMessage(error);
  }
};

// Function to return description of the error based on the status code of the error
export const getStandardErrorMessage = (error = {}) => {
  switch (error.status) {
    case 400:
      return 'There is a problem with the page. Please consult the administrators of the system.';
    case 401:
      return 'The server indicates an authentication problem. Please logout and login again, and if the problem persists contact the administrators of the system.';
    case 403:
      return 'The server indicates an authorization problem. Please check if you have required permissions.';
    case 404:
      return 'Not found.';
    case 500:
      return 'The service failed to process the request. Please try again in a few seconds. If the problem persists, please consult the administrators of the system.';
    default:
      return 'An unexpected error occurred. Please try again in a few seconds. If the problem persists, please consult the administrators of the system.';
  }
};