import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Select,
  shapes,
  Toggle,
  Modal,
  Tooltip,
} from "@cimpress/react-components";
import _ from "underscore";
import IconPencilAlt from "@cimpress-technology/react-streamline-icons/lib/IconPencilAlt";

import AddCurrency from "./AddCurrency";
import AddVat from "./AddVat";
import AddSkuModal from "../AddSkuModal";
import MappingFailedReasonModal from "../../modals/MappingFailedReasonModal";
import Breadcrumb from "../../breadcrumb/Breadcrumb";
import { questionnaireItems } from "../../breadcrumb/breadcrumbElements";
import { createSubmitData } from "../util";
import { routeNames, snackBarStatus } from "../../../constants/general";
import { validateFile } from "../../../services/scrapingFileService";
import { saveFinalSubmitData } from "../../../services/submit";
import { StandardColumnName, NO_CURRENCY, StandardColumnType } from "../../../utils/constants";
import { getScrapingtoolconfigurations } from "../../../services/metaData";
import {
  mandatoryColumns,
  validationFields,
  fetchQuestionnaireData,
  saveQuestionnaire,
} from "../../../utils/questionnaire";
import {
  toggleVisibilityOnElement,
  checkAllColumnSelected,
  checkColumnAlreadySelected,
  convertToTitleCase,
  getColumnData,
} from "../../../utils/utils";
import questionnaireStyles from "./Questionnaire.module.css";
import ConfirmationModal from "../../ConfirmationModal";
import SnackBarAlert from "../../SnackBarAlert";

const h4 = {
  fontSize: "15px",
  fontWeight: "500",
};

const Questionnaire = (props) => {
  const { getAccessToken } = props;
  const { id, fileId } = useParams();
  const history = useHistory();

  const [data, setData] = useState({});
  const [saveScrapeData, setSaveScrapeData] = useState({});
  const [open, setModel] = useState(false);
  const [openVatModel, setVatModel] = useState(false);
  const [showMappingFailedModal, setShowMappingFailedModal] = useState();
  const [mappingFailedReasonDetail, setMappingFailedReasonDetail] =
    useState(undefined);
  const [showAddSkuModal, setShowAddSkuModal] = useState(false);
  const [snackbarDetails, setSnackbarDetails] = useState({
    show: false,
    status: undefined,
    delay: 2000,
    message: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSpinner, setSpinner] = useState(true);
  const [standardColumnData, setStandardColumnData] = useState(undefined);
  const fetchData = async () => {
    setSpinner(true);

    try {

      const dataFromScrapingToolConfiguration = await getStandardColumnDataForAccount(id);
      setData(dataFromScrapingToolConfiguration);
      setStandardColumnData(dataFromScrapingToolConfiguration.standardColumnData);

      const scrapedData = await fetchQuestionnaireData(
        id,
        fileId,
        getAccessToken,
        dataFromScrapingToolConfiguration
      );
      setSaveScrapeData(scrapedData);
      setData(scrapedData);
    } catch (err) {
      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.danger,
        message: err.message,
        error: err,
      });
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      fetchData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fileId, getAccessToken]);

  const openModal = () => {
    setModel(true);
  };

  const closeModal = () => {
    if (_.isEmpty(data.countryCurrencyMapping)) {
      setData((prevState) => {
        return {
          ...prevState,
          questionnaireData: {...prevState.questionnaireData, [StandardColumnName.CURRENCY]:""},
          countryCurrencyMapping: []
        };
      });
    }
    setModel(false);
  };

  const openVatModal = () => {
    setVatModel(true);
  };

  const closeVatModal = () => {
    if (_.isEmpty(data.countryVatMapping)) {
      setData((prevState) => {
        return {
          ...prevState,
          questionnaireData:{...prevState.questionnaireData, [StandardColumnName.ISSTANDARDTAX]: true},
          countryVatMapping: []
        }
      });
    }
    setVatModel(false);
  };

  const toggleAddSkuModal = () => {
    setShowAddSkuModal((prevState) => !prevState);
  };

  const hideSnackbar = () => {
    invokeSnackBarHandler({ ...snackbarDetails, show: false });
  };

  const invokeSnackBarHandler = (snackBarDetails) => {
    setSnackbarDetails(snackBarDetails);
  };

  const updateCurrency = (countryCurrencyMapping) => {
    if (_.isEmpty(countryCurrencyMapping)) {
      setData((prevstate)=> {
        return{
          ...prevstate,
          questionnaireData: {...prevstate.questionnaireData, [StandardColumnName.CURRENCY]: ""},
          countryCurrencyMapping: countryCurrencyMapping,
        }
      });
    } else {
      setData((prevState) => ({
        ...prevState,
        countryCurrencyMapping: countryCurrencyMapping,
      }));
    }
    setModel(false);
  };

  const updateVat = (countryVatMapping) => {
    if (_.isEmpty(countryVatMapping)) {
      setData((prevstate) => {
        return {
          ...prevstate,
          questionnaireData: {...prevstate.questionnaireData, [StandardColumnName.ISSTANDARDTAX]: true},
          countryVatMapping: countryVatMapping,
        }
      });
    } else {
      setData({ ...data, countryVatMapping: countryVatMapping });
    }

    setVatModel(false);
  };

  const setCountryDataInState = (selectedValue) => {
    let countryData = [];
    countryData = getColumnData(selectedValue, data.scrapedData);
    if (!_.isEmpty(countryData)) {
      countryData = countryData.attributeValue;
    }
    setData((prevState) => {
      return {
        ...prevState,
        countryData: countryData,
        questionnaireData: {...prevState.questionnaireData, [StandardColumnName.COUNTRY]: selectedValue},
      };
    });
  };

  const onSelectionChange = (keyName, e) => {
    let selectedValue = "";
    if (e) {
      selectedValue = e.value;
    }
    const column = checkColumnAlreadySelected(
      validationFields,
      keyName,
      selectedValue,
      data
    );
    if (column) {
      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.danger,
        message: `This column is already selected for ${convertToTitleCase(
          column[0]
        )}`,
        delay: 2000,
      });
      return;
    }
    if (
      data.questionnaireData[StandardColumnName.COUNTRY] === "" &&
      selectedValue === NO_CURRENCY
    ) {
      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.danger,
        message: "Select a country column.",
        delay: 2000,
      });
      return;
    }
    if (keyName === StandardColumnName.COUNTRY) {
      setCountryDataInState(selectedValue);
    } else {
      setData((prevState) => {
        return { ...prevState, questionnaireData: { ...prevState.questionnaireData, [keyName]: selectedValue } };
      });
    }
    if (
      keyName === StandardColumnName.CURRENCY &&
      data.questionnaireData[StandardColumnName.CURRENCY] === NO_CURRENCY &&
      selectedValue !== NO_CURRENCY
    ) {
      setData((prevState) => {
        return { ...prevState, countryCurrencyMapping: [] };
      });
    }
    if (selectedValue === NO_CURRENCY) {
      setModel(true);
    }
  };

  const toggle = (name) => {
    if (
      data.questionnaireData[StandardColumnName.COUNTRY] === "" &&
      name === StandardColumnName.ISSTANDARDTAX
    ) {
      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.danger,
        message: "Select country column.",
        delay: 2000,
      });
      return;
    }
    if (name === StandardColumnName.PRICEINCLUSIVESHIPPING &&  data.questionnaireData[name]) {
      setData((prevState) => {
        return {
          ...prevState,
          [StandardColumnName.SHIPPINGPRICE]: "",
          questionnaireData: {...prevState.questionnaireData, [name]: !data.questionnaireData[name]}
        };
      });
    } else {
      setData((prevState) => {
        return { ...prevState,
                 questionnaireData: {...prevState.questionnaireData, [name]: ! data.questionnaireData[name]}
               };
      });
    }

    if (name === StandardColumnName.ISSTANDARDTAX && ! data.questionnaireData[name] === true) {
      setData((prevState) => {
        return { ...prevState, countryVatMapping: [] };
      });
    }
    if (name === StandardColumnName.ISSTANDARDTAX && ! data.questionnaireData[name] === false) {
      setVatModel(true);
    }
  };

  const onCancelButtonClick = () => {
    history.push(
      `/${routeNames.MAPPING_MANAGEMENT}/${id}/${encodeURI(
        data.scrapedFileDetail.competitor
      )}/${data.scrapedFileDetail.countryCode}/${encodeURI(
        data.scrapedFileDetail.category
      )}`
    );
  };

  const onSaveButtonClick = async () => {
    if (
      saveScrapeData &&
      _.isEmpty(saveScrapeData[StandardColumnName.COUNTRY])
    ) {
      await onSaveQuestionnaire();
      return;
    }
    setShowConfirmation(true);
  };

  const onClickConfirmation = async () => {
    setShowConfirmation(false);
    await onSaveQuestionnaire();
  };

  const onSaveQuestionnaire = async () => {
    try {
      setSpinner(true);

      await saveQuestionnaire(data, standardColumnData, getAccessToken);

      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.success,
        message: "File mapping is successfully saved.",
        delay: 2000,
      });

      const validateFileResponse = await validateFile(
        getAccessToken,
        data.scrapedFileDetail.id
      );

      if (validateFileResponse && validateFileResponse.hasError) {
        setMappingFailedReasonModalVisibility(true, validateFileResponse);

        return;
      }

      if (
        data.scrapedFileDetail.references &&
        data.scrapedFileDetail.references.length <= 0
      ) {
        setShowAddSkuModal(true);
      }
    } catch (error) {
      invokeSnackBarHandler({
        show: true,
        status: snackBarStatus.danger,
        message: error.message,
        error: error,
      });
    } finally {
      setSpinner(false);
    }
  };

  const setMappingFailedReasonModalVisibility = (
    show,
    mappingFailedReasonData
  ) => {
    setShowMappingFailedModal(show);
    setMappingFailedReasonDetail(mappingFailedReasonData);
  };

  const onSaveSku = async (newSKU) => {
    setSpinner(true);
    try {
      const finalSubmitData = await createSubmitData(
        data.scrapedFileDetail,
        newSKU,
        getAccessToken,
        id
      );
      let saveResponse = await saveFinalSubmitData(
        finalSubmitData,
        getAccessToken
      );

      history.push(
        `/${routeNames.MAPPING_MANAGEMENT}/${id}/${routeNames.VIEW_MAPPING}/${data.scrapedFileDetail.competitor}/${saveResponse.scrapedFileDetailId}/${saveResponse.modelMappingId}/${data.scrapedFileDetail.countryCode}/${data.scrapedFileDetail.category}`
      );
    } catch (error) {
      const snackBarDetails = {
        message: `Error while adding SKU ${newSKU.productId}`,
        status: snackBarStatus.danger,
        show: true,
        error: error,
      };

      invokeSnackBarHandler(snackBarDetails);
      setSpinner(false);
    }
  };

  const FormTitle = ({ title, tooltip }) => (
    <div style={{ display: "flex", marginTop: "5px" }}>
      <h4 style={h4}>{title}</h4>
      <div style={{ marginTop: "5px", marginLeft: "10px" }}>
        {tooltip && (
          <Tooltip direction="top" contents={tooltip}>
            <i className="fa fa-question-circle" {...{ "aria-hidden": true }} />
          </Tooltip>
        )}
      </div>
    </div>
  );

  const { Spinner } = shapes;

  const breadCrumbList = data.scrapedFileDetail
    ? questionnaireItems(
        id,
        data.scrapedFileDetail.competitor,
        data.scrapedFileDetail.category,
        data.scrapedFileDetail.countryCode
      )
    : questionnaireItems(id);

    const getStandardColumnDataForAccount = async (accountId) => {
      const scraingToolConfiguration = await getScrapingtoolconfigurations();
      const filteredConfigurations = scraingToolConfiguration.filter(data => data.accountId === accountId);
      const data = {
        countryData: [],
        fileHeaderOptions: [],
        countryCurrencyMapping: [],
        countryVatMapping: [],
        standardColumnData: filteredConfigurations?.[0]?.standardColumnData ?? [],
        questionnaireData:{}
      };
    
      for (const standardColumnData of filteredConfigurations?.[0]?.standardColumnData ?? []) {
        data.questionnaireData[standardColumnData.standardColumnName] = "";
    
        if (standardColumnData.standardColumnType === StandardColumnType.PRICE && standardColumnData.shouldcalculatepriceinclusivity) {
            const includesShippingName = `Includes${standardColumnData.standardColumnName}Shipping`;
            const includesTaxName = `Includes${standardColumnData.standardColumnName}Tax`;
    
            data.questionnaireData[includesShippingName] = false;
            data.questionnaireData[includesTaxName] = true;
        }
    }    
    
      data.questionnaireData.isStandardTax = true;
    
      return data;
    };
    
    

  return (
    <Fragment>
      <div
        className={`${questionnaireStyles.questionnaire_breadcrumb}`}
        style={{ paddingLeft: "15px" }}
      >
        <Breadcrumb separator="/">
          {breadCrumbList.map(({ to, label }, index) =>
            index < breadCrumbList.length - 1 ? (
              <Link key={to} to={to}>
                {label}
              </Link>
            ) : (
              <span key={to}>{label}</span>
            )
          )}
        </Breadcrumb>
      </div>
      <div className={`${questionnaireStyles.questionnaire_header} container`}>
        <div className="row">
          <h2>File Mapping</h2>
        </div>
        {data && data.scrapedFileDetail && data.scrapedFileDetail.fileName && (
          <div className="row">
            <h5>File Name: {data.scrapedFileDetail.fileName}</h5>
          </div>
        )}
      </div>
      {!showSpinner &&<div className={`${questionnaireStyles.questionnaire_body} container`}>
        <div className="content-wrapper">
          {/* display standard column questions. */}
          {standardColumnData &&
            standardColumnData.filter(eachData => eachData.isDerivedFrom === null).map((each, index) => (
              <div key={index}>
                <div className="row">
                  <div className={"col-md-6"}>
                    <FormTitle
                      title={each.isMandatory ? `Which Column is ${each.displayName}` : `Which Column is ${each.displayName}(optional)`}
                      tooltip={each.description}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <Select
                      required={each.isMandatory}
                      isClearable = {!each.isMandatory}
                      label={`Select the column that represents the ${each.displayName}`}
                      value={
                        each.standardColumnName ===
                        StandardColumnName.CURRENCY
                        ? data.fileHeaderOptions.concat([
                          {
                            label: "No Currency column in the file",
                            value: "NoCurrency",
                          },
                        ]).find(
                          (eachOption) =>
                          data.questionnaireData[each.standardColumnName] === eachOption.value
                        ) ?? null :
                        data.fileHeaderOptions.find(
                          (eachOption) =>
                          data.questionnaireData[each.standardColumnName] === eachOption.value
                        ) ?? null
                      }
                      options={
                        each.standardColumnName ===
                        StandardColumnName.CURRENCY
                          ? data.fileHeaderOptions.concat([
                              {
                                label: "No Currency column in the file",
                                value: "NoCurrency",
                              },
                            ])
                          : data.fileHeaderOptions
                      }
                      onChange={(e) =>
                        onSelectionChange(
                          each.standardColumnName,
                          e
                        )
                      }
                      tether
                    />
                  </div>
                  { data.questionnaireData[each.standardColumnName] === NO_CURRENCY && (
                    <div className="col-md-1">
                      <div style={{ cursor: "pointer" }} onClick={openModal}>
                        <IconPencilAlt size="2x" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          {/* display price inclusive of indirect tax questions. */}
          {standardColumnData &&
            standardColumnData
              .filter((eachData) => eachData.standardColumnType === StandardColumnType.PRICE && eachData.isDerivedFrom === null && eachData.shouldcalculatepriceinclusivity)
              .map((each, index) => (
                <div key={index}>
                  <div
                    style={
                      !each.isMandatory
                        ? toggleVisibilityOnElement( data.questionnaireData[each.standardColumnName])
                        : {}
                    }
                  >
                    <div className="row" >
                      <div className="col-md-12">
                        <FormTitle
                          title={`Does the ${each.displayName} include indirect tax?`}
                          tooltip={
                            "Is the competitor price is after Sales Tax/VAT/GST?."
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Toggle
                          on={ data.questionnaireData[`Includes${each.standardColumnName}Tax`]}
                          onText="Yes"
                          offText="No"
                          onClick={() =>
                            toggle(`Includes${each.standardColumnName}Tax`)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          {/* display standard tax includes questions. */}
          {<div>
            <div className="row">
              <div className="col-md-12">
                <FormTitle
                  title={
                    "To compare with MCP product, the prices need to be without taxes. Does standard tax apply?"
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <Toggle
                  on={ data.questionnaireData[StandardColumnName.ISSTANDARDTAX]}
                  onText="Yes"
                  offText="No"
                  onClick={() => toggle(StandardColumnName.ISSTANDARDTAX)}
                />
              </div>
              {! data.questionnaireData[StandardColumnName.ISSTANDARDTAX] && (
                <div className="col-md-1">
                  <div style={{ cursor: "pointer" }} onClick={openVatModal}>
                    <IconPencilAlt size="2x" />
                  </div>
                </div>
              )}
            </div>
          </div>}
          {/* display shipping includes questions. */}
          {standardColumnData &&
            standardColumnData
              .filter((eachData) => eachData.standardColumnType === StandardColumnType.PRICE && eachData.isDerivedFrom !== null)
              .map((each, index) => (
                <div key = {index} >
                  <div
                    style={
                      each.standardColumnName !== StandardColumnName.SHIPPINGPRICE?
                        toggleVisibilityOnElement( data.questionnaireData[each.isDerivedFrom]) :
                        {}}
                  >
                     <div className="row">
                    <div className="col-md-12">
                      <FormTitle
                        title={`Does the ${each.isDerivedFrom} includes shipping?`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Toggle
                        on={ data.questionnaireData[`Includes${each.isDerivedFrom}Shipping`]}
                        onText="Yes"
                        offText="No"
                        onClick={() =>
                          toggle(`Includes${each.isDerivedFrom}Shipping`)
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={toggleVisibilityOnElement(
                      !data.questionnaireData[`Includes${each.isDerivedFrom}Shipping`]
                    )}
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <FormTitle
                          title={`Which column represents ${each.displayName}? (optional)`}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <Select
                          isClearable
                          label={`Select the column which represents ${each.displayName}`}
                          value={
                            data.fileHeaderOptions.find(
                              (eachOption) =>
                              data.questionnaireData[each.standardColumnName] ===
                                eachOption.value
                            ) ?? null
                          }
                          options={data.fileHeaderOptions}
                          data-Key={`Shipping ${each.isDerivedFrom}`}
                          onChange={(e) =>
                            onSelectionChange(
                              each.standardColumnName,
                              e
                            )
                          }
                          tether
                        />
                      </div>
                    </div>
                  </div>
                  </div>
                  {
                    each.shouldcalculatepriceinclusivity && 
                    <div key={index}>
                  <div
                    style={toggleVisibilityOnElement( data.questionnaireData[each.standardColumnName])}
                  >
                    <div className="row" >
                      <div className="col-md-12">
                        <FormTitle
                          title={`Does the ${each.displayName} include indirect tax?`}
                          tooltip={
                            "Is the competitor price is after Sales Tax/VAT/GST?."
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Toggle
                          on={ data.questionnaireData[`Includes${each.standardColumnName}Tax`]}
                          onText="Yes"
                          offText="No"
                          onClick={() =>
                            toggle(`Includes${each.standardColumnName}Tax`)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                  }
                  
                </div>
              ))}
              <div className="row">
            <div className="col-md-1">
              <button className="btn btn-default" onClick={onCancelButtonClick}>
                Cancel
              </button>
            </div>
            <div className="col-md-1">
              <button
                className="btn btn-primary"
                disabled={
                  checkAllColumnSelected(mandatoryColumns, data) ||
                  id !== data.scrapedFileDetail.accountId
                }
                onClick={onSaveButtonClick}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>}
      {open && (
        <Modal
          className="foobar"
          show={open}
          onRequestHide={closeModal}
          title="Enter Currency"
          closeButton={true}
        >
          <AddCurrency
            countryData={data.countryData}
            countryCurrencyMapping={data.countryCurrencyMapping}
            updateCurrency={updateCurrency}
            closeModal={closeModal}
          />
        </Modal>
      )}
      {openVatModel && (
        <Modal
          className="foobar"
          show={openVatModel}
          onRequestHide={closeVatModal}
          title="Enter Vat"
          closeButton={true}
        >
          <AddVat
            countryData={data.countryData}
            countryVatMapping={data.countryVatMapping}
            updateVat={updateVat}
            closeVatModal={closeVatModal}
          />
        </Modal>
      )}
      {showAddSkuModal && (
        <AddSkuModal
          showAddSkuModal={showAddSkuModal}
          toggleAddSkuModal={toggleAddSkuModal}
          currScrapedFile={data.scrapedFileDetail}
          onSaveSkus={onSaveSku}
          isLoading={showSpinner}
          maxSkus={1}
        />
      )}
      {showSpinner && <Spinner fullPage />}
      <SnackBarAlert snackbar={snackbarDetails} closeAlert={hideSnackbar} />
      <ConfirmationModal
        show={showConfirmation}
        title="Update Questionnaire"
        description="Updating the questionnaire will delete the mapping(s) for this file. Do you want to continue?"
        nonPrimaryButton="Cancel"
        primaryButton="Confirm"
        onNonPrimaryButtonClickAction={() => setShowConfirmation(false)}
        onPrimaryButtonClickAction={onClickConfirmation}
      />
      {showMappingFailedModal && (
        <MappingFailedReasonModal
          description={`SKU cannot be added for the file due to following reasons.`}
          showModal={showMappingFailedModal}
          setModalVisibility={setMappingFailedReasonModalVisibility}
          mappingFailedReason={mappingFailedReasonDetail}
        />
      )}
    </Fragment>
  );
};

export default Questionnaire;
