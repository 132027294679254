import React, { Fragment, useState, useEffect } from "react";
import { TextField } from "@cimpress/react-components";

import { StandardColumnName } from "../../../utils/constants";
import { areKeysSame, CheckIfEmpty } from "../../../utils/utils";

export default function AddCurrency(props) {
  const { countryData, countryCurrencyMapping, updateCurrency, closeModal } =
    props;
  const [countryDetails, setCountryDetails] = useState({ countries: [] });

  useEffect(() => {
    const countryArr = [];
    countryData.map((data) => {
      const currency = countryCurrencyMapping.find(
        (each) => each.countryCode === data
      );

      return countryArr.push({
        CountryCode: data,
        Currency: currency ? currency.currency : "",
      });
    });
    setCountryDetails({ countries: countryArr });
  }, [countryData, countryCurrencyMapping]);

  function onInputChange(e) {
    const countryCode = e.target.getAttribute("data-key");
    const index = countryDetails.countries.findIndex((x) =>
      areKeysSame(x.CountryCode, countryCode)
    );
    const countries = [...countryDetails.countries];
    const country = { ...countries[index] };
    country.Currency = e.target.value;
    countries[index] = country;
    setCountryDetails({ countries });
  }

  function createPopupData() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <TextField readOnly value={"Country"} />
          </div>
          <div className="col-md-6">
            <TextField readOnly value={"Currency"} />
          </div>
        </div>
        {countryDetails.countries.map((country) => {
          return (
            <div className="row" key={country.CountryCode}>
              <div className="col-md-6">
                <TextField
                  readOnly
                  label="Country"
                  value={country.CountryCode}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  required
                  onChange={onInputChange}
                  label="Currency"
                  data-key={country.CountryCode}
                  value={country.Currency}
                />
              </div>
            </div>
          );
        })}
        <div>
          <button
            className="btn btn-default"
            onClick={(data) => updateCurrency(countryDetails.countries)}
            disabled={CheckIfEmpty(
              StandardColumnName.CURRENCY,
              countryDetails.countries
            )}
          >
            Save & Close
          </button>{" "}
          &nbsp;&nbsp;
          <button className="btn btn-default" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  }

  return <Fragment>{createPopupData()}</Fragment>;
}
