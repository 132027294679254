import Header from "@cimpress-technology/react-platform-header";
import React from "react";
import { Link } from "react-router-dom";
import Loudspeakers from "./components/Loudspeakers";

import auth from "./authentication";
import { Fragment } from "react";

import AccountSelection from './components/AccountSelection';
import { withRouter } from 'react-router-dom';
import { routeNames } from './constants/general';

class AppHeader extends React.Component {
  state = {
    isLoggedIn: false,
    showAccountSelection: true,
  };

  componentDidMount() {
    const isLoggedIn = auth.isLoggedIn();
    const {pathname} = this.props.match.params;
    this.setState({ isLoggedIn });

    if( pathname === routeNames.NOT_FOUND){
        this.setState({ showAccountSelection: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {pathname} = this.props.match.params;
    if(prevState.showAccountSelection === true && pathname === routeNames.NOT_FOUND){
      this.setState({ showAccountSelection: false });
    }
    else if(prevState.showAccountSelection === false && pathname !== routeNames.NOT_FOUND){
      this.setState({ showAccountSelection: true });
    }
  }

  login = () => {
    auth.login("/").then(() => this.setState({ isLoggedIn: true }));
  };

  logout = () => {
    // auth.login returns a promise, but auth.logout does not. Given that the part that removes the token is synchronous, it's safe to setState after calling logout here.
    auth.logout("/");
    this.setState({ isLoggedIn: false });
  };

  links = {
    id: 'account-selection',
    title: 'Account Selection',
    content:
    <div style={{width: '300px', float: 'right', paddingRight: '20px'}}><AccountSelection accessToken={this.props.accessToken} isProduction={this.props.isProduction} /></div>
  };

  render() {
    const { isLoggedIn, showAccountSelection } = this.state;
    const { isProduction } = this.props;
    const header = (
      <Fragment>
        <Loudspeakers
          accessToken={auth.getAccessToken()}
          isProduction={isProduction}
        />
        <Header
          accessToken={auth.getAccessToken()}
          appTitle={
            <Link to={"/"}>
              <h2>Competitor Price Tracking</h2>
            </Link>
          }
          appLinks={showAccountSelection ? [this.links] : undefined}
          isLoggedIn={isLoggedIn}
          profile={auth.getProfile()}
          onLogInClicked={this.login}
          onLogOutClicked={this.logout}
          documentationUrl="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/15513093000"
        />
      </Fragment>
    );

    return header;
  }
}

export default withRouter(AppHeader);
