import React from "react";
import { CssLoader, shapes } from "@cimpress/react-components";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "./App.css";
import "./components/breadcrumb/breadcrumb.css";
import Subscriptions from "./components/Subscriptions";
import Header from "./LayoutHeader";
import Competitors from "./components/Competitors";
import ScrapingJobs from "./components/ScrapingJobs";
import Workflow from "./components/Workflow";
import { getAccessToken } from "./utils/accessToken";
import auth from "./authentication";
import Home from "./Home";
import { routeNames } from "./constants/general";
import Mapping from "./components/viewMapping/Mapping";
import FullStory from "./components/FullStory";
import NotFound from "./components/NotFound";
import MappingManagement from "./components/mappingManagement/MappingManagement";
import Questionnaire from "./components/mappingManagement/questionnaire/Questionnaire";
import NewRelic from "./components/newRelic/NewRelic";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    authorizing: false,
    authorizationError: null,
    isLoggedIn: false,
    showSpinner: true,
    redirect: false,
  });

  updateSelectedTab = (e, selectedKey) =>
    this.setState({ selectedIndex: selectedKey });
  componentDidMount() {
    if (!auth.isLoggedIn()) {
      this.setState({ authorizing: true });

      auth
        .ensureAuthentication(window.location.pathname + window.location.search)
        .then((authorized) => {
          // `authorized` can be false if the user is not authorized but there was no error.
          this.setState({
            authorizing: !authorized,
            isLoggedIn: true,
            showSpinner: false,
          });
        })
        .catch((err) => {
          this.setState({
            authorizing: false,
            authorizationError: err,
            isLoggedIn: false,
            showSpinner: false,
          });
        });
    } else {
      this.setState({ isLoggedIn: true, showSpinner: false });
    }
  }
  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={`/${routeNames.HOME}`} />;
    }
  };
  render() {
    const { Spinner } = shapes;
    const isProduction =
      process.env.REACT_APP_ENVIRONMENT_NAME === "Production";
    return (
      <>
        <FullStory isProduction={isProduction} />
        <CssLoader>
          <NewRelic
            applicationID={process.env.REACT_APP_NEWRELIC_APPLICATION_ID}
            licenseKey={process.env.REACT_APP_NEWRELIC_LICENSE_KEY}
          />
          <div className="appShell">
            {this.renderRedirect()}
            {this.state.isLoggedIn && (
              <Router>
                <Route path={["/:pathname/:id", "/:pathname", "/"]}>
                  <Header
                    isProduction={isProduction}
                    accessToken={getAccessToken()}
                  />
                </Route>
                <Route exact path="/">
                  <Redirect to={`/${routeNames.HOME}`} />
                </Route>
                <Route
                  exact
                  path={`/${routeNames.MAPPING_MANAGEMENT}/:id/${routeNames.VIEW_MAPPING}/:competitor/:fileId/:modelMappingId/:country/:category`}
                  component={() => <Mapping getAccessToken={getAccessToken} />}
                />
                <Route
                  path={`/${routeNames.NOTIFICATIONS}/:id`}
                  component={(props) => (
                    <Subscriptions
                      {...props}
                      isProduction={isProduction}
                      getAccessToken={getAccessToken}
                    />
                  )}
                ></Route>
                <Route path={`/${routeNames.HOME}/:id`}>
                  <Home />
                </Route>
                <Route
                  path={`/${routeNames.WORKFLOW}/:id`}
                  component={(props) => (
                    <Workflow
                      {...props}
                      isProduction={isProduction}
                      accessToken={getAccessToken()}
                    />
                  )}
                ></Route>
                <Route
                  path={`/${routeNames.COMPETITORS}/:id`}
                  component={(props) => (
                    <Competitors
                      {...props}
                      isProduction={isProduction}
                      accessToken={getAccessToken()}
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={[
                    `/${routeNames.MAPPING_MANAGEMENT}/:id`,
                    `/${routeNames.MAPPING_MANAGEMENT}/:id/:competitor/:country/:category`,
                  ]}
                  component={() => (
                    <MappingManagement accessToken={getAccessToken()} />
                  )}
                ></Route>
                <Route
                  path={`/${routeNames.MAPPING_MANAGEMENT}/:id/${routeNames.QUESTIONNAIRE}/:fileId`}
                  component={(props) => (
                    <Questionnaire {...props} getAccessToken={getAccessToken} />
                  )}
                ></Route>
                <Route
                  path={`/${routeNames.JOBS}/:id`}
                  component={(props) => (
                    <ScrapingJobs
                      {...props}
                      isProduction={isProduction}
                      accessToken={getAccessToken()}
                    />
                  )}
                ></Route>
                <Route
                  path={`/${routeNames.NOT_FOUND}`}
                  component={(props) => (
                    <NotFound
                      {...props}
                      isProduction={isProduction}
                      accessToken={getAccessToken()}
                    />
                  )}
                ></Route>
              </Router>
            )}
            {this.state.showSpinner && <Spinner fullPage />}
          </div>
        </CssLoader>
      </>
    );
  }
}
export default App;
