import axios from "axios";
import { getAccessToken } from "../utils/accessToken";
import { request, httpMethod } from "./request";
import { areKeysSame } from "../utils/utils";
import _ from "underscore";

export const getScrapedFileInfo = async (accountId, fileId, getAccessToken) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/${fileId}/accountid/${accountId}`;
  const response = await request(endpoint, getAccessToken);
  return response;
};

export const getScrapedFiles = async (
  accessToken,
  accountId,
  competitor,
  category,
  country
) => {
  const authorization = { authorization: `Bearer ${accessToken}` };
  const headers = { ...authorization };
  let url = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/${accountId}/${competitor}`;
  if (category) {
    url = `${url}?category=${category}`;
  }
  if (country) {
    url = `${url}&country=${country}`;
  }
  const response = await axios.get(url, {
    method: "GET",
    mode: "cors",
    headers,
  });
  return response.data;
};

export const getScrapedData = async (fileId, getAccessToken) => {
  const responseBody = await request(
    `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/attribute-values/${fileId}/questionnaire`,
    getAccessToken
  );
  return responseBody;
};

export const getQuestionnaireData = async (fileId, getAccessToken) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v2/scrapedfile/getQuestionnaire/${fileId}`;
  return await request(endpoint, getAccessToken);
};

export const saveQuestionnaireData = async (getAccessToken, finalData) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v2/scrapedfile/saveQuestionnaire/${finalData.scrapedFileDetailId}`;
  const dataPayload = JSON.stringify(finalData);
  return await request(endpoint, getAccessToken, dataPayload, httpMethod.PUT);
};

export const getColumnData = (columnName, data) => {
  if (!_.isEmpty(data.scrapedData)) {
    return data.scrapedData.find((x) =>
      areKeysSame(x.attributeKey, columnName)
    );
  } else {
    return [];
  }
};

export const reUploadFile = async (formData, fileId, ignoreMappingValidation) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/reupload/${fileId}?ignoreMappingValidation=${ignoreMappingValidation}`;

  return await request(
    endpoint,
    getAccessToken,
    formData,
    httpMethod.POST,
    null
  );
};

export const validateFile = async (getAccessToken, fileId) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/scrapedfile/validate/${fileId}`;
  return await request(endpoint, getAccessToken);
};
