import { request, httpMethod } from "./request";

export const getInitialPreviewData = () => {
  return {
    loading: true,
    columnHeaders: [
      { Header: " ", accessor: "Header1" },
      { Header: " ", accessor: "Header2" },
      { Header: " ", accessor: "Header3" },
      { Header: " ", accessor: "Header4" },
      { Header: " ", accessor: "Header5" },
      { Header: " ", accessor: "Header6" },
    ],
    rows: [],
  };
};

export const getPreviewData = async (getAccessToken, finalSubmitData) => {
  let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/normalisation/preview`;
  return await request(
    endpoint,
    getAccessToken,
    JSON.stringify(finalSubmitData),
    httpMethod.POST
  );
};

export const getColumnHeadersAndValues = (previewData) => {
  const { columnHeaders, accessor } =
    createColumnHeaderAndAccessor(previewData);
  return { columnHeaders, rows: getColumnValues(previewData, accessor) };
};

const createColumnHeaderAndAccessor = (normalizeData) => {
  const columnHeaders = [];
  const accessor = [];
  for (let row = 0; row < normalizeData.length; row++) {
    for (let col = 0; col < normalizeData[row].length; col++) {
      accessor.push(`header_${col}`);
      columnHeaders.push({
        Header: normalizeData[row][col],
        accessor: `header_${col}`,
      });
    }
    return { columnHeaders, accessor };
  }
};

const getColumnValues = (normalizeData, accessor) => {
  const columnValues = [];
  for (let row = 1; row < normalizeData.length; row++) {
    let rowData = {};
    for (let col = 0; col < normalizeData[row].length; col++) {
      rowData[accessor[col]] = normalizeData[row][col];
    }
    columnValues.push(rowData);
  }
  return columnValues;
};

export const modalBody = {
  width: "90%",
};
