import React, { Component } from "react";
import { Button, Snackbar } from "@cimpress/react-components";
import Selector from "./Selector";
import {
  getDefaultTenantKeys,
  getAttributeValues,
} from "../../services/productAttributeMapping";
import AutoCompleteComponent from "../autocomplete/AutoComplete";
import { tenantValue } from "../../services/mapAttributeService";
import { getDisplayMessage } from "../../services/errorMessages";

export default class DefaultValueSelectionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      bsStyle: undefined,
      defaultTenantKeys: [],
      showFileListingPage: false,
      showPreview: false,
      finalSubmitData: undefined,
      snackbarDetails: {
        show: false,
        bsStyle: undefined,
        delay: 0,
        message: "",
      },
    };
  }

  componentDidMount() {
    var tenantKeys = [];
    const { translationRules, productModelData } = this.props;
    getDefaultTenantKeys(translationRules, productModelData).forEach((item) => {
      tenantKeys.push({
        value: item.value,
        label: item.value,
      });
    });

    this.setState({
      defaultTenantKeys: tenantKeys,
      open: true,
    });
  }

  onTenantKeySelection = (e, index) => {
    var tenantData = [...this.props.defaultTenantData];

    if (
      e !== null &&
      tenantData.find((x) => x.attributeKey === e.value) !== undefined
    ) {
      this.setState({
        snackbarDetails: {
          show: true,
          bsStyle: "danger",
          message: getDisplayMessage({
            customMessage: e.value + " is already selected.",
          }),
        },
      });
      return;
    }

    var data = tenantData[index];
    tenantData[index] = { ...data };
    if (e === null) {
      tenantData[index] = {
        attributeKey: "",
        attributeValues: [],
      };
    } else {
      tenantData[index].attributeKey = e.value;
      tenantData[index].attributeValues = [];

      getAttributeValues(this.props.productModelData, e.value).forEach(
        (item, valueIndex) => {
          tenantData[index].attributeValues.push({
            value: item.value,
            id: valueIndex,
          });
        }
      );
    }
    this.props.onChangeDefaultTenantData(tenantData);
  };

  onTenantValueChange = (e, index) => {
    var tenantData = [...this.props.defaultTenantData];
    this.props.onChangeDefaultTenantData(
      this.setValue(tenantData, index, e.target.value)
    );
  };

  onTenantValueSelection = (item, index) => {
    var tenantData = [...this.props.defaultTenantData];
    this.props.onChangeDefaultTenantData(
      this.setValue(tenantData, index, item.value)
    );
  };

  onAddTenantDefault = () => {
    var tenantData = [...this.props.defaultTenantData];
    tenantData.push({ attributeKey: "", attributeValues: [] });
    this.props.onChangeDefaultTenantData(tenantData);
  };

  setValue(dataList, index, value) {
    var data = dataList[index];
    dataList[index] = { ...data };
    dataList[index].defaultValue = value;
    return dataList;
  }

  onDeleteRow = (e) => {
    const index = e.target.getAttribute("data-key");
    var tenantData = [...this.props.defaultTenantData];
    tenantData.splice(index, 1);
    this.props.onChangeDefaultTenantData(tenantData);
  };

  hideSnackbar = () =>
    this.setState({
      snackbarDetails: {
        show: false,
      },
    });

  render() {
    const {
      snackbarDetails: { show, bsStyle, message, delay },
    } = this.state;
    return (
      <div>
        <Snackbar
          show={show}
          bsStyle={bsStyle}
          delay={delay}
          onHideSnackbar={this.hideSnackbar}
        >
          {message}
        </Snackbar>
        {this.getModalBody(this.props.defaultTenantData)}
      </div>
    );
  }

  getModalBody(defaultTenantData) {
    return (
      <div>
        {defaultTenantData.map((eachDefaultTenantData, index) => {
          return this.getTenantDefaultSection(eachDefaultTenantData, index);
        })}
        <br />
        <Button
          disabled={this.props.disableActions}
          type="default"
          onClick={this.onAddTenantDefault}
        >
          Add more default values
        </Button>
      </div>
    );
  }

  getTenantDefaultSection(eachDefaultTenantData, index) {
    return (
      <div className="row" key={index}>
        <div className="col-md-3">
          <Selector
            disableActions={this.props.disableActions}
            label={"Select tenant attribute key"}
            selectedValue={eachDefaultTenantData.attributeKey}
            options={this.state.defaultTenantKeys}
            onChange={this.onTenantKeySelection}
            index={index}
          />
        </div>
        <div className="col-md-3">
          <AutoCompleteComponent
            key={index}
            value={eachDefaultTenantData.defaultValue}
            items={eachDefaultTenantData.attributeValues}
            index={index}
            onChange={this.onTenantValueChange}
            onSelection={this.onTenantValueSelection}
            attributeType="attributeValue"
            label={tenantValue}
            placeholder={tenantValue}
            required={false}
            disableActions={this.props.disableActions}
          />
        </div>
        <div className="col-md-2">
          <i
            className="fa fa-trash fa-1x"
            aria-hidden="true"
            onClick={this.onDeleteRow}
            data-key={index}
          ></i>
        </div>
      </div>
    );
  }
}
