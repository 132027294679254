import _ from "underscore";
import { areKeysSame } from "./utils";
import { ScrapedKeyState } from "./constants";
import { getActiveRuleIds } from "./common";

export const isAtLeastOneAttributeKeyMapped = (
  translationRules,
  scrapedKeyStates
) => {
  const dicActiveRule = getActiveRuleIds(scrapedKeyStates);
  return translationRules.some((translationRule) => {
    return translationRule.tenantKeys.some(
      (key) => dicActiveRule[translationRule.ruleId] && !_.isEmpty(key)
    );
  });
};

export const isEmptyTenantDataPresent = (selectedData) => {
  return selectedData.tenantKeys.some((tenantKey) => {
    return selectedData.rules.some((data) => {
      var emptyTenantArray = data.tenantAttributes.filter(
        (item) => item.value === "" && item.key.trim() === tenantKey.trim()
      );
      if (_.isEmpty(data.tenantAttributes) || emptyTenantArray.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });
};

export const isAllScrapedValuesMapped = (selectedData) => {
  const { tenantKeys, rules } = selectedData;
  return tenantKeys.some((tenantKey) => {
    return rules.some((rule) => {
      return !rule.tenantAttributes.find(
        (tenantAttribute) =>
          areKeysSame(tenantAttribute.key, tenantKey) &&
          !_.isEmpty(tenantAttribute.value)
      );
    });
  });
};

export const isEmptyScrapeDataPresent = (selectedData) => {
  return selectedData.scrapedKeys.some((scrapedKey) => {
    return selectedData.rules.some((data) => {
      var emptyScrapedArray = data.scrapedAttributes.filter(
        (item) => item.value === "" && item.key.trim() === scrapedKey.trim()
      );
      if (_.isEmpty(data.scrapedAttributes) || emptyScrapedArray.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  });
};

export const isMerged = (selectedData) => {
  return selectedData.scrapedKeys.length > 1;
};

export const isSplit = (selectedData) => {
  return selectedData.tenantKeys.length > 1;
};

export const isScrapeKeyAlreadyMerged = (mappedData, keyName) => {
  if (keyName === "") return false;
  return mappedData.some((x) => {
    return x.scrapedKeys.some(
      (item, idx) => item === keyName && x.id !== mappedData.id && idx > 0
    );
  });
};

export const isTenantKeyAlreadySplit = (mappedData, keyName) => {
  if (keyName === "") return false;
  return mappedData.some((x) => {
    return x.tenantKeys.some(
      (item, idx) => item === keyName && x.id !== mappedData.id
    );
  });
};

export const isTenantKeyAlreadyMapped = (
  translationRules,
  keyName,
  tenantKeyIndex,
  translationRule
) => {
  if (_.isEmpty(keyName)) return false;

  if (
    translationRule.tenantKeys.some(
      (key, index) => index !== tenantKeyIndex && areKeysSame(key, keyName)
    )
  ) {
    return true;
  }

  return translationRules.some((x) => {
    return x.tenantKeys.some(
      (key) => x.ruleId !== translationRule.ruleId && areKeysSame(key, keyName)
    );
  });
};

export const isTenantKeyMappedInDefaultSelection = (
  defaultTenantKeys,
  keyName
) => {
  return defaultTenantKeys.some((key) =>
    areKeysSame(key.attributeKey, keyName)
  );
};

export const isTenantKeysEmpty = (translationRules, scrapedKeyStates) => {
  let emptyTenantKeys = [];
  translationRules.forEach((data) => {
    if (
      data.tenantKeys.includes("") &&
      isScrapedKeyActive(data.ruleId, scrapedKeyStates)
    ) {
      emptyTenantKeys.push(data.ruleId);
    }
  });
  return emptyTenantKeys;
};

export const isScrapedKeysEmpty = (scrapedKeyStates) => {
  let emptyScrapedData = [];
  scrapedKeyStates.forEach((data) => {
    if (data.scrapedKeys.includes("")) {
      emptyScrapedData.push(data.ruleId);
    }
  });
  return emptyScrapedData;
};

export const isEmptyTenantValue = (translationRules, scrapedKeyStates) => {
  let emptyTenantValue = [];

  translationRules.forEach((translationRule) => {
    let ruleId = translationRule.ruleId;
    const { tenantKeys } = translationRule;
    let hasEmptyData = translationRule.tenantAttributes.some(
      (tenantAttribute) => {
        return (
          isScrapedKeyActive(ruleId, scrapedKeyStates) &&
          isDataEmpty(tenantKeys, tenantAttribute.data)
        );
      }
    );
    if (hasEmptyData) {
      emptyTenantValue.push(ruleId);
    }
  });
  return emptyTenantValue;
};

export const isDataEmpty = (tenantKeys, data) => {
  if (data.length === 0) return true;
  return !tenantKeys.every((x) =>
    data.some((y) => areKeysSame(y.key, x, true) && !_.isEmpty(y.value))
  );
};

export const isScrapedKeyActive = (ruleId, scrapedKeyStates) => {
  let key = scrapedKeyStates.find((x) => x.ruleId === ruleId);
  return key.isActive && key.mappingState !== ScrapedKeyState.DELETED;
};

export const areAllMandatoryFieldPopulated = (
  scrapedKeyStates,
  translationRules
) => {
  return !(
    isScrapedKeysEmpty(scrapedKeyStates).length > 0 ||
    isTenantKeysEmpty(translationRules, scrapedKeyStates).length > 0 ||
    isEmptyTenantValue(translationRules, scrapedKeyStates).length > 0
  );
};

export const getErrorMessageIfTenantKeyMappedIndefault = (
  defaultTenantKeys,
  keyName
) => {
  if (isTenantKeyMappedInDefaultSelection(defaultTenantKeys, keyName)) {
    return keyName + " already mapped in default selection screen.";
  }
};

export const getErrorMessageIfScrapeKeyAlreadyMapped = (
  translationRules,
  scrapedKeyStates,
  selectedIndex,
  value
) => {
  if (_.isEmpty(value)) return false;

  if (scrapedKeyStates[selectedIndex].scrapedKeys.includes(value)) {
    return `${value} is already selected`;
  }

  let ruleId = undefined;
  for (let count = 0; count < scrapedKeyStates.length; count++) {
    if (
      scrapedKeyStates[count].mappingState === ScrapedKeyState.DELETED &&
      scrapedKeyStates[selectedIndex].scrapedKeys.includes(value)
    ) {
      return `${value} is DELETED`;
    }
    const nonEmptyScrapeKeys = scrapedKeyStates[count].scrapedKeys.filter(
      (x) => x.length > 0
    );
    if (nonEmptyScrapeKeys.includes(value)) {
      if (nonEmptyScrapeKeys.length > 1) {
        return `${value} is already merged`;
      } else {
        ruleId = scrapedKeyStates[count].ruleId;
        break;
      }
    }
  }
  let isAlreadyMapped = false;
  if (ruleId) {
    const translationRule = translationRules.find((x) => x.ruleId === ruleId);
    translationRule.tenantKeys.forEach((element) => {
      if (element !== "") {
        isAlreadyMapped = true;
        return;
      }
    });
  }
  if (isAlreadyMapped) return `${value} is already mapped.`;
  return isAlreadyMapped;
};

export const getErrorMessageIfTenantKeyAlreadyMapped = (
  translationRules,
  ruleIndex,
  keyName,
  index
) => {
  const translationRule = translationRules[ruleIndex];
  if (
    isTenantKeyAlreadyMapped(translationRules, keyName, index, translationRule)
  ) {
    return `${keyName} is already mapped`;
  } else if (translationRule.tenantKeys.includes(keyName)) {
    return false;
  }
};

export const isSelectedKeyPartiallyMapped = (
  scrapedKeyState,
  translationRule
) => {
  return (
    isScrapedKeyEmpty(scrapedKeyState) ||
    isTenantKeyEmpty(translationRule, scrapedKeyState) ||
    isTenantValueEmpty(translationRule, scrapedKeyState)
  );
};

export const isScrapedKeyEmpty = (scrapedKeyState) => {
  return scrapedKeyState.scrapedKeys.includes("");
};

export const isTenantKeyEmpty = (translationRule, scrapedKeyState) => {
  return (
    translationRule.tenantKeys.includes("") &&
    scrapedKeyState.isActive &&
    scrapedKeyState.mappingState !== ScrapedKeyState.DELETED
  );
};

export const isTenantValueEmpty = (translationRule, scrapedKeyState) => {
  const { tenantKeys } = translationRule;
  return translationRule.tenantAttributes.some((tenantAttribute) => {
    return (
      scrapedKeyState.isActive &&
      scrapedKeyState.mappingState !== ScrapedKeyState.DELETED &&
      isDataEmpty(tenantKeys, tenantAttribute.data)
    );
  });
};
