export const routeNames = {
  HOME: "Home",
  NOTIFICATIONS: "notifications",
  WORKFLOW: "workflow",
  COMPETITORS: "competitors",
  JOBS: "jobs",
  REQUESTS: "requests",
  PRICE_COMPARISION: "PriceComparison",
  CREATE_NEW_MAPPING: "CreateNewMapping",
  MANAGE_MAPPING: "ManageMapping",
  NOT_FOUND: "NotFound",
  MAPPING_MANAGEMENT: "MappingManagement",
  VIEW_MAPPING: "ViewMapping",
  QUESTIONNAIRE: "EditFileMapping",
};

export const MISSINGMANDATORYFIELDS = "MissingMandatoryFields";

export const snackBarStatus = {
  danger: "danger",
  info: "info",
  success: "success",
  warning: "warning",
};
