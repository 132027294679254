import { useEffect } from "react";
import { identify, init } from "@fullstory/browser";
import auth from "../authentication";
import PropTypes from 'prop-types';

const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;

const defaultUserSub = { sub: "", name: "Guest" };
function FullStory({ isProduction }) {
  const isLoggedIn = auth.isLoggedIn();
  const user = isLoggedIn ? auth.getProfile() : defaultUserSub;

  useEffect(() => {
    init({ orgId, devMode: !isProduction });
  }, [isProduction]);

  useEffect(() => {
    if (user.sub != null) {
      identify(user.sub, { displayName: user.name, email: user.email });
    }
  }, [user.sub, user.name, user.email]);
  return null;
}

FullStory.propTypes = {
  isProduction: PropTypes.bool.isRequired,
};

export default FullStory;
