export const getLocalStorage = (key) => {
    var accountDetails = localStorage.getItem(key);
    try {
        return JSON.parse(accountDetails)
    } catch {
        return null
    }
};

export const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key) => {
    localStorage.removeItem(key);
}
