import React, { useState, useEffect } from "react";
import { Modal } from "@cimpress/react-components";
import { isEmpty } from "lodash";

const isMappingFailedReasonPresent = (mappingFailedReason) => {
  if (
    !mappingFailedReason ||
    (mappingFailedReason && Object.keys(mappingFailedReason).length <= 0)
  )
    return false;

  let isReasonPresent = false;

  const {
    newScrapedAttributeKeys,
    newValuesForScrapedAttributes,
    missingMandatoryAttributeKeys,
    message,
  } = mappingFailedReason;
  if (isEmpty(newScrapedAttributeKeys)) {
    isReasonPresent = true;
  }

  if (isEmpty(newValuesForScrapedAttributes)) {
    isReasonPresent = true;
  }

  if (isEmpty(missingMandatoryAttributeKeys)) {
    isReasonPresent = true;
  }

  if (message) {
    isReasonPresent = true;
  }

  return isReasonPresent;
};

const MappingFailedReasonModal = ({
  description,
  showModal,
  setModalVisibility,
  mappingFailedReason,
}) => {
  const [failureReasons, setFailureReasons] = useState(undefined);

  useEffect(() => {
    if (mappingFailedReason) {
      setFailureReasons(mappingFailedReason);
    }
  }, [mappingFailedReason]);

  const display = [];
  const isReasonPresent = isMappingFailedReasonPresent(failureReasons);

  if (isReasonPresent) {
    const {
      newScrapedAttributeKeys,
      newValuesForScrapedAttributes,
      missingMandatoryAttributeKeys,
      message,
      sku,
    } = mappingFailedReason;
    display.push(
      <div>
        <h5>{sku}</h5>
        <ul>
          {newScrapedAttributeKeys && newScrapedAttributeKeys.length ? (
            <>
              <li>
                <h5>Competitor has new attributes.</h5>
              </li>
              <div style={{ marginLeft: "15px" }}>
                {newScrapedAttributeKeys.join(", ")}
              </div>
            </>
          ) : (
            ""
          )}

          {newValuesForScrapedAttributes &&
          newValuesForScrapedAttributes.length ? (
            <>
              <li>
                <h5>Competitor has new values for existing attributes.</h5>
              </li>
              <div style={{ marginLeft: "10px" }}>
                {newValuesForScrapedAttributes.map(
                  ({ attributeKey, attributeValue }) => {
                    return (
                      <div
                        key={attributeKey}
                        className="row"
                        style={{ padding: "3px" }}
                      >
                        <div className="col-md-2">{`${attributeKey} :`}</div>
                        <div className="col-md-10">
                          {attributeValue.join(", ")}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {missingMandatoryAttributeKeys &&
          missingMandatoryAttributeKeys.length ? (
            <>
              <li>
                <h5>Competitor has missing mandatory fields.</h5>
              </li>
              <div style={{ marginLeft: "15px" }}>
                {missingMandatoryAttributeKeys.join(", ")}
              </div>
            </>
          ) : (
            ""
          )}

          {message ? (
            <>
              <li>
                <h5>{message}</h5>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  } else {
    display.push(
      <div>
        <ul>
          <li>
            <h5>
              Mapping failed due to unknown reasons, please contact
              <a href={"mailto:phoenixsquad%40cimpress.com"}>Phoenix Squad</a>
            </h5>
          </li>
        </ul>
      </div>
    );
  }
  return (
    <Modal
      style={{ width: "70%" }}
      show={showModal}
      onRequestHide={() => setModalVisibility(false)}
      title="Mapping failed reasons"
      closeButton={true}
      footer={
        <div style={{ float: "right" }}>
          <button
            className="btn btn-default"
            onClick={() => setModalVisibility(false)}
          >
            Close
          </button>
        </div>
      }
    >
      <>
        <div>
          <h4>
            {description}
          </h4>
        </div>
        <div style={{ marginLeft: "5px" }}>{display}</div>
      </>
    </Modal>
  );
};

export default MappingFailedReasonModal;
