import { Snackbar } from "@cimpress/react-components";
import React, { useState } from "react";
import { FeedbackSticker } from "@cimpress-technology/react-feedback";
import auth from "./authentication";
import { routeNames } from "./constants/general";
import "./App.css";
import { useHistory, useParams } from "react-router-dom";
const Home = () => {
  const buttonWidthStyle = {
    width: "200px",
  };
  const [snackbarDetails, setSnackbarDetails] = useState({
    show: false,
    bsStyle: undefined,
    delay: 0,
    message: "",
  });
  function hideSnackbar() {
    setSnackbarDetails({ show: false });
  }
  const history = useHistory();
  const { id } = useParams();
  return (
    <div className="main">
      <FeedbackSticker
        title="Feedback?"
        accessToken={auth.getAccessToken()}
        campaignId="d3921bc2-3b84-11eb-b77b-06e765523a47"
        questionId="34655066-3b91-11eb-b15d-06e765523a47"
      />
      {snackbarDetails.show && (
        <Snackbar
          show={snackbarDetails.show}
          bsStyle={snackbarDetails.bsStyle}
          delay={snackbarDetails.delay}
          onHideSnackbar={hideSnackbar}
        >
          {snackbarDetails.message}
        </Snackbar>
      )}
      <div className="main__content">
        <div className="container">
          <div className="css-1h9865k">
            <h1>Welcome to Competitor Price Tracking</h1>
            <h2>
              This service help you to configure and manage the auto scraping of
              competitor's data and map it to the MCP product
            </h2>
            <div className="css-x4o302">
              <div className="card card-minimal">
                <div className="card-body">
                  <div className="card-header text-center">
                    <h4>Competitors</h4>
                  </div>
                  <div className="card-block">
                    <ul key="view">
                      <li>Add, delete and review competitors</li>
                    </ul>
                    <div className="text-center">
                      <button
                        style={buttonWidthStyle}
                        type="submit"
                        className="btn btn-default"
                        onClick={(event) =>
                          history.push(`/${routeNames.COMPETITORS}/${id}`)
                        }
                      >
                        Competitors
                      </button>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-minimal" style={{ height: "250px" }}>
                <div className="card-header text-center">
                  <h4>Map Competitor to MCP</h4>
                </div>
                <div className="card-block">
                  <ul>
                    <li>
                      Map the competitor data to MCP product to do price
                      comparison
                    </li>
                  </ul>
                  <div className="text-center">
                    <button
                      style={buttonWidthStyle}
                      type="submit"
                      className="btn btn-default"
                      onClick={() =>
                        history.push(`/${routeNames.MAPPING_MANAGEMENT}/${id}`)
                      }
                    >
                      Mapping Management
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="card card-minimal">
                <div className="card-body">
                  <div className="card-header text-center">
                    <h4>Notifications</h4>
                  </div>
                  <div className="card-block">
                    <ul>
                      <li>Manage notification subscription</li>
                    </ul>
                    <div className="text-center">
                      <button
                        style={{ width: "215px" }}
                        type="submit"
                        className="btn btn-default"
                        onClick={(event) =>
                          history.push(`/${routeNames.NOTIFICATIONS}/${id}`)
                        }
                      >
                        Notification Subscription
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-minimal">
                <div className="card-body">
                  <div className="card-header text-center">
                    <h4>Scraping Jobs</h4>
                  </div>
                  <div className="card-block">
                    <ul>
                      <li>Review and manage scraping jobs</li>
                    </ul>
                    <div className="text-center">
                      <button
                        style={{ width: "215px" }}
                        type="submit"
                        className="btn btn-default"
                        onClick={(event) =>
                          history.push(`/${routeNames.JOBS}/${id}`)
                        }
                      >
                        Scraping Jobs
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-minimal" style={{ height: "250px" }}>
                <div className="card-body">
                  <div className="card-header text-center">
                    <h4>Workflow</h4>
                  </div>
                  <div className="card-block">
                    <ul>
                      <li>Prioritize the workflow using the board</li>
                    </ul>
                    <div className="text-center">
                      <button
                        style={{ width: "215px" }}
                        type="submit"
                        className="btn btn-default"
                        onClick={(event) =>
                          history.push(`/${routeNames.WORKFLOW}/${id}`)
                        }
                      >
                        Workflow Board
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
