export const httpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE:"DELETE"
};

export const contentTypes = {
  text: "text/plain;charset=UTF-8",
  octet_stream: "application/octet-stream",
  csv :"text/csv"  ,
  application_json:"application/json"
};

// Constructs a request from the provided body and authentication information and returns the result.
export const request = async (
  endpoint,
  getAccessToken,
  body,
  method = httpMethod.GET,
  contentType = "application/json",
  isBlob = false
) => {
  const credentials = "same-origin";
  const accessToken = getAccessToken();
  const headers = new Headers({
    Authorization: `Bearer ${accessToken}`
  });
  contentType && headers.append("Content-Type", contentType);
  const requestParams = { credentials, headers, method, body };
  const response = await fetch(endpoint, requestParams);
  if (response.ok) {
    switch (response.status) {
      case 204:
        return;
      default: {
        const contentType = response.headers.get("Content-Type");
        if (contentType === contentTypes.text || contentType === contentTypes.octet_stream || contentType == null || isBlob) {
          return response.blob();
        }
        return await response.json();
      }
    }
  }

  throw response;
};
