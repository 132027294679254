import React, { Component } from "react";
import "./DraggableButton.css";
import * as Constants from "../../utils/constants";
import IconBin from "@cimpress-technology/react-streamline-icons/lib/IconBin";
import { Button, Tooltip } from "@cimpress/react-components";
import { granite, cobalt } from "@cimpress/react-components/lib/colors";
import { generateToolTip } from "../../utils/utils";

class DraggableButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onClickAttributeKey, index } = this.props;
    onClickAttributeKey(index);
    window.scrollTo(0, 0);
  }
  handleDeleteButton() {
    const { onDeleteScrapeKey, mappingState, ruleId } = this.props;
    onDeleteScrapeKey(
      mappingState !== Constants.ScrapedKeyState.DELETED
        ? Constants.ScrapedKeyState.DELETED
        : Constants.ScrapedKeyState.PARTIALLY_MAPPED,
      ruleId
    );
  }
  disableDeleteButton() {
    const { scrapedKeys, translationRules, ruleId } = this.props;
    const translationRule = translationRules.find(each => each.ruleId === ruleId);
    return scrapedKeys.length > 1 || (translationRule && translationRule.tenantKeys.length > 1);
  }
  
  render() {
    const { isSelected, attributeKey, mappingState, disableActions, scrapedKeys, translationRules, ruleId } =
      this.props;
      const translationRule = translationRules.find(each => each.ruleId === ruleId);

    const isRemoveDisabled = this.disableDeleteButton() || disableActions;
    
    const binIconColor = isSelected ? isRemoveDisabled ? granite.base : cobalt.darkest : isRemoveDisabled ? granite.base : "#0088a9"
    
    let icon = null,
      attributeKeyClassName = "col-md-9",
      isIconVisible = true;
    
      switch (mappingState) {
      case Constants.ScrapedKeyState.MAPPED:
        icon = (
          <i
            className="fa fa-check"
            style={{ color: "green" }}
            aria-hidden="true"
          ></i>
        );
        break;
      case Constants.ScrapedKeyState.DELETED:
        icon = (
          <i
            className="fa fa-remove"
            style={{ color: "red" }}
            aria-hidden="true"
          ></i>
        );
        break;
      case Constants.ScrapedKeyState.PARTIALLY_MAPPED:
        icon = (
          <i
            className="fa fa-edit"
            style={{ color: "blue" }}
            aria-hidden="true"
          ></i>
        );
        break;
      default:
        icon = "";
        attributeKeyClassName = "col-md-12";
        isIconVisible = false;
    }

    return (
      <div
        onClick={this.onClick}
        className={`${isSelected ? "draggable item-selected" : "draggable"}`}
      >
        <div className="row" style={{ display: "flex" }}>
          <div className={attributeKeyClassName}>{attributeKey}</div>
          {isIconVisible && (
            <div className="col-md-1" style={{ alignContent: "center" }}>
              {icon}
            </div>
          )}
          {mappingState !== Constants.ScrapedKeyState.DELETED && (
            <Button
              className={
                isRemoveDisabled
                  ? "remove-bin-icon-disabled"
                  : "remove-bin-icon"
              }
              disabled={isRemoveDisabled}
              type="anchor"
              onClick={(e) => {
                e.stopPropagation();
                this.handleDeleteButton();
              }}
            >
              <Tooltip
                direction="bottom"
                contents={
                  this.disableDeleteButton()
                    ? generateToolTip(scrapedKeys, translationRule.tenantKeys)
                    : `${scrapedKeys[0]} will be removed from scraped file`
                }
              >
                <IconBin color={binIconColor} size="lg" />
              </Tooltip>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default DraggableButton;
