import { getAccessToken } from "../utils/accessToken";
import { request } from "./request";
export const maxInt32 = 2147483647;

export const getStandardProductMappingConfig = async (
    accountId
  ) => {
    let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/metadata/sharedscrapeddataconfig/${accountId}`;
    return await request(endpoint, getAccessToken);
  };

  export const getScrapingtoolconfigurations = async () => {
    let endpoint = `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/scrapingtoolconfigurations`;
    return await request(endpoint, getAccessToken);
  };