import React, { Component, Fragment } from "react";
import { shapes, Snackbar } from "@cimpress/react-components";
import AttributeMapper from "./AttributeMapper";
import { getNlpData } from "../../services/nlpService";
import {
  getMappedData,
  getAttributeKeys,
  getCombinationData,
  updateMappingStates,
  updateSelectedKeyMappingState,
  getAttributeValuesNew,
  getPreviousModelMappingDetails,
  getPreviousMappedData,
  getScrapeAndProductModelData,
} from "../../services/productAttributeMapping";
import DefaultValueSelectionScreen from "../defaultValueSelection/DefaultValueSelectionScreen";
import DefaultValueSelection from "../defaultValueSelection/DefaultValueSelection";
import _ from "underscore";
import { getFulfillerDetails } from "../../services/productModel";
import { getDisplayMessage } from "../../services/errorMessages";
import * as Constants from "../../utils/constants";
import { MemoizeScrapedAttributesListView } from "../draggableButton/ScrapedAttributesListView";
import {
  changeTenantValue,
  changeTenantKey,
  deleteTenantKey,
  changeScrapeKey,
  deleteScrapeKey,
  createMergedCombination,
  copyScrapeValues,
  reorder,
  setSelectedKey,
} from "../../services/mapAttributeService";
import {
  getErrorMessageIfScrapeKeyAlreadyMapped,
  getErrorMessageIfTenantKeyMappedIndefault,
  getErrorMessageIfTenantKeyAlreadyMapped,
} from "../../utils/validation";
import MappingActions from "./MappingActions";
import ProductChangesModal from "./ProductChangesModal";
import { AttributesTransformedStatus } from "../../utils/constants";
import styles from "./App.module.css";

const isAttributesTransformed = (value) => {
  if (
    value.mappingStatus !== AttributesTransformedStatus.MATCHED &&
    value.mappingStatus !== AttributesTransformedStatus.CUSTOM
  ) {
    return true;
  }

  if (value.attributeValueMappings?.length > 0) {
    for (
      let indexOfAttributeValueMappings = 0;
      indexOfAttributeValueMappings < value.attributeValueMappings.length;
      indexOfAttributeValueMappings++
    ) {
      if (
        value.attributeValueMappings[indexOfAttributeValueMappings]
          .mappingStatus !== AttributesTransformedStatus.MATCHED &&
        value.attributeValueMappings[indexOfAttributeValueMappings]
          .mappingStatus !== AttributesTransformedStatus.CUSTOM
      ) {
        return true;
      }
    }
  }

  return false;
};

class ProductAttributeMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKeyId: "",
      scrapedKeyStates: [],
      translationRules: [],
      copiedScrapeAttributes: [],
      productModelData: [],
      selectedMerchant: undefined,
      showDefaultValueScreen: false,
      showSpinner: false,
      mcpSku: "",
      snackbarDetails: {
        show: false,
        bsStyle: undefined,
        message: "",
        delay: 0,
      },
      showMappingSection: false,
      defaultTenantData: [{ attributeKey: "", attributeValues: [] }],
      isStandard: false,
      isDraft: true,
      openSkuChangesModal: false,
      attributesTransformed: [],
    };
  }

  populateInitialData() {
    const { selectedFileInfo, getAccessToken, accountId } = this.props;
    const { productModel, modelMappingId, fileId } = selectedFileInfo;
    this.setState({ showSpinner: true });
    getMappedData(
      productModel,
      modelMappingId,
      selectedFileInfo,
      getAccessToken,
      accountId
    )
      .then((response) => {
        if (!response) {
          this.showMappingSectionHandler(fileId, productModel, getAccessToken);
        } else {
          const attributesTransformed = response.attributesTransformed?.filter(
            isAttributesTransformed
          );

          this.setState({
            ...response,
            mcpSku: productModel,
            selectedMerchant: selectedFileInfo.tenant,
            selectedKeyId: response.scrapedKeyStates[0].ruleId,
            showSpinner: false,
            showDefaultValueScreen: false,
            attributesTransformed,
            openSkuChangesModal: attributesTransformed?.length > 0,
          });
        }
      })
      .catch((error) => {
        this.setState({
          snackbarDetails: {
            show: true,
            bsStyle: "danger",
            message: getDisplayMessage({
              customMessage: Constants.Messages.GET_MAPPED_DATA_FAILED,
              error: error,
            }),
          },
          showSpinner: false,
        });
      });
  }

  showMappingSectionHandler = async (fileId, mcpSku, getAccessToken) => {
    try {
      const {
        selectedFileInfo: { competitor, category, countryCode },
        accountId,
      } = this.props;

      let latestModelMappingDetails = await getPreviousModelMappingDetails(
        accountId,
        competitor,
        category,
        countryCode,
        getAccessToken
      );

      if (latestModelMappingDetails.length > 0) {
        let matchedModelMappingDetail = latestModelMappingDetails.find(
          (x) => x.sku === mcpSku
        );
        if (matchedModelMappingDetail) {
          const { modelMappingId } = matchedModelMappingDetail;
          const response = await getPreviousMappedData(
            fileId,
            mcpSku,
            modelMappingId,
            countryCode,
            getAccessToken,
            accountId
          );
          this.setState({
            ...response,
            showSpinner: false,
            showDefaultValueScreen: false,
          });
          return;
        }
      }

      const [mappingData, ruleSet] = await getScrapeAndProductModelData(
        fileId,
        mcpSku,
        countryCode,
        getAccessToken,
        accountId
      );

      if (ruleSet) {
        const { scrapedKeyStates, translationRules } = mappingData;
        this.setState({
          mcpSku: mcpSku,
          mcpSkuVersion: ruleSet.mcpSkuVersion,
          showMappingSection: true,
          productModelData: ruleSet.whiteList,
          selectedKeyId: scrapedKeyStates[0].ruleId,
          scrapedKeyStates: scrapedKeyStates,
          translationRules: translationRules,
          showSpinner: false,
          showDefaultValueScreen: false,
          defaultTenantData: [{ attributeKey: "", attributeValues: [] }],
          isStandard: ruleSet.isStandard ? true : false,
        });
      } else {
        this.setStateOnErrorOfMcpSkuSearch(
          getDisplayMessage({
            customMessage: Constants.Messages.GET_RULE_SET_FAILED,
          })
        );
      }
    } catch (e) {
      this.setStateOnErrorOfMcpSkuSearch(
        getDisplayMessage({
          customMessage: Constants.Messages.GET_MAPPED_DATA_FAILED,
        })
      );
    }
  };

  setStateOnErrorOfMcpSkuSearch(message) {
    this.setState({
      snackbarDetails: {
        show: true,
        bsStyle: "danger",
        message: message,
      },
      showMappingSection: false,
      productModelData: [],
      showSpinner: false,
    });
  }

  componentDidMount() {
    this.populateInitialData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getAccessToken() !== this.props.getAccessToken() ||
      prevProps.selectedFileInfo.fileId !==
        this.props.selectedFileInfo.fileId ||
      prevProps.selectedFileInfo.modelMappingId !==
        this.props.selectedFileInfo.modelMappingId
    ) {
      this.populateInitialData();
    }
  }

  updateMappingModalId = (modelMappingId) => {
    this.setState({ modelMappingId: modelMappingId });
  };

  updateScrapedKeyStates = () => {
    let scrapedKeyStates = [...this.state.scrapedKeyStates];
    let updatedScrapedKeyStates = updateMappingStates(
      this.state.translationRules,
      scrapedKeyStates
    );
    this.setState({ scrapedKeyStates: updatedScrapedKeyStates });
  };

  updateSelectedScrapedKeyState = () => {
    let scrapedKeyStates = [...this.state.scrapedKeyStates];
    let updatedScrapedKeyStates = updateSelectedKeyMappingState(
      this.state.selectedKeyId,
      scrapedKeyStates,
      this.state.translationRules
    );
    this.setState({ scrapedKeyStates: updatedScrapedKeyStates });
  };

  onChangeDefaultTenantData = (updatedDefaultTenantData) => {
    this.setState({ defaultTenantData: updatedDefaultTenantData });
  };

  reorderOnDrag = (startIndex, endIndex) => {
    const scrapedKeyStates = reorder(
      this.state.scrapedKeyStates,
      startIndex,
      endIndex
    );
    this.setState({ scrapedKeyStates: scrapedKeyStates });
  };

  onClickAttributeKey = (index) => {
    const { scrapedKeyStates, selectedKeyId } = this.state;
    const updatedScrapedKeyStates = setSelectedKey(
      scrapedKeyStates,
      index,
      selectedKeyId
    );
    this.setState({
      selectedKeyId: scrapedKeyStates[index].ruleId,
      scrapedKeyStates: updatedScrapedKeyStates,
      showDefaultValueScreen: false,
    });
  };

  getTenantKeys = () => {
    const { productModelData } = this.state;
    return getAttributeKeys(productModelData);
  };

  getTenantValues = (tenantKey, data) => {
    const { productModelData } = this.state;
    return getAttributeValuesNew(productModelData, tenantKey, data);
  };

  getAttributeKeys = () => {
    return _.pluck(this.state.scrapedKeyStates, "scrapedKeys");
  };

  openDefaultValueScreen = () => {
    let scrapedKeyStates = [...this.state.scrapedKeyStates];
    scrapedKeyStates.forEach((scrapedKeyState) => {
      if (scrapedKeyState.isSelected === true) {
        scrapedKeyState.isSelected = false;
      }
    });
    this.setState({
      scrapedKeyStates: scrapedKeyStates,
      showDefaultValueScreen: true,
    });
  };

  getFulfillerName = async () => {
    try {
      const { mcpSku } = this.state;
      const { getAccessToken } = this.props;
      const fulfillerName = await getFulfillerDetails(mcpSku, getAccessToken);
      return fulfillerName;
    } catch (ex) {
      //TODO; Log error;
    }
  };

  // Handle tenant key event
  updateTenantKeyChange = (value, index, tenantKey, ruleIndex) => {
    const translationRules = changeTenantKey(
      this.state.translationRules,
      value,
      ruleIndex,
      index
    );
    this.setState({ translationRules: translationRules });
  };

  updateTenantValueChange = (value, index, tenantKey, ruleIndex, ratio) => {
    const translationRules = changeTenantValue(
      this.state.translationRules,
      value,
      ruleIndex,
      index,
      tenantKey,
      ratio
    );
    this.setState({ translationRules: translationRules });
  };

  onChangeTenantKey = (e, index, tenantKey, ruleIndex) => {
    this.updateTenantKeyChange(e.target.value, index, tenantKey, ruleIndex);
  };

  onSelectionTenantKey = async (item, index, tenantKey, ruleIndex) => {
    this.setState({ showSpinner: true });
    const {
      selectedKeyId,
      scrapedKeyStates,
      translationRules,
      productModelData,
    } = this.state;
    const scrapedKeys = scrapedKeyStates.find(
      (x) => x.ruleId === selectedKeyId
    ).scrapedKeys;
    const { scrapedAttributes } = translationRules[ruleIndex];
    const { getAccessToken } = this.props;
    const rules = changeTenantKey(
      translationRules,
      item.value,
      ruleIndex,
      index
    );
    const isMergedOrSplit =
      scrapedKeys.length > 1 || rules[ruleIndex].tenantKeys.length > 1;
    if (isMergedOrSplit) {
      this.setState({ translationRules: rules, showSpinner: false });
      return;
    }
    try {
      const tenantAttributes = await getNlpData(
        scrapedKeys,
        scrapedAttributes,
        item.value,
        productModelData,
        getAccessToken
      );
      rules[ruleIndex].tenantAttributes = tenantAttributes;
      this.setState({ translationRules: rules, showSpinner: false });
      this.updateSelectedScrapedKeyState();
    } catch (error) {
      this.setState({
        showSpinner: false,
        translationRules: rules,
      });
    }
  };

  onBlurTenantKey = (e, index, ruleIndex) => {
    this.updateSelectedScrapedKeyState();
    const value = e.target.value;
    if (value === "") return;
    let errorMessage = getErrorMessageIfTenantKeyMappedIndefault(
      this.state.defaultTenantData,
      value
    );
    if (errorMessage === undefined) {
      errorMessage = getErrorMessageIfTenantKeyAlreadyMapped(
        this.state.translationRules,
        ruleIndex,
        value,
        index
      );
    }

    if (errorMessage) {
      const translationRules = changeTenantKey(
        this.state.translationRules,
        "",
        ruleIndex,
        index
      );
      this.setState({
        translationRules: translationRules,
        snackbarDetails: {
          show: true,
          bsStyle: "danger",
          message: errorMessage,
        },
      });
    }
  };

  onBlurTenantValue = () => {
    this.updateSelectedScrapedKeyState();
  };

  onChangeTenantValue = (value, index, tenantKey, ruleIndex) => {
    this.updateTenantValueChange(value, index, tenantKey, ruleIndex);
    this.updateSelectedScrapedKeyState();
  };

  onSelectionTenantValue = (item, index, tenantKey, ruleIndex) => {
    this.updateTenantValueChange(
      item.value,
      index,
      tenantKey,
      ruleIndex,
      item.ratio
    );
  };

  onDeleteScrapeKey = (mappingState, ruleId = this.state.selectedKeyId) => { 
    const scrapedKeyStates = [...this.state.scrapedKeyStates];
    const deleteIndex = scrapedKeyStates.findIndex(
      (i) => i.ruleId === ruleId
    );
    const translationRules = [...this.state.translationRules];
    if (mappingState === Constants.ScrapedKeyState.DELETED) {
      const selectedIndexToDelete = translationRules.findIndex(
        (i) => i.ruleId === ruleId
      );
      const tenantMapData = translationRules[selectedIndexToDelete];
      translationRules[selectedIndexToDelete] = { ...tenantMapData };
      translationRules[selectedIndexToDelete].tenantAttributes.forEach((row) => {
        row.data = [];
      });
      translationRules[selectedIndexToDelete].tenantKeys = [""];
    }

    const DELETEDMapData = scrapedKeyStates[deleteIndex];
    scrapedKeyStates[deleteIndex] = { ...DELETEDMapData };
    scrapedKeyStates[deleteIndex].mappingState = mappingState;
    if (mappingState === Constants.ScrapedKeyState.DELETED) {
      this.setState({ scrapedKeyStates, translationRules });
    } else {
      this.setState({ scrapedKeyStates });
    }
  };

  onScrapedKeySplit = (ruleIndex) => {
    const { translationRules } = this.state;
    const tenantKeysLength = translationRules[ruleIndex].tenantKeys.length;

    let keyStates = [...this.state.scrapedKeyStates];
    let keyState = { ...keyStates[ruleIndex] };
    keyState.mappingState = Constants.ScrapedKeyState.PARTIALLY_MAPPED;
    keyStates[ruleIndex] = keyState;

    if (tenantKeysLength > Constants.MAX_KEY) {
      this.showSnackBar(
        getDisplayMessage({
          customMessage: Constants.Messages.MAX_SPLIT_KEY,
        })
      );
      return;
    }
    const rules = changeTenantKey(
      translationRules,
      "",
      ruleIndex,
      translationRules[ruleIndex].tenantKeys.length
    );
    this.setState({ translationRules: rules, scrapedKeyStates: keyStates });
  };

  onDeleteTenantKey = (ruleIndex, index) => {
    const translationRules = deleteTenantKey(
      this.state.translationRules,
      ruleIndex,
      index
    );
    let scrapedKeyStates = updateSelectedKeyMappingState(
      this.state.selectedKeyId,
      this.state.scrapedKeyStates,
      translationRules
    );
    this.setState({
      translationRules: translationRules,
      scrapedKeyStates: scrapedKeyStates,
    });
  };

  onDeleteAttributeKey = (ruleIndex, index) => {
    this.setState({ showSpinner: true });
    const { scrapedKeyStates, translationRules, selectedKeyId } = this.state;
    const selectedIndex = scrapedKeyStates.findIndex(
      (x) => x.ruleId === selectedKeyId
    );
    const keyStates = deleteScrapeKey(
      scrapedKeyStates,
      selectedIndex,
      ruleIndex,
      index
    );
    if (scrapedKeyStates[selectedIndex].scrapedKeys[index] === "") {
      let updatedScrapedKeyStates = updateSelectedKeyMappingState(
        selectedKeyId,
        keyStates,
        translationRules
      );
      this.setState({
        scrapedKeyStates: updatedScrapedKeyStates,
        showSpinner: false,
      });
    } else {
      this.updateMergedCombination(
        translationRules,
        keyStates,
        selectedIndex,
        ruleIndex,
        selectedKeyId
      );
    }
  };

  onScrapedKeyMerge = () => {
    const { scrapedKeyStates, selectedKeyId } = this.state;
    const selectedIndex = scrapedKeyStates.findIndex(
      (x) => x.ruleId === selectedKeyId
    );
    const scrapedKeys = scrapedKeyStates[selectedIndex].scrapedKeys;

    if (scrapedKeys.length > Constants.MAX_KEY) {
      this.showSnackBar(
        getDisplayMessage({
          customMessage: Constants.Messages.MAX_MERGED_KEY,
        })
      );
      return;
    }
    const keyStates = changeScrapeKey(
      scrapedKeyStates,
      selectedIndex,
      "",
      scrapedKeys.length
    );
    this.setState({ scrapedKeyStates: keyStates });
  };

  onSelectAttributeKey = async (value, ruleIndex, index) => {
    this.setState({ showSpinner: true, showDefaultValueScreen: false });
    const { translationRules, scrapedKeyStates, selectedKeyId } = this.state;
    const selectedIndex = scrapedKeyStates.findIndex(
      (x) => x.ruleId === selectedKeyId
    );
    const message = getErrorMessageIfScrapeKeyAlreadyMapped(
      translationRules,
      scrapedKeyStates,
      selectedIndex,
      value
    );
    if (message) {
      this.setState({
        showSpinner: false,
        snackbarDetails: {
          show: true,
          bsStyle: "danger",
          message: message,
        },
      });
      this.showSnackBar(message);
      return;
    }
    const keyStates = changeScrapeKey(
      scrapedKeyStates,
      selectedIndex,
      value,
      index
    );
    this.updateMergedCombination(
      translationRules,
      keyStates,
      selectedIndex,
      ruleIndex,
      selectedKeyId
    );
  };

  updateMergedCombination = async (
    translationRules,
    keyStates,
    selectedIndex,
    ruleIndex,
    selectedKeyId
  ) => {
    const { selectedFileInfo, getAccessToken } = this.props;
    const scrapedKeys = keyStates[selectedIndex].scrapedKeys;
    let inputRequest = {
      fileId: selectedFileInfo.fileId,
      attributeName: scrapedKeys.filter(function (e) {
        return e;
      }),
    };
    try {
      const mergedCombinationData = await getCombinationData(
        inputRequest,
        getAccessToken
      );
      const rules = createMergedCombination(
        translationRules,
        ruleIndex,
        scrapedKeys,
        mergedCombinationData
      );

      let updatedScrapedKeyStates = updateSelectedKeyMappingState(
        selectedKeyId,
        keyStates,
        rules
      );

      this.setState({
        translationRules: rules,
        scrapedKeyStates: updatedScrapedKeyStates,
        showSpinner: false,
      });
    } catch (e) {
      this.setState({
        showSpinner: false,
        snackbarDetails: {
          show: true,
          bsStyle: "danger",
          message: Constants.Messages.GET_MARGE_DATA_FAILED,
        },
      });
    }
  };

  onCopyScrapeValues = (ruleIndex) => {
    const { selectedKeyId, translationRules, scrapedKeyStates } = this.state;
    const rules = copyScrapeValues(translationRules, ruleIndex);
    let updatedScrapedKeyStates = updateSelectedKeyMappingState(
      selectedKeyId,
      scrapedKeyStates,
      rules
    );
    this.setState({
      translationRules: rules,
      scrapedKeyStates: updatedScrapedKeyStates,
    });
  };

  onToggleAlwaysCopy = (attribute) => {
    const { copiedScrapeAttributes } = this.state;
    if (copiedScrapeAttributes.includes(attribute)) {
      const newCopiedScrapeAttributes = copiedScrapeAttributes.filter(
        (each) => each !== attribute
      );
      this.setState({ copiedScrapeAttributes: newCopiedScrapeAttributes });
    } else {
      this.setState({
        copiedScrapeAttributes: [...copiedScrapeAttributes, attribute],
      });
    }
  };

  showSnackBar(message) {
    this.setState({
      snackbarDetails: {
        show: true,
        bsStyle: "danger",
        message: message,
      },
    });
  }

  hideSnackbar = () =>
    this.setState({
      snackbarDetails: {
        show: false,
      },
    });

  toggleSkuChangesModal = () =>
    this.setState((prevState) => ({
      openSkuChangesModal: !prevState.openSkuChangesModal,
    }));

  render() {
    const {
      showFileSelectionPage,
      showDefaultValueScreen,
      productModelData,
      showSpinner,
      mcpSku,
      mcpSkuVersion,
      snackbarDetails: { show, bsStyle, message, delay },
      showMappingSection,
      scrapedKeyStates,
      translationRules,
      selectedKeyId,
      defaultTenantData,
      isStandard,
      isDraft,
      openSkuChangesModal,
      attributesTransformed,
      copiedScrapeAttributes,
    } = this.state;
    const { Spinner } = shapes;
    const translationRuleIndex = translationRules.findIndex(
      (x) => x.ruleId === selectedKeyId
    );
    const scrapedKeyState = scrapedKeyStates.find(
      (x) => x.ruleId === selectedKeyId
    );
    let scrapedKeys = [];
    if (scrapedKeyState) scrapedKeys = scrapedKeyState.scrapedKeys;

    const {
      getAccessToken,
      selectedFileInfo,
      accountId,
      sharedScrapedDataConfig,
    } = this.props;

    const disableActions =
      sharedScrapedDataConfig &&
      sharedScrapedDataConfig.canEditStandardProductMapping === false &&
      sharedScrapedDataConfig.canViewStandardProductMapping &&
      selectedFileInfo &&
      selectedFileInfo.isStandard;

    return (
      <Fragment>
        {!showFileSelectionPage && (
          <div>
            <Fragment>
              <div>
                <h2 className={styles.productAttributeMappingHeaderTitle}>
                  Product Option Mapping
                </h2>
                <h5 className={styles.productAttributeMappingHeaderSku}>
                  <br></br>
                  <b>Competitor:</b> {selectedFileInfo.competitor}
                  &nbsp;&nbsp;&nbsp;&nbsp; <b>Country:</b>
                  {selectedFileInfo.countryCode}&nbsp;&nbsp; &nbsp;&nbsp;
                  <b>Category:</b> {selectedFileInfo.category}
                  &nbsp;&nbsp;&nbsp;&nbsp;<b>Product:</b> {mcpSku}
                </h5>
                <br />
                <div className="inner-content">
                  {showMappingSection && (
                    <Fragment>
                      <MappingActions
                        scrapedKeyStates={scrapedKeyStates}
                        translationRules={translationRules}
                        copiedScrapeAttributes={copiedScrapeAttributes}
                        selectedKeyId={selectedKeyId}
                        skuId={mcpSku}
                        skuVersion={mcpSkuVersion}
                        selectedFileInfo={selectedFileInfo}
                        getAccessToken={getAccessToken}
                        getFulfillerName={this.getFulfillerName}
                        defaultTenantData={defaultTenantData}
                        updateMappingModalId={this.updateMappingModalId}
                        updateScrapedKeyStates={this.updateScrapedKeyStates}
                        updateSelectedScrapedKeyState={
                          this.updateSelectedScrapedKeyState
                        }
                        modelMappingId={
                          this.props.selectedFileInfo.modelMappingId
                        }
                        isStandard={isStandard}
                        accountId={accountId}
                        isDraft={isDraft}
                        onExistingSkuSearchMapping={
                          this.props.onExistingSkuSearchMapping
                        }
                        onNewSkuSearchMapping={this.props.onNewSkuSearchMapping}
                        disableActions={disableActions}
                      />
                      <div className="row" style={{ display: "flex", alignContent: "space-between", width: "auto" }}>
                        <div className="col-md-2" style={{width: "max-content", flexGrow: "25"}}>
                          <DefaultValueSelection
                            openDefaultValueScreen={this.openDefaultValueScreen}
                            showDefaultValueScreen={showDefaultValueScreen}
                          />
                          <hr
                            style={{
                              color: "#C0C0C0",
                              backgroundColor: "#C0C0C0",
                              height: 2,
                            }}
                          />
                          <MemoizeScrapedAttributesListView
                            items={scrapedKeyStates}
                            reorderOnDrag={this.reorderOnDrag}
                            onClickAttributeKey={this.onClickAttributeKey}
                            onDeleteScrapeKey = {this.onDeleteScrapeKey}
                            disableActions = {disableActions}
                            scrapedKeys={scrapedKeys}
                            translationRule={
                              translationRules[translationRuleIndex]
                            }
                            translationRules={
                              translationRules
                            }                          />
                        </div>
                        <div className="col-md-10" style={{flexGrow: "1"}}>
                          {showDefaultValueScreen ? (
                            <div>
                              <div
                                style={{
                                  height: 58,
                                }}
                              />
                              <DefaultValueSelectionScreen
                                translationRules={translationRules}
                                productModelData={productModelData}
                                selectedFileInfo={selectedFileInfo}
                                getAccessToken={getAccessToken}
                                scrapedKeyStates={scrapedKeyStates}
                                skuId={mcpSku}
                                getFulfillerName={this.getFulfillerName}
                                onChangeDefaultTenantData={
                                  this.onChangeDefaultTenantData
                                }
                                defaultTenantData={this.state.defaultTenantData}
                                disableActions={disableActions}
                              />
                            </div>
                          ) : (
                            <AttributeMapper
                              ruleIndex={translationRuleIndex}
                              mappingState={scrapedKeyState.mappingState}
                              onDeleteScrapeKey={this.onDeleteScrapeKey}
                              translationRule={
                                translationRules[translationRuleIndex]
                              }
                              scrapedKeys={scrapedKeys}
                              productModelData={productModelData}
                              getTenantKeys={this.getTenantKeys}
                              getTenantValues={this.getTenantValues}
                              getAttributeKeys={this.getAttributeKeys}
                              onChangeTenantKey={this.onChangeTenantKey}
                              onSelectionTenantKey={this.onSelectionTenantKey}
                              onBlurTenantKey={this.onBlurTenantKey}
                              onBlurTenantValue={this.onBlurTenantValue}
                              onFocusTenantValue={this.onFocusTenantValue}
                              onChangeTenantValue={this.onChangeTenantValue}
                              onSelectionTenantValue={
                                this.onSelectionTenantValue
                              }
                              onScrapedKeySplit={this.onScrapedKeySplit}
                              onScrapedKeyMerge={this.onScrapedKeyMerge}
                              onDeleteTenantKey={this.onDeleteTenantKey}
                              onDeleteAttributeKey={this.onDeleteAttributeKey}
                              onSelectAttributeKey={this.onSelectAttributeKey}
                              onCopyScrapeValues={this.onCopyScrapeValues}
                              disableActions={disableActions}
                              copiedScrapeAttributes={copiedScrapeAttributes}
                              onToggleAlwaysCopy={this.onToggleAlwaysCopy}
                            />
                          )}
                        </div>
                      </div>
                      <ProductChangesModal
                        openSkuChangesModal={openSkuChangesModal}
                        sku={mcpSku}
                        attributesTransformed={attributesTransformed}
                        toggleSkuChangesModal={this.toggleSkuChangesModal}
                      />
                    </Fragment>
                  )}
                </div>
              </div>
            </Fragment>
            <Snackbar
              show={show}
              bsStyle={bsStyle}
              delay={delay}
              onHideSnackbar={this.hideSnackbar}>
              {message}
            </Snackbar>
          </div>
        )}
        {showSpinner && <Spinner fullPage />}
      </Fragment>
    );
  }
}

export default ProductAttributeMapping;
