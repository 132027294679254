import axios from 'axios';

const SCRAPING_SCHEDULER_PROD_API = 'https://api.scrapingscheduler.cimpress.io';
const SCRAPING_SCHEDULER_STAGE_API = 'https://stg.api.scrapingscheduler.cimpress.io';

const ACCOUNTS_URL = 'https://accounts.cimpress.io';

export const getScrapingToolConfigurations = async (accessToken, isProduction) => {
  const baseUrl = isProduction ? SCRAPING_SCHEDULER_PROD_API : SCRAPING_SCHEDULER_STAGE_API;
  const authorization = { authorization: `Bearer ${accessToken}` };
  const headers = { ...authorization };
  const url = `${baseUrl}/scrapingtoolconfigurations`;
  const response = await axios.get(url, { headers });
  const accountResponseHandler = response?.data.map((eachScrapingToolConfiguration) => getAccountDetails(accessToken, eachScrapingToolConfiguration.accountId));
  const accountResponses = await Promise.all(accountResponseHandler);
  const scrapingToolConfigurations = response?.data.map((eachScrapingToolConfiguration, index) => {
    return { label: accountResponses[index]?.name, value: eachScrapingToolConfiguration }
  });
  return scrapingToolConfigurations;
};

export const getAccountDetails = async (accessToken, accountId) => {
  const baseUrl = ACCOUNTS_URL;
  const authorization = { authorization: `Bearer ${accessToken}` };
  const headers = { ...authorization };
  const url = `${baseUrl}/api/v1/accounts/${accountId}`;
  try {
    const response = await axios.get(url, { method: 'GET', mode: 'cors', headers });
    return response.data;
  }
  catch
  {
    return { name: accountId, accountId: accountId };
  }
};
