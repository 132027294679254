import { areKeysSame } from "./utils";
import { MappingStatus, ScrapedKeyState } from "./constants";

export const getActiveRuleIds = (scrapedKeyStates) => {
  const dicActiveRule = {};
  scrapedKeyStates.forEach((element) => {
    dicActiveRule[element.ruleId] =
      element.isActive &&
      !areKeysSame(element.mappingState, ScrapedKeyState.DELETED);
  });

  return dicActiveRule;
};

export const showErrorMessageInSnackBar = (errorMessage) => {
  return {
    show: true,
    bsStyle: "danger",
    message: errorMessage,
    delay: 5000,
  };
};

export const hideSnackBar = () => {
  return { show: false };
};

export const getOptions = (data) => {
  if (!data || data.length === 0) return [];
  return data.map((value) => {
    return { value: value, label: value };
  });
};

export const getInitialTableData = () => {
  return {
    loading: true,
    columnHeaders: [
      { Header: " ", accessor: "Header1" },
      { Header: " ", accessor: "Header2" },
      { Header: " ", accessor: "Header3" },
      { Header: " ", accessor: "Header4" },
      { Header: " ", accessor: "Header5" },
      { Header: " ", accessor: "Header6" },
    ],
    rows: [],
  };
};

export const getColumnHeadersAndValues = (previewData) => {
  if (previewData == null) {
    return { columnHeaders: null, rows: null };
  }
  const { columnHeaders, accessor } =
    createColumnHeaderAndAccessor(previewData);
  return { columnHeaders, rows: getColumnValues(previewData, accessor) };
};

const createColumnHeaderAndAccessor = (normalizeData) => {
  const columnHeaders = [];
  const accessor = [];
  for (let row = 0; row < normalizeData.length; row++) {
    for (let col = 0; col < normalizeData[row].length; col++) {
      accessor.push(`header_${col}`);
      columnHeaders.push({
        Header: normalizeData[row][col],
        accessor: `header_${col}`,
      });
    }
    return { columnHeaders, accessor };
  }
};

const getColumnValues = (normalizeData, accessor) => {
  const columnValues = [];
  for (let row = 1; row < normalizeData.length; row++) {
    let rowData = {};
    for (let col = 0; col < normalizeData[row].length; col++) {
      rowData[accessor[col]] = normalizeData[row][col];
    }
    columnValues.push(rowData);
  }
  return columnValues;
};

export function buildQuery(key, values) {
  let query = "";
  if (!Array.isArray(values)) {
    return query;
  }
  values.forEach((value) => {
    query = `${query}${key}=${value}&`;
  });

  return query;
}

export const checkIfPriceThresholdChanged = (validationMessage) => {
  if (validationMessage.priceChange && validationMessage.priceChange.isError) {
    return MappingStatus.PRICE_CHANGE_THRESHOLD;
  }
  return MappingStatus.MAPPING_FAILED;
};
