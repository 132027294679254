import _ from "underscore";

import { StandardColumnName, NO_CURRENCY, StandardColumnType } from "./constants";
import { convertToOptionFormat, getColumnData } from "./utils";
import {
  getScrapedFileInfo,
  getScrapedData,
  getQuestionnaireData,
} from "../services/scrapingFileService";
import { saveQuestionnaireData } from "../services/scrapingFileService";


export const mandatoryColumns = [
  [StandardColumnName.COUNTRY],
  [StandardColumnName.CURRENCY],
  [StandardColumnName.QUANTITY],
  [StandardColumnName.PRICE],
];

export const validationFields = [
  [StandardColumnName.COUNTRY],
  [StandardColumnName.CURRENCY],
  [StandardColumnName.QUANTITY],
  [StandardColumnName.PRICE],
];

export const fetchQuestionnaireData = async (
  accountId,
  fileId,
  getAccessToken,
  oldState
) => {
  const scrapedFileDetail = await getScrapedFileInfo(
    accountId,
    fileId,
    getAccessToken
  );

  const scrapedData = await getScrapedData(fileId, getAccessToken);

  let questionnaire;
  try {
    questionnaire = await getQuestionnaireData(fileId, getAccessToken);
  } catch {
    // If getQuestionnaire fails questionnaire variable should be undefined
  }

  let fileHeaderOptions = [];
  if (
    scrapedData.scrapedData !== undefined &&
    !_.isEmpty(scrapedData.scrapedData)
  ) {
    fileHeaderOptions = convertToOptionFormat(
      _.pluck(scrapedData.scrapedData, "attributeKey")
    );
  }

  const newState = { ...oldState };
  let countryData = [];

  if (questionnaire) {
    questionnaire.columnNameMapping.forEach((eachColumn) => {
      newState.questionnaireData[eachColumn.standardColumnName] = eachColumn.scrapedFileHeaderName;
      if (eachColumn.standardColumnName === "Country") {

        countryData = getColumnData(
          newState.questionnaireData[StandardColumnName.COUNTRY],
          scrapedData
        );
        if (!_.isEmpty(countryData)) {
          countryData = countryData.attributeValue;
        }
      }
    });

    newState.countryCurrencyMapping = questionnaire.countryCurrencyMapping;
    if (
      newState.countryCurrencyMapping &&
      newState.countryCurrencyMapping.length > 0
    ) {
      newState[StandardColumnName.CURRENCY] = NO_CURRENCY;
    }

    newState.countryVatMapping = questionnaire.countryVatMapping;
    questionnaire.priceInclusivity.forEach((eachPrice) => {
        newState.questionnaireData[`Includes${eachPrice.standardColumnName}Shipping`] = eachPrice.includesShipping;
        newState.questionnaireData[`Includes${eachPrice.standardColumnName}Tax`] = eachPrice.includesTax;
    });
    newState.questionnaireData[StandardColumnName.ISSTANDARDTAX]= questionnaire.isStandardTax
  }

  return {
    ...newState,
    scrapedData: scrapedData,
    fileHeaderOptions: fileHeaderOptions,
    scrapedFileDetail: scrapedFileDetail,
    countryData: countryData,
  };
};

export const saveQuestionnaire = async (data, standardColumnData, getAccessToken) => {
  let finalData = {
    scrapedFileDetailId: data.scrapedFileDetail.id,
    ColumnNameMapping: [],
    CountryCurrencyMapping: data.countryCurrencyMapping,
    CountryVatMapping: data.countryVatMapping,
    isStandardTax: data.questionnaireData.isStandardTax,
    PriceInclusivity: []
  };

  for (let each of Object.keys(data.questionnaireData)) {
      let standardColumn = standardColumnData.find(standardColumn => standardColumn.standardColumnName === each);
      if (!standardColumn) {
        continue;
      }
      if (
        standardColumn.standardColumnType === StandardColumnType.PRICE && standardColumn.shouldcalculatepriceinclusivity)
        {
          if (!data.questionnaireData[each]) {
          continue;
          }

          finalData.PriceInclusivity.push(
            {
              "IncludesTax":  data.questionnaireData[`Includes${each}Tax`],
              "IncludesShipping": data.questionnaireData[`Includes${each}Shipping`],
              "StandardColumnName": each
            }
          );
        }
        if (each === "CURRENCY" && data.questionnaireData[each] === "NO_CURRENCY") {
          continue;
        }
        if (standardColumn.standardColumnType === StandardColumnType.PRICE && standardColumn.isDerivedFrom !== null){
          const priceName = standardColumn.isDerivedFrom;
    
          if (
            data.questionnaireData[priceName] &&
            !data.questionnaireData[`Includes${priceName}Shipping`] &&
            data.questionnaireData[each]
          ) {
            finalData.ColumnNameMapping.push({
              standardColumnName: each,
              scrapedFileHeaderName: data.questionnaireData[each],
            });
          }
        } else {
          finalData.ColumnNameMapping.push({
            standardColumnName: each,
            scrapedFileHeaderName: data.questionnaireData[each],
          });
        }
      }
  await saveQuestionnaireData(getAccessToken, finalData);
};