import { request } from "./request";
import StatusCodes from "http-status-codes";

export const getRuleSet = async (
  productModel,
  skuVersion,
  isDraft,
  getAccessToken,
  accountId,
  countryCode
) => {
  try {
    const config = await request(
      process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI +
        "/scrapingtoolconfigurations",
      getAccessToken
    );
    const accountConfig = config.find((o) => o.accountId === accountId);

    // if disable sku mapping is true or if mapping is submitted and there is no version means its custom sku.
    if (
      (accountConfig && accountConfig.disableSkuMapping) ||
      (!isDraft && !skuVersion)
    ) {
      return {
        disableSkuMapping: true,
        whiteList: [],
      };
    }

    if (productModel.startsWith("PRD") || productModel.startsWith("MER")) {
      if(isDraft)
      {
        const merchantProductModel = await request(
          `${process.env.REACT_APP_SCRAPING_SCHEDULAR_SERVICE_BASE_URI}/v1/normalisation/getProductVersionAndAttributes/${accountId}/${productModel}/${countryCode}`,
          getAccessToken
      );
        return {
          ...convertPRDProductDataToWhiteList(merchantProductModel),
          mcpSkuVersion: merchantProductModel.version,
          isStandard: false,
        };
      }
      const v2MerchantProductModel = await request(
        `${process.env.REACT_APP_MERCHANT_PRODUCT_URL}/${productModel}/versions/${skuVersion}`,
        getAccessToken
      );
      return {
        ...convertPRDProductDataToWhiteList(v2MerchantProductModel),
        mcpSkuVersion: v2MerchantProductModel.version,
        isStandard: false,
      };
    }

    const v2ProductModel = isDraft
      ? await request(
          `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/${productModel}:latest`,
          getAccessToken
        )
      : await request(
          `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/${productModel}/versions/${skuVersion}`,
          getAccessToken
        );
    const whiteListData = convertCIMProductDataToWhiteList(v2ProductModel);

    if (!v2ProductModel.isStandard) {
      return {
        ...whiteListData,
        mcpSkuVersion: v2ProductModel.version,
      };
    }

    return {
      ...whiteListData,
      isStandard: true,
      mcpSkuVersion: v2ProductModel.version,
    };
  } catch (Exception) {
    if (Exception.status === StatusCodes.NOT_FOUND) {
      try {
        const v2ProductModel = isDraft
          ? await request(
              `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/${productModel}:latest`,
              getAccessToken
            )
          : await request(
              `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/${productModel}/versions/${skuVersion}`,
              getAccessToken
            );
        return {
          ...convertCIMProductDataToWhiteList(v2ProductModel),
          mcpSkuVersion: v2ProductModel.version,
        };
      } catch (innerException) {
        if (innerException.status === StatusCodes.NOT_FOUND) {
          return {
            disableSkuMapping: false,
            whiteList: [],
          };
        } else {
          throw innerException;
        }
      }
    }
    throw Exception;
  }
};

export const convertPRDProductDataToWhiteList = (PRDProductData) => {
  const options = PRDProductData.attributes.filter(
    (attribute) => attribute.buckets[0] === "option"
  );

  const optionsWhiteList = options.map((option) => {
    const attributeValues = [];
    option.values.forEach((item) => {
      if (item.type !== "range") {
        attributeValues.push({ value: item[item.type] });
      }
    });
    return {
      attributeKey: option.key,
      attributeValues: attributeValues,
    };
  });

  return { whiteList: [...optionsWhiteList] };
};

const convertCIMProductDataToWhiteList = (productData) => {
  const options = productData.options;
  const optionsWhiteList = options.map((option) => {
    const attributeValues = [];
    option.values.forEach((item) => {
      if (item.value) {
        attributeValues.push({ value: item.value });
      }
    });
    return {
      attributeKey: option.name,
      attributeValues: attributeValues,
    };
  });
  return { whiteList: [...optionsWhiteList] };
};

const getFulfillerId = (mcpSku, getAccessToken) =>
  request(`${process.env.REACT_APP_FULFILLER_ID}/${mcpSku}`, getAccessToken);

const getFulfiller = (skuId, getAccessToken) =>
  request(
    `${process.env.REACT_APP_FULFILLER_IDENTITY_URL}/mcpsku?mcpsku=${skuId}`,
    getAccessToken
  ).then((response) => response.familySummary.tenant.name);

const getFulfillerAlternate = (fulfillerId, getAccessToken) =>
  request(
    `${process.env.REACT_APP_FULFILLER_TRADELINK_URL}/${fulfillerId}`,
    getAccessToken
  ).then((response) => response.name);

const getFulfillerName = async (skuId, fulfillerId, getAccessToken) => {
  const fulfiller = await Promise.all([
    getFulfiller(skuId, getAccessToken),
    getFulfillerAlternate(fulfillerId, getAccessToken),
  ]);
  // eslint-disable-next-line no-nested-ternary
  return fulfiller[0]
    ? fulfiller[0]
    : fulfiller[1]
    ? fulfiller[1]
    : "Unknown Fulfiller";
};

export const getFulfillerDetails = async (sku, getAccessToken) => {
  const response = await getFulfillerId(sku, getAccessToken);
  return await getFulfillerName(
    sku,
    response.externalFulfillerId,
    getAccessToken
  );
};

export const isStandardProduct = async (sku, getAccessToken) => {
  try {
    if (sku.startsWith("PRD") || sku.startsWith("MER")) {
      return false;
    }
    const v2ProductModel = await request(
      `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/${sku}:current`,
      getAccessToken
    );
    return v2ProductModel.isStandard;
  } catch (error) {
    return false;
  }
};