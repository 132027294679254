import React, { useState, useEffect, Fragment } from "react";
import { Modal, Table, Snackbar } from "@cimpress/react-components";
import { getPreviewData, modalBody } from "../../services/preview";
import { getDisplayMessage } from "../../services/errorMessages";
import * as Constants from "../../utils/constants";
import { downloadFile } from "../../services/downloadFile";
import {
  getColumnHeadersAndValues,
  getInitialTableData,
} from "../../utils/common";

function Preview({
  togglePreviewScreen,
  showPreview,
  finalSubmitData,
  getAccessToken,
}) {
  const [state, setState] = useState(() => {
    return getInitialTableData();
  });

  const [snackbarDetails, setSnackbarDetails] = useState({
    show: false,
    bsStyle: undefined,
    delay: 0,
    message: "",
  });

  useEffect(() => {
    async function fetchPreviewData() {
      try {
        const previewData = await getPreviewData(
          getAccessToken,
          finalSubmitData
        );
        const { columnHeaders, rows } = getColumnHeadersAndValues(previewData);
        setState({ columnHeaders: columnHeaders, rows: rows, loading: false });
      } catch (e) {
        setSnackbarDetails({
          show: true,
          bsStyle: "danger",
          message: getDisplayMessage({
            customMessage: Constants.Messages.PREVIEW_FAILED,
            error: e,
          }),
        });
      }
    }
    fetchPreviewData();
  }, [finalSubmitData, getAccessToken]);

  function closeModal() {
    togglePreviewScreen();
  }

  function hideSnackbar() {
    setSnackbarDetails({ show: false });
    togglePreviewScreen();
  }

  function onDownload() {
    downloadFile(getAccessToken, finalSubmitData).catch((error) => {
      setSnackbarDetails({
        show: true,
        bsStyle: "danger",
        message: getDisplayMessage({
          customMessage: Constants.Messages.DOWNLOAD_FAILED,
          error: error,
        }),
      });
    });
  }

  return (
    <div>
      {!snackbarDetails.show && (
        <Modal
          style={modalBody}
          bsStyle="info"
          className="foobar"
          show={showPreview}
          onRequestHide={closeModal}
          title="Preview of normalize data"
          closeButton={true}
        >
          <Fragment>
            <div className="row">
              <div className="col-md-9" />
              <div className="col-md-3" align="right">
                <button className="btn btn-default" onClick={onDownload}>
                  Download
                </button>
              </div>
            </div>
            <br />
            <div className="row">
              <Table
                columns={state.columnHeaders}
                loading={state.loading}
                data={state.rows}
                defaultPageSize={10}
              />
            </div>
          </Fragment>
        </Modal>
      )}
      {snackbarDetails.show && (
        <Snackbar
          show={snackbarDetails.show}
          bsStyle={snackbarDetails.bsStyle}
          delay={snackbarDetails.delay}
          onHideSnackbar={hideSnackbar}
        >
          {snackbarDetails.message}
        </Snackbar>
      )}
    </div>
  );
}

export default Preview;
